import styled from 'styled-components'

export const CalloutContainer = styled.div`
  border:         ${props => props.border ? `1px solid ${props.border}` : 'none'};
  background:     ${props => props.background ? props.background : `var(--rep-${props.type}-light)`};
  color:          ${props => props.color ? props.color : `var(--rep-${props.type})`};
  padding:        8px;
  border-radius:  5px;
  gap:            8px;
  grid-column:    1 / span 3;
  white-space:    pre-line;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
`

export const CalloutContent = styled.div`
  display:    flex;
  gap:        8px;
  text-align: left;
  margin-top: ${props => props.withTitle ? '2px' : '0px'};
`

export const CalloutTitle = styled.div`
  font-size:      .5rem;
  text-transform: uppercase;
  font-weight:    600;
  display:        flex;
  align-items:    center;
  gap:            4px;
  border-bottom:  1px dashed var(--rep-neutral-primary-middle);
  display:        inline-block;
  padding-bottom: 1px;
`
