import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const CREATE        = 'REMARK_CREATE'
const UPDATE        = 'REMARK_UPDATE'
const OPEN          = 'REMARK_OPEN'
const CLOSE         = 'REMARK_CLOSE'
const DESTROY       = 'REMARK_DESTROY'
const CREATE_REPORT = 'REMARK_CREATE_REPORT'

export const REMARK_ACTIONS = {
  CREATE:        CREATE,
  UPDATE:        UPDATE,
  OPEN:          OPEN,
  CLOSE:         CLOSE,
  DESTROY:       DESTROY,
  CREATE_REPORT: CREATE_REPORT
}

export function remarkReducer(state, action) {

  const { report, remark, updateMethods, fetchApi, closeModal } = state

  switch (action.type) {
    case CREATE:
      fetchApi({
        url:       `/reports/${report.id}/control_remarks/rep_create.json`,
        method:    'POST',
        body:      action.data,
        callback:  data => updateMethods.addRemark(data.response),
        followUps: [closeModal]
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/control_remarks/${remark.id}/rep_update.json`,
        method:    'PATCH',
        body:      action.data,
        callback:  data => updateMethods.remark(data.response),
        followUps: [closeModal]
      })
      return state

    case OPEN:
      fetchApi({
        url:      `/control_remarks/${remark.id}/rep_open.json`,
        method:   'PATCH',
        callback: data => updateMethods.remark(data.response)
      })
      return state

    case CLOSE:
      fetchApi({
        url:      `/control_remarks/${remark.id}/rep_close.json`,
        method:   'PATCH',
        callback: data => updateMethods.remark(data.response)
      })
      return state

    case DESTROY:
      fetchApi({
        url:      `/control_remarks/${remark.id}/rep_destroy.json`,
        method:   'DELETE',
        callback: data => updateMethods.removeRemark(data.response)
      })
      return state

    case CREATE_REPORT:
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
