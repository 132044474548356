import React from 'react'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface fetchProps {
  /** URL to fetch. */
  url:           string,
  /** Method for the request.  */
  method?:       'GET' | 'POST' | 'PATCH' | 'DELETE',
  /** CSRF Token */
  token?:        string,
  /** Credentials for the request (same-origin, …) */
  credentials?:  RequestCredentials,
  /** Custom headers */
  headers?:      object,
  /** Body content of the request */
  body?:         object | string,
  /** Function to be run once with te the response.json() result */
  callback?:     (any) => void,
  followUps?:    ((any) => void)[],
  /** If animation present, function to end it */
  endAnimation?: (string) => void,
  /** If set to true, will display a "Processing …" toast during the Promise resolution time */
  withLoading?:  boolean,
  /** Set to false if you want to keep a FormData format or something else. */
  stringify?:    boolean,
  /** Content-Type header */
  contentType?:  string,
  /** Signal to abort request if controller.abort() is called */
  signal?:       AbortSignal
}

/**
 * @description `fetchApi` is a function to fetch backend with authentication and toast alerts on success/error.
 * @see Interface {@link fetchProps}
 * @example
 * fetchApi({
 *  url       : 'http://www.google.com',
 *  callbacks : [() => console.log('Google fetched!')]
 * })
 *
*/
async function fetchApi(token, {
  url,
  credentials,
  headers,
  body,
  callback,
  signal,
  method       = 'GET',
  contentType  = 'application/json',
  followUps    = [],
  endAnimation = null,
  withLoading  = false,
  stringify    = true
}: fetchProps) {

  // CONTENT-TYPE
  const bodyString:                   any = stringify ? ((typeof(body) === 'object') ? JSON.stringify(body) : body) : body
  const headerObject:                 any = {...headers, 'X-CSRF-Token': token }
  const headersObjectWithContentType: any = contentType ? {...headerObject, 'Content-Type': contentType } : { ...headerObject }

  const fetchAction = fetch(url, {
    method:      method,
    headers:     headersObjectWithContentType,
    credentials: credentials,
    body:        bodyString,
    signal:      signal
  }).then(response => response.json())
    .then(data => {

      if (window.env === 'development') console.log(data)
      // If error
      if (data.errors) {
        endAnimation && endAnimation('error')
        return Promise.reject(data.errors)
      }
      if (data.error) {
        endAnimation && endAnimation('error')
        return Promise.reject(data.error)
      }

      // Stop animation
      endAnimation &&
      endAnimation(null)

      // Display success toast
      !withLoading  &&
      data?.message &&
        toast.success(data.message, { icon: <FontAwesomeIcon icon="check-circle" />, iconTheme: { primary: 'var(--rep-success)', secondary: 'var(--rep-success-light)' } })

      return Promise.resolve(data)
    })

  const fetchRequest = withLoading
    ? toast.promise(fetchAction, {
      loading: 'Processing',
      success: 'Success !',
      error:   err => err.toString()
    },
    {
      style: {
        minWidth: '250px',
      },
      success: {
        icon: <FontAwesomeIcon icon="check-circle" />,
      },
      error: {
        icon: <FontAwesomeIcon icon="times" />,
      }
    })
    : fetchAction

  await fetchRequest.then(data => {
    async function executeCallback(func) {
      func && await func(data)
    }
    callback && executeCallback(callback)
    followUps.forEach(update => executeCallback(update))
  }).catch(error => {
    if (error.code === 20) return // If the request was aborted through a signal
    if (error?.message) {
      if (window.env === 'development') {
        !withLoading &&
          toast.error(error.message, { icon: <FontAwesomeIcon icon="times" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      } else {
        !withLoading &&
          toast.error('Unexpected error.', { icon: <FontAwesomeIcon icon="times" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }

    } else {
      !withLoading &&
        toast.error(error, { icon: <FontAwesomeIcon icon="times" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
    }
  })
}

export default fetchApi
