import React, { useMemo, useState }  from 'react'
import styled                           from 'styled-components'

import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome'

import Checkbox   from '@form/Checkbox/Checkbox'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'

import { useGlobalContextState } from '@context/GlobalContext'

import { FileFormProps } from './types.d'

import { matchImage, matchPdf } from '@utils/regex'

const FileContainerStyle = styled.div`
  img { width: 100% }
`

const ImageFileStyle = styled.div`
  position: relative;
  img {
    transition:    .3s ease;
    width:         100%;
    border-radius: 4px;
    max-height:    500px;
    object-fit:    contain;
  }
  .download-btn {
    transition:      .3s ease;
    opacity:         0;
    position:        absolute;
    text-align:      center;
    top:             40%;
    right:           40%;
    color:         white;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    padding:         4px 8px;
    border-radius:   4px;
    z-index:         200;
  }
  &:hover {
    cursor: pointer;
    img           { filter: brightness(50%); }
    .download-btn { opacity: .9; }
  }
`

const FileStyle = styled.div`
  font-size:  2em;
  color:      var(--rep-primary);
  text-align: center;
`

const RemoveFileStyle = styled.div`
  background:      var(--rep-danger-light);
  color:           var(--rep-danger);
  width:           50px;
  display:         flex;
  justify-content: center;
  align-items:     center;
  border-radius:   4px;
  &:hover {
    cursor:  pointer;
    opacity: .8;
  }
`

const FileNameStyle = styled.div`
  display: flex;
  gap:     8px;
  margin:  8px 0;
`

const openFile = url => window.open(url, '_blank', 'noopener,noreferrer')

const FileForm: React.FC<FileFormProps> = ({
  file,
  certification,
  destroyable = false,
  updateDocument,
  removeDocument,
}) => {

  const { i18n } = useGlobalContextState()

  const [fileCertification, setFileCertification] = useState({ value: 'urgent', isCheck: file.certification })

  const isPdf   = useMemo(() => matchPdf(file.doctype), [])
  const isImage = useMemo(() => matchImage(file.doctype), [])

  const handleSubmit = event => {
    event.preventDefault()

    const formData: FormData = new FormData(event.currentTarget)
    const formObject = Object.fromEntries(formData)

    formData.append('certification', fileCertification?.isCheck?.toString())
    formObject.filename = `${formObject.filename}.${file.name.split('.').slice(-1)}`

    const data = { ...formObject, id: file.id }

    updateDocument(data)
  }

  const FileContent = useMemo(() => {
    if (isPdf) {
      return  <div>
        <iframe src={file.url} width="100%" height="500px" />
        <FileStyle onClick={() => openFile(file.url)}>
          <FontAwesomeIcon icon="file-pdf" />
          <div>{i18n.t('document.actions.access_file')}</div>
        </FileStyle>
      </div>
    } else if (isImage) {
      return <img
        onClick = {() => openFile(file.url)}
        src     = {file.url}
      />
    } else {
      return <FileStyle onClick={() => openFile(file.url)}>
        <FontAwesomeIcon icon="file" />
        <div>{i18n.t('document.actions.access_file')}</div>
      </FileStyle>
    }
  }, [])

  return(
    <FileContainerStyle>
      <ImageFileStyle>
        {FileContent}
      </ImageFileStyle>

      <form onSubmit={handleSubmit}>
        <FileNameStyle>
          <Input
            type         = 'text'
            name         = 'filename'
            defaultValue = {file.name.split('.').slice(0, -1)
              .join('.')}
            hint         = {`.${file.name.split('.').slice(-1)}`}
          />
          {!!destroyable &&
            <RemoveFileStyle onClick={removeDocument}>
              <FontAwesomeIcon icon="trash-alt" />
            </RemoveFileStyle>
          }
        </FileNameStyle>

        {certification &&
          <Checkbox
            name         = 'certification'
            label        = {i18n.t('document.certification_question')}
            defaultValue = {file.certification}
            callback     = {setFileCertification}
            marginY      = 'M'
          />
        }
        {certification && fileCertification.isCheck &&
          <DatePicker
            name   = 'validity_end'
            label  = {i18n.t('document.validity_end')}
            date   = {file?.validity_end}
          />
        }

        <Input
          name         = 'submit'
          type         = 'submit'
          defaultValue = {i18n.t('actions.save')}
        />
      </form>
    </FileContainerStyle>
  )
}

export default FileForm
