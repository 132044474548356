import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Input  from '@form/Input'
import Select from '@form/Select'

import * as FormStyle            from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { CONVERSATION_ACTIONS } from '@reducers/index'

interface NewChatFormProps {
  dispatch: React.Dispatch<any>
}

const ConversationForm: React.FC<NewChatFormProps> = ({ dispatch }) => {

  const {
    i18n,
    CONSTANTS
  } = useGlobalContextState()

  const { CONVERSATION } = CONSTANTS

  const createConversation  = data => dispatch({ type: CONVERSATION_ACTIONS.CREATE, data: data })

  const sendConversationForm = event => {
    event.preventDefault()

    const formData: FormData = new FormData(event.currentTarget)
    formData.append('conv_type', CONVERSATION.TYPES.TECHNICIANS)

    const conversation = Object.fromEntries(formData)
    createConversation(conversation)
  }

  return(
    <div>
      <form onSubmit={sendConversationForm}>
        <FormStyle.Header><FontAwesomeIcon icon="users" />{i18n.t('user.users')}</FormStyle.Header>
        <Select
          name             = 'users'
          search           = {true}
          searchUrl        = '/users/search'
          multiselect      = {true}
          defaultValue     = {[]}
          format           = {{ content: 'name', value: 'id', details: 'role' }}
          detailsLocaleKey = 'shared'
          placeholder      = 'Utilisateurs'
          marginY          = 'M'
        />

        <Input
          name         = 'submit'
          type         = 'submit'
          defaultValue = {i18n.t('conversation.actions.create')}
        />
      </form>
    </div>
  )
}

export default ConversationForm
