import React from 'react'

import * as Sentry from '@sentry/react'

import toast, { ToastBar, Toaster }           from 'react-hot-toast'
import { library }           from '@fortawesome/fontawesome-svg-core'
import { useMediaQuery }     from 'react-responsive'

import Alert          from '@components/Alert'
import CommandBar     from '@components/CommandBar'
// import InfoWindow     from '@components/InfoWindow'
import Modal          from '@components/Modal'

import { GlobalStyle } from './GlobalStyle'

import { GlobalContextProvider } from '../context/GlobalContext'

import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { ICompany, IUser } from '../interfaces'
import Button from '@components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas, far)

// import * as icons from './icons'
// library.add(...icons)

interface WrapperProps {
  children:        any,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string,
  withModal?:      boolean
}


const Wrapper: React.FC<WrapperProps> = ({
  children,
  current_user,
  current_company,
  token,
  locale,
  withModal = true
}) => {

  Sentry.setContext('user', current_user)
  Sentry.setContext('company', current_company)

  const isDesktop = useMediaQuery({
    query: '(min-width:770px)'
  })

  return (
    <GlobalContextProvider
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      isDesktop       = {isDesktop}
      locale          = {locale}
    >
      <GlobalStyle />
      <Toaster
        position      = "top-center"
        toastOptions  = {{
          style:   { zIndex: '3000' },
          success: { style: { color: 'var(--rep-success)' } },
          error:   { style: { color: 'var(--rep-danger)' } },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <div style={{ borderLeft: '1px solid var(--rep-primary-light)', paddingLeft: '4px'}}>
                    <Button
                      icon  = {<FontAwesomeIcon icon="times" />}
                      click = {() => toast.dismiss(t.id)}
                      color = 'var(--rep-primary-middle)'
                    >
                      {/* Close */}
                    </Button>
                  </div>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      {withModal && <Modal />}
      <Alert />
      {/* <InfoWindow /> */}
      <div id="single-month-popup" />
      <CommandBar />
      {children}
    </GlobalContextProvider>
  )
}

export default Wrapper
