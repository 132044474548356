import React, { useEffect, useRef, useState } from 'react'

import * as Style from './style'

import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import { OrderTagContentProps } from './types.d'

const OrderTag: React.FC<OrderTagContentProps> = ({
  children,
  click,
  marginY,
  value,
  activeValue,
  defaultOrder
}) => {

  const states = [
    {
      id:         0,
      value:      value,
      direction:  'NULL',
      icon:       <FontAwesomeIcon icon={['fas', 'arrows-up-down']} />,
      background: 'transparent',
      border:     'var(--rep-primary-light)',
      color:      'var(--rep-neutral-primary-middle)'
    },
    {
      id:         1,
      value:      value,
      direction:  'ASC',
      icon:       <FontAwesomeIcon icon={['fas', 'arrow-up-short-wide']} />,
      background: 'var(--rep-primary-light)',
      border:     'var(--rep-primary)',
      color:      'var(--rep-primary)'
    },
    {
      id:         2,
      value:      value,
      direction:  'DESC',
      icon:       <FontAwesomeIcon icon={['fas', 'arrow-down-short-wide']} />,
      background: 'var(--rep-primary-light)',
      border:     'var(--rep-primary)',
      color:      'var(--rep-primary)'
    },
  ]

  const [selectedState, setSelectedState] = useState(states[0])
  const [timeoutId,     setTimeoutId]     = useState(null)

  const index = useRef(0)

  useEffect(() => {
    if (defaultOrder.value === value && defaultOrder.direction !== null) {
      const defaultIndex = states.find((state) => state.direction === defaultOrder.direction).id
      setSelectedState(states[defaultIndex])
      index.current = defaultIndex
    }
  }, [])

  useEffect(() => {
    if (activeValue !== value) {
      setSelectedState(states[0])
      index.current = 0
    }
  }, [activeValue])

  const handleClick = () => {
    index.current = index.current + 1 < 3 ? index.current + 1 : 0
    setSelectedState(states[index.current])
    // Delay reorder while user is still clicking through
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => {
        !!click && click(states[index.current])
      }, 300))
    } else {
      setTimeoutId(setTimeout(() => null, 300))
      !!click && click(states[index.current])
    }
  }

  return (
    <Style.OrderTag
      marginY    = {marginY}
      color      = {selectedState.color}
      border     = {selectedState.border}
      background = {selectedState.background}
      onClick    = {handleClick}
    >
      {children}
      {!!selectedState.icon &&
        <Style.OrderTagIcon hasContent={!!children}>
          {selectedState.icon}
        </Style.OrderTagIcon>
      }
    </Style.OrderTag>
  )
}

export default OrderTag
