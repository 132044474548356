import React, { useRef, useState } from 'react'

import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react'

import * as Style from './style'

import WithTooltipProps from './types.d'

const WithTooltip: React.FC<WithTooltipProps> = ({
  children,
  content,
  title,
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const arrowRef = useRef(null)

  const { x, y, refs, strategy, context } = useFloating({
    open:                 isOpen,
    onOpenChange:         setIsOpen,
    placement:            'top',
    whileElementsMounted: autoUpdate,
    middleware:           [
      offset(6),
      flip({ fallbackAxisSideDirection: 'start' }),
      arrow({ element: arrowRef }),
      shift(),
      size({
        apply({availableHeight, elements}) {
          Object.assign(elements.floating.style, {
            // Minimum acceptable height is 50px.
            // `flip` will then take over.
            // maxHeight: `200px`,
            maxWidth: '100ch',
          })
        },
      })
    ]
  })

  // Event listeners to change the open state
  const hover   = useHover(context, { move: false })
  const focus   = useFocus(context)
  const dismiss = useDismiss(context)
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ])

  return (
    <>
      <div style={{ display: 'inline-block' }} ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <Style.Tooltip
            ref   = {refs.setFloating}
            style = {{
              position: strategy,
              top:      y ?? 0,
              left:     x ?? 0
            }}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} fill="var(--rep-neutral-primary)" />
            {!!title && <h6>{title}</h6>}
            {content}
          </Style.Tooltip>
        </FloatingPortal>
      )}
    </>
  )
}

export default WithTooltip
