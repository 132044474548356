import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

import Button from '@components/Button'

const CREATE                = 'REPORT_CREATE'
const UPDATE                = 'REPORT_UPDATE'
const ADD_COST_INVOICE      = 'REPORT_ADD_COST_INVOICE'
const ADD_CONVERSATION      = 'REPORT_ADD_CONVERSATION'
const ARCHIVE               = 'REPORT_ARCHIVE'
const CANCEL                = 'REPORT_CANCEL'
const UNARCHIVE             = 'REPORT_UNARCHIVE'
const UNCANCEL              = 'REPORT_UNCANCEL'
const OPEN                  = 'REPORT_OPEN'
const SEND_FOR_APPROVAL     = 'REPORT_SEND_FOR_APPROVAL'
const APPROVE               = 'REPORT_APPROVE'
const CLOSE                 = 'REPORT_CLOSE'
const GENERATE_FULL_REPORT  = 'REPORT_GENERATE_FULL_REPORT'
const GENERATE_COSTS_REPORT = 'REPORT_GENERATE_COSTS_REPORT'

export const REPORT_ACTIONS = {
  CREATE:                CREATE,
  UPDATE:                UPDATE,
  ADD_COST_INVOICE:      ADD_COST_INVOICE,
  ADD_CONVERSATION:      ADD_CONVERSATION,
  ARCHIVE:               ARCHIVE,
  CANCEL:                CANCEL,
  UNARCHIVE:             UNARCHIVE,
  UNCANCEL:              UNCANCEL,
  OPEN:                  OPEN,
  SEND_FOR_APPROVAL:     SEND_FOR_APPROVAL,
  APPROVE:               APPROVE,
  CLOSE:                 CLOSE,
  GENERATE_FULL_REPORT:  GENERATE_FULL_REPORT,
  GENERATE_COSTS_REPORT: GENERATE_COSTS_REPORT,
}

export function reportReducer(state, action) {

  const { i18n, report, updateMethods, fetchApi, showModal, closeModal } = state
  const callbacks = action.callbacks || []

  switch (action.type) {
    case CREATE:
      fetchApi({
        url:      '/reports/rep_create.json',
        method:   'POST',
        body:     { report: action.data },
        callback: data => window.location.assign(`/reports/${data.response.id}`)
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/reports/${report.id}/rep_update.json`,
        method:    'PATCH',
        body:      { report: action.data },
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [closeModal]
      })
      return state

    case OPEN:
      fetchApi({
        url:       `/reports/${report.id}/rep_open.json`,
        method:    'PATCH',
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [...callbacks]
      })
      return state

    case UNCANCEL:
      fetchApi({
        url:       `/reports/${report.id}/rep_uncancel.json`,
        method:    'PATCH',
        body:      { report: action.data },
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [
          closeModal,
          ...callbacks
        ]
      })
      return state

    case CANCEL:
      fetchApi({
        url:       `/reports/${report.id}/rep_cancel.json`,
        method:    'PATCH',
        body:      { report: action.data },
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [
          closeModal,
          ...callbacks
        ]
      })
      return state

    case SEND_FOR_APPROVAL:
      fetchApi({
        url:       `/reports/${report.id}/rep_send_for_approval.json`,
        method:    'PATCH',
        body:      action.data,
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [
          closeModal,
          ...callbacks
        ]
      })
      return state

    case APPROVE:
      fetchApi({
        url:       `/reports/${report.id}/rep_approve.json`,
        method:    'PATCH',
        body:      action.data,
        callback:  data => updateMethods.minimalReport(data.response),
        followUps: [closeModal]
      })
      return state

    case CLOSE:
      fetchApi({
        url:       `/reports/${report.id}/rep_close.json`,
        method:    'PATCH',
        body:      { report: action.data },
        callback:  data => updateMethods.report(data.response),
        followUps: [closeModal]
      })
      return state

    case GENERATE_FULL_REPORT:
      fetchApi({
        url:      `/reports/${report.id}/generate_full_report`,
        method:   'POST',
        callback: data => {
          fetchApi({
            url:         `/reports/${report.id}/pdf_status?document_id=${data.response.attributes.table.id}`,
            withLoading: true,
            callback:    response => showModal({
              title: i18n.t('report.report_generated'),
              content:
                  <Button
                    icon       = {<FontAwesomeIcon icon="receipt" />}
                    background = 'var(--rep-primary)'
                    color      = 'white'
                    size       = 'L'
                    click      = {
                      () => window.open(`${response.download_url}`, '_blank', 'noopener,noreferrer')
                    }
                  >
                    {i18n.t('report.report_download')}
                  </Button>
            })
          })
        }
      })

      return state

    case GENERATE_COSTS_REPORT:
      fetchApi({
        url:      `${report.id}/generate_costs_report`,
        method:   'POST',
        callback: data =>
          fetchApi({
            url:         `/reports/${report.id}/pdf_status?document_id=${data.response.attributes.table.id}`,
            withLoading: true,
            callback:    response => showModal({
              title: i18n.t('report.report_costs_generated'),
              content:
                  <Button
                    icon       = {<FontAwesomeIcon icon="receipt" />}
                    background = 'var(--rep-primary)'
                    color      = 'white'
                    size       = 'L'
                    click      = {
                      () => window.open(`${response.download_url}`, '_blank', 'noopener,noreferrer')
                    }
                  >
                    {i18n.t('report.report_costs_download')}
                  </Button>
            })
          })
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
