import { ConfigProvider, DatePickerProps, Form } from 'antd'
import React, { useEffect, useMemo, useState }   from 'react'

import dayjs                     from 'dayjs'

import * as FormStyle            from '../FormStyles'
import * as Style                from './style'

import DatePickerContainerProps  from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

import de from 'antd/locale/de_DE'
import en from 'antd/locale/en_GB'
import fr from 'antd/locale/fr_BE'
import nl from 'antd/locale/nl_BE'

import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/nl'
import 'dayjs/locale/de'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import updateLocale      from 'dayjs/plugin/updateLocale'

const DatePicker: React.FC<DatePickerContainerProps> = ({
  name,
  date,
  label,
  showTime,
  callback,
  footer,
  maxDate,
  disableYear = false,
  allowClear  = false,
  required    = false,
  disabled    = false,
  mode        = 'single',
  result      = 'date'
}) => {

  // defaultValue is a string that need to be parsed

  const { isDesktop, i18n } = useGlobalContextState()

  dayjs.extend(customParseFormat)
  dayjs.extend(updateLocale)
  dayjs.updateLocale('fr', { weekStart: 1 })
  dayjs.updateLocale('nl', { weekStart: 1 })
  dayjs.updateLocale('de', { weekStart: 1 })
  dayjs.extend(customParseFormat)

  /**
    * This function returns the date format to display according to the locale
    * @returns a string, DD/MM/YYY for instance in french.
  */
  const localizedDatePattern = useMemo(() => {
    const format = showTime
      ? {
        year:   'numeric',
        month:  '2-digit',
        day:    '2-digit',
        hour:   '2-digit',
        minute: '2-digit'
      }
      : {
        year:  'numeric',
        month: '2-digit',
        day:   '2-digit',
      }
    // Setting locale to 'en-GB' to have the DD/MM/YYYY format, because 'en' defaults to 'en-US'
    return new Intl.DateTimeFormat(i18n.locale == 'en' ? 'en-GB' : i18n.locale, format)
      .formatToParts(new Date())
      .map((obj) => {
        switch (obj.type) {
          case 'day':     return 'DD'
          case 'month':   return 'MM'
          case 'year':    return 'YYYY'
          // case 'literal': return obj.value === ':' ? showTime ? ':' : null : obj.value
          case 'hour':    return 'HH'
          case 'minute':  return 'mm'
          default: return obj.value
        }})
      .join('')
  }, [i18n.locale, showTime])

  /**
   *
   * @param date Coming from the props
   * @returns a dayjs array or object (if it's a *range* or not)
   */
  const parseDateStringOnChange = input => {
    return mode === 'range'
      ? (input
        ? input.map(day => dayjs(day, localizedDatePattern))
        : [])
      : (input
        ? dayjs(input, localizedDatePattern)
        : '')
  }

  const parseStringToDayjs = input => input ? mode === 'range' ? input.map(d => dayjs(d)) : dayjs(input) : ''

  const [selectedDate, setSelectedDate] = useState(parseStringToDayjs(date))

  useEffect(() => {
    let callbackResult
    if (selectedDate) {
      switch(result) {
        case 'date':    callbackResult = mode === 'range' ? selectedDate.map(day => day.toDate())      : dayjs(selectedDate, localizedDatePattern).toDate();   break
        case 'string':  callbackResult = mode === 'range' ? selectedDate.map(day => day.toString())    : dayjs(selectedDate, localizedDatePattern).toString(); break
        case 'ISO':     callbackResult = mode === 'range' ? selectedDate.map(day => day.toISOString()) : dayjs(selectedDate, localizedDatePattern).toISOString(); break
      }


      if (result === 'date' && !showTime) {
        if (mode === 'range') {
          callbackResult.map(d => d.setHours(12, 0, 0))
        } else {
          callbackResult.setHours(12, 0, 0)
        }
      }

      !!callback && callback(callbackResult)
    } else {
      !!callback && callback(null)

    }

  }, [selectedDate])


  const formatRangeOnChange = (dates) => {
    let result
    if (dates.some(date => date === '')) {
      result = []
    } else if (disableYear) {
      result = dates.map(d => dayjs(`${d}/2024`, localizedDatePattern))
    } else {
      result = dates.map(d => dayjs(d, localizedDatePattern))
    }
    setSelectedDate(result)
  }

  return (
    <ConfigProvider
      locale = {
        (i18n.locale === 'en' && en) ||
        (i18n.locale === 'fr' && fr) ||
        (i18n.locale === 'nl' && nl) ||
        (i18n.locale === 'de' && de)
      }
      theme = {{
        token:      { colorPrimary: '#2e9af1' },
        components: { Form: { marginLG: 0 } }
      }}
    >
      <Form.Item required={required}>
        {label && <FormStyle.Label noMarginTop>{label}</FormStyle.Label>}

        <input
          type  = "hidden"
          name  = {name}
          value = {selectedDate ? mode === 'range' ? selectedDate.map(d => d.toISOString()) : selectedDate?.toISOString() : ''}
        />

        <Style.DatePickerContainer disableYear={disableYear}>
          {mode === 'range'
            ? <Style.RangePickerStyle<DatePickerProps>
              locale = {
                (i18n.locale === 'en' && en) ||
                (i18n.locale === 'fr' && fr) ||
                (i18n.locale === 'nl' && nl) ||
                (i18n.locale === 'de' && de)
              }
              name               = {`range-${name}`}
              onChange           = {(_date, string) => formatRangeOnChange(string)}
              defaultValue       = {selectedDate && selectedDate.map(day => dayjs(day))}
              size               = 'large'
              maxDate            = {maxDate ? dayjs(maxDate) : null}
              allowClear         = {allowClear}
              required           = {required}
              requiredMark       = {required}
              popupStyle         = {{zIndex: 12000 }}
              showTime           = {showTime}
              format             = {disableYear ? localizedDatePattern.replace('/YYYY', '') : localizedDatePattern}
              range
              disabled           = {disabled}
              inputReadOnly
              popupClassName     = {!isDesktop ? 'single-month' : ''}
              getPopupContainer  = {() => document.getElementById('single-month-popup')}
              renderExtraFooter  = {() => footer}
            />
            : <Style.DatePickerStyle<DatePickerProps>
              locale = {
                (i18n.locale === 'en' && en) ||
                (i18n.locale === 'fr' && fr) ||
                (i18n.locale === 'nl' && nl) ||
                (i18n.locale === 'de' && de)
              }
              name               = {`${name}-picker`}
              onChange           = {(_date, string) => setSelectedDate(parseDateStringOnChange(string))}
              defaultValue       = {selectedDate}
              size               = 'large'
              maxDate            = {maxDate ? dayjs(maxDate) : null}
              allowClear         = {allowClear}
              required           = {required}
              requiredMark       = {required}
              disabled           = {disabled}
              format             = {disableYear ? localizedDatePattern.replace('/YYYY', '') : localizedDatePattern}
              getPopupContainer  = {triggerNode => triggerNode.parentNode}
              showTime           = {showTime}
              renderExtraFooter  = {() => footer}
              showNow
              inputReadOnly
            />
          }
        </Style.DatePickerContainer>
      </Form.Item>
    </ConfigProvider>
  )
}

export default DatePicker
