import styled from 'styled-components'

export const ChatHeader = styled.div`
  padding:         4px;
  gap:             4px;
  position:        sticky;
  top:             0;
  background:    white;
  border-bottom:   ${props => props.hasConversations ? '1px solid var(--rep-neutral-primary-light)' : 'none'};
`

export const ChatTitle = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
`

export const ChatTag = styled.div`
  flex-grow: 1;
  &:hover { cursor: pointer }
`

export const Conversations = styled.div`
  background:   white;
  display:        flex;
  height:         ${props => props.height ? props.height : '100%'};
  max-height:     ${props => props.maxHeight ? props.maxHeight : '70dvh' };
  flex-grow:      1;
  flex-direction: row;
  border:         1px solid var(--rep-neutral-primary-light);
  overflow-y:     auto;
  border-radius:  5px;
  border-bottom-left-radius:  0px;
  border-bottom-right-radius: 0px;
`

export const ConversationList = styled.div`
  border-right:  1px solid var(--rep-neutral-primary-light);
  border-bottom: none;
  overflow-y:    scroll;
`

export const ConversationBtn = styled.div`
  position:   relative;
  padding:    8px 4px;
  background: ${props => props.selected ? 'var(--rep-primary-light)' : 'transparent'};
  &:first-of-type { border-top-left-radius: 5px }
  &:hover {
    cursor:     pointer;
    background: var(--rep-primary-light);
  }
`

export const UnseenMsgCount = styled.div`
  padding-left:  5px;
  padding-right: 5px;
  border-radius: 5px;
  background:    var(--rep-danger);
  color:         white;
  position:      absolute;
  right:         2px;
  top:           2px;
  font-size:     10px;
`

export const Messages = styled.div`
  padding: 4px;
`

export const MessageList = styled.div`
  flex-grow:          1;
  overflow:           scroll;
  -ms-overflow-style: none;
  scrollbar-width:    none;
`

export const EmptyChat = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  flex-direction:  column;
  color:           var(--rep-neutral-primary);
  text-align:      center;
  background:    rgb(251, 252, 253);
  p { font-size: 3em }
`

export const UserLine = styled.div`
  display:         flex;
  align-items:     center;
  gap:             8px;
  justify-content: space-between;
  margin:          8px 0;
`

export const UserDisplay = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
`

export const UserRole = styled.div`
  text-transform: uppercase;
  color:          var(--rep-neutral);
  font-size:      .8em;
`
export const UserName = styled.div`
  font-weight: 600;
  font-size:   1.1em;
  color:       var(--rep-neutral-primary);
`
