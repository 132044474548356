import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGlobalContextState } from '@context/GlobalContext'

import Dropdown    from '@components/Dropdown'
import WithTooltip from '@components/WithTooltip'

import GroupForm   from './GroupForm'
import MainLine    from '../MainLine/MainLine'

import * as Style from '../style'

import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { IGroupLine } from '@interfaces/ICalendar.d'
import PlanFormEquipments from '@pages/Maintenances/Form/PlanFormEquipments'
import Callout from '@components/Callout'

interface GroupLineProps {
  group:      IGroupLine
  disabled?:  boolean
  isSubItem?: boolean
}

const GroupLine: React.FC<GroupLineProps> = ({
  group,
  disabled = false,
  isSubItem = false
}) => {

  const {
    i18n,
    fetchApi,
    showModal,
    closeModal,
    showAlert,
    closeAlert
  } = useGlobalContextState()

  const {
    weekCount,
    currentWeek,
    isCurrentYear,
    filters,
    MAINTENANCE_CONSTANTS
  } = useMaintenancesContextState()

  const { VIEWS } = MAINTENANCE_CONSTANTS

  const [render,          setRender]          = useState(true)
  const [groupLine,       setGroupLine]       = useState(group)
  const [item,            setItem]            = useState(group.content)
  const [showDetails,     setShowDetails]     = useState(false)
  const [plans,           setPlans]           = useState([])
  const [groupPagination, setGroupPagination] = useState({ page: 0, next: 1, pages: null })
  const [loading,         setLoading]         = useState(false)

  useEffect(() => setItem(group.content), [group])
  useEffect(() => setItem(groupLine.content), [groupLine])

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    setLoading,
    groupPagination,
    VIEWS,
    updateLine:   setGroupLine,
    setMainLines: setPlans,
  })

  const fetchGroupLine = () => dispatch({
    type:  MAINTENANCE_ACTIONS.FETCH_GROUPLINE,
    group: item,
  })
  const fetchMainLines = () => dispatch({
    type:          MAINTENANCE_ACTIONS.FETCH_MAINLINES,
    group:         item,
    response:      'plans',
    filters:       filters,
    pagination:    groupPagination,
    setPagination: setGroupPagination,
  })
  const archiveGroup = () => {
    setGroupPagination({ page: 0, next: 1, pages: null })
    dispatch({
      type:      MAINTENANCE_ACTIONS.ARCHIVE_MAINTENANCE_PLAN_GROUP,
      group:     item,
      callbacks: [
        () => fetchMainLines(),
        () => fetchGroupLine(),
        () => setShowDetails(false)
      ]
    })
  }
  const unarchiveGroup = () => {
    setGroupPagination({ page: 0, next: 1, pages: null })
    dispatch({
      type:      MAINTENANCE_ACTIONS.UNARCHIVE_MAINTENANCE_PLAN_GROUP,
      group:     item,
      callbacks: [
        () => fetchMainLines(),
        () => fetchGroupLine(),
        () => setShowDetails(false)
      ]
    })
  }
  const updateEquipments = data => dispatch({
    type:       MAINTENANCE_ACTIONS.UPDATE_GROUP_EQUIPMENTS,
    group:      item,
    equipments: data,
    callbacks:  [() => fetchMainLines()]
  })
  const destroyMaintenancePlanGroup = () => dispatch({
    type:      MAINTENANCE_ACTIONS.DESTROY_MAINTENANCE_PLAN_GROUP,
    group:     item,
    callbacks: [
      () => closeAlert(),
      () => setRender(false)
    ]
  })

  const dropdownRef = useRef(null)
  const optionsRef  = useRef(null)
  const toggleDetails = event => {
    if (dropdownRef?.current?.contains(event.target) || optionsRef?.current?.contains(event.target)) return
    if (!showDetails) fetchMainLines()
    setShowDetails(!showDetails)
    if (!showDetails) return
  }

  const maintenanceGroupActions = useMemo(() => {
    const groupActions = []
    if (!disabled && item.permissions.can_update_maintenance_group)
      groupActions.push({
        icon:    <FontAwesomeIcon icon="pen" />,
        color:   'var(--rep-primary)',
        content: i18n.t('maintenance.actions.group_edit'),
        click:   () => showModal({
          title:   `Edit group ${item.name}`,
          content: <GroupForm group={item} setGroupLine={setGroupLine} />
        })
      })
    if (!disabled && item.permissions.can_update_equipments) groupActions.push({
      icon:    <FontAwesomeIcon icon="code-pull-request" />,
      color:   'var(--rep-primary)',
      content: i18n.t('maintenance.actions.manage_equipments'),
      click:   () => showModal({
        title:   i18n.t('maintenance.actions.manage_equipments', {name: item.name }),
        content: <PlanFormEquipments
          data     = {item}
          setData  = {setItem}
          callback = {updateEquipments}
          // dateSelect
        />,
        asWizard: true
      }),
      separator: true
    })
    if (!disabled && item.permissions.can_archive_maintenance_group)
      groupActions.push({
        icon:    <FontAwesomeIcon icon="folder" />,
        color:   'var(--rep-warning)',
        content: i18n.t('maintenance.actions.archive_group'),
        click:   archiveGroup
      })
    if (!disabled && item.permissions.can_unarchive_maintenance_group)
      groupActions.push({
        icon:    <FontAwesomeIcon icon="folder-open" />,
        color:   'var(--rep-warning)',
        content: i18n.t('maintenance.actions.unarchive_group'),
        click:   unarchiveGroup
      })
    if (!disabled && item.permissions.can_destroy_maintenance_group) groupActions.push({
      icon:    <FontAwesomeIcon icon="trash-can" />,
      color:   'var(--rep-danger)',
      content: i18n.t('actions.destroy'),
      click:   () => showAlert(
        <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
          {i18n.t('maintenance.actions.delete_maintenance_plan_group')}
        </Callout>,
        [{
          content:    i18n.t('actions.delete'),
          color:      'white',
          background: 'var(--rep-danger)',
          icon:       <FontAwesomeIcon icon="trash-can" />,
          click:      destroyMaintenancePlanGroup
        }]
      )
    })
    return groupActions
  }, [item, group])

  if (!render) return null

  return (
    <>
      <Style.CalendarLine
        length     = {weekCount + 1}
        clickable  = {!disabled}
        onClick    = {toggleDetails}
        background = {'red'}
      >
        <Style.CalendarName
          borderLeft = {showDetails ? (isSubItem ? 8 : 4) : (isSubItem ? 4 : 0)}
          // background = {showDetails ? '#e4e9ee' : item.archived ? 'var(--rep-neutral-primary-light)' : null}
          background = 'var(--rep-neutral-primary-middle)'
          color      = 'var(--rep-neutral-light)'
        >

          <FontAwesomeIcon icon="layer-group" />

          <Style.CalendarControlName>
            {item.archived &&
              <WithTooltip content={i18n.t('maintenance.group_archived')}>
                <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
              </WithTooltip>
            }
            <WithTooltip content={item.name}>
              {item.name}
            </WithTooltip>
          </Style.CalendarControlName>
          <Dropdown
            icon              = {<FontAwesomeIcon icon="cog" />}
            options           = {maintenanceGroupActions}
            color             = 'var(--rep-neutral-light)'
            forwardRef        = {dropdownRef}
            forwardOptionsRef = {optionsRef}
          />

          {!disabled && (showDetails
            ? <FontAwesomeIcon icon="arrow-turn-down" color="var(--rep-neutral-light)" />
            : <FontAwesomeIcon icon="minus" color="var(--rep-neutral-light)" />
          )}
        </Style.CalendarName>
        {Array.from({ length: weekCount }, (_x, i) =>
          <Style.CalendarBox key={i} currentWeek={isCurrentYear && ((i + 1) === currentWeek)} />
        )}

      </Style.CalendarLine>

      {showDetails &&
      <>
        {plans.map(plan =>
          <MainLine
            key           = {`${item.id}-${plan.content.data.id}`}
            line          = {plan}
            isSubItem
          />
        )}

        {loading
          ? <Style.Loading length={weekCount + 1} />
          : groupPagination.page < groupPagination.pages &&
            <Style.CalendarEmpty onClick={fetchMainLines} size='small' >Load more…</Style.CalendarEmpty>
        }
      </>
      }
    </>
  )
}

export default GroupLine
