import React, { useEffect, useState } from 'react'

import Select from '@form/Select'

import SelectTreeProps from './types.d'
import { useGlobalContextState } from '@context/GlobalContext'

/** SelectTree component.
 * @details +selecte+ propsd needs to have a +path+ key to build the whole tree
 */
const SelectTree: React.FC<SelectTreeProps> = ({
  name,
  label,
  selected,
  callback,
  withEmpty,
  emptyCallback,
  initUrl,
  searchUrl,
  filters,
  format,
  disabled,
  marginY,
  multiple = false,
  subElementName,
  subFilterName
}) => {

  const { fetchApi } = useGlobalContextState()

  /** The unit that is gonna be sent through the form. The last one of the unit tree  */
  const [selectedElement, setSelectedElement] = useState(selected)
  /** The tree of elements selected */
  const [tree,  setTree]  = useState(selected?.path ? selected?.path : [selected])
  /** Current length of the three */
  const [level, setLevel] = useState(selected?.path ? selected?.path?.length - 1 : 0)

  const uniqueId = btoa(Math.random().toString()).substring(10,15)

  /** Fetch data if missing (Only the Id and name coming from URL params for instance) */
  useEffect(() => {
    if (selected?.id && !selected?.path) {
      fetchApi({
        url:      `${initUrl}/${selected?.id}/rep_show.json?path=true`,
        callback: data => {
          if (data) {
            setSelectedElement(data.response)
            setTree(data.response?.path || [data.response])
            setLevel(data.response?.path ? data.response?.path?.length - 1 : 0)
          }
        }
      })
    }
  }, [])

  /** Callback when an element is selected */
  useEffect(() => !!callback && callback(selectedElement), [selectedElement])
  useEffect(() => {
    if (!selected) {
      selectElement(null, 0)
    } else {
      setSelectedElement(selected)
    }
  }, [selected])

  /** Handling the selection of an element */
  const selectElement = (element, elementLevel) => {
    /** If element is the root element */
    if (elementLevel === 0) {
      setLevel(0)
      setSelectedElement(element)
      setTree([element])
    }

    /** If it's on the last select, replace the last element in the tree */
    else if (level === elementLevel) {
      const newTree = tree.slice(0, -1)
      newTree.push(element)

      setTree(newTree)
    }

    /** If selected is before the current level, cut the tree and replace last element */
    else if (elementLevel < level) {
      const newTree = tree.slice(0, elementLevel - level - 1)
      newTree.push(element)

      setTree(newTree)
      setLevel(level - (level - elementLevel))
    }

    /** If selected is in the child select, just add the element in the tree */
    else if (elementLevel > level) {
      setLevel(level + 1)
      setTree([...tree, element])
    }

    /** Select the element and execute callback */
    setSelectedElement(element)
  }

  const selectEmptyelement = elementLevel => {
    /** If we empty the root element, nothing is selected */
    if (elementLevel === 0) {
      setSelectedElement(undefined)
      setLevel(0)
      setTree([])
    /** Else, just cut the tree */
    } else {
      const newTree = tree.slice(0, elementLevel - level - 1)
      setTree(newTree)
      setLevel(elementLevel - 1)
      setSelectedElement(newTree.slice(-1)[0])
    }
    /** Apply callback */
    !!emptyCallback && emptyCallback()
  }

  return (
    <>
      <Select
        name          = {`${name}_${uniqueId}`}
        label         = {label}
        search        = {true}
        searchUrl     = {searchUrl}
        withEmpty     = {withEmpty}
        emptyCallback = {() => selectEmptyelement(0)}
        callback      = {response  => selectElement(response.object, 0)}
        defaultValue  = {[tree[0]]}
        filters       = {filters}
        defaultFilter = 'root'
        multiselect   = {multiple && (tree.length === 1)}
        format        = {format}
        disabled      = {disabled}
        marginY       = {marginY}
      />

      {tree.map((parent, key) => {
        if (!parent?.has_children) return
        return <Select
          key           = {parent.id}
          name          = {`${name}_${uniqueId}_${key}`}
          label         = {subElementName}
          search        = {true}
          searchUrl     = {searchUrl}
          filters       = {[{
            id:      subFilterName,
            name:    'Parent',
            filters: { [subFilterName]: parent.id }
          }]}
          defaultFilter = {subFilterName}
          defaultValue  = {tree[key + 1] ? [tree[key + 1]] : []}
          withEmpty     = {true}
          emptyCallback = {() => selectEmptyelement(key + 1)}
          callback      = {response  => selectElement(response.object, key + 1)}
          format        = {format}
          disabled      = {disabled}
          marginY       = {marginY}
        />
      }
      )}
    </>
  )
}

export default SelectTree
