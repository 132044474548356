import styled from 'styled-components'

export const FileContainer = styled.div`
  display:    block;
  margin:     8px 0;
  max-width:  ${props => props.maxWidth ? props.maxWidth : 'unset'};
`

export const FileStyles = styled.div`
  position:         relative;
  width:            100%;
  border:           1px solid var(--rep-primary-light);
  padding:          4px;
  border-radius:    5px;
  gap:              8px;
  height:           100px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  img {
    width:      100%;
    height:     100%;
    object-fit: cover;
  }

  &:hover { cursor: pointer }
`

export const DeleteStyles = styled.div`
  position:        absolute;
  right:           8px;
  top:             8px;
  color:         white;
  border:          1px solid var(--rep-danger);
  background:      var(--rep-danger);
  padding:         4px 8px;
  border-radius:   4px;
  opacity:         .5;
  transition:      .2s ease;
  display:         flex;
  justify-content: center;
  align-items:     center;
  &:hover {
    cursor:   pointer;
    opacity:  1;
  }
`

export const FileIcon = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  font-size:       2rem;
  height:          100%;
  color:           ${props => props.color ? props.color : 'var(--rep-primary-light)'};
`

export const FileName = styled.div`
  font-weight:   400;
  color:         var(--rep-neutral-primary);
  word-wrap:     break-word;
  text-overflow: ellipsis;
  height:        1.2rem;
  overflow:      hidden;
  white-space:   ${props => props.wrapText ? 'nowrap' : 'unset'};
  text-align:    left;
  max-width:     100%;
  svg     { margin-right: 4px }
  &:hover { cursor: pointer }
`
