import FieldTagProps       from './types.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React               from 'react'

import Tag from '@components/Tag'


import { useGlobalContextState } from '@context/GlobalContext'

const FieldTag: React.FC<FieldTagProps> = ({
  background = 'var(--rep-neutral-light)',
  border     = 'var(--rep-neutral-light)',
  color      = 'var(--rep-neutral-primary)',
  field,
  hover      = 'var(--rep-neutral-primary-light)',
  icon,
  marginY,
  style,
  value,
}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { REPLY_TYPES }     = CONSTANTS

  const convertValue = (value) => {
    switch (field.reply_type) {
      case REPLY_TYPES.TRUE_FALSE:
        return field.name
      case REPLY_TYPES.DATE:
        return new Date(value).toLocaleDateString(i18n.locale)
      case REPLY_TYPES.DATE_TIME:
        return new Date(value).toLocaleDateString(i18n.locale)
      default:
        return value
    }
  }

  if (field.reply_type === REPLY_TYPES.TRUE_FALSE) {
    switch (value) {
      case 'true':
        icon = <FontAwesomeIcon icon={'check'} color={'var(--rep-success)'} />
        break
      case 'false':
        icon = <FontAwesomeIcon icon={'times'} color={'var(--rep-danger)'}/>
        break
      default:
        icon = ''
    }
  }

  return (
    <Tag
      color          = {color}
      background     = {background}
      border         = {border}
      hover          = {hover}
      icon           = {icon}
      marginY        = {marginY}
      style          = {style}
      tooltipContent = {field.name}
    >
      {convertValue(value)}
    </Tag>
  )
}

export default FieldTag
