import React, { useMemo, useReducer } from 'react'
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'

import Button            from '@components/Button'
import Column            from '@components/Column'
import ControlRemark     from '@components/ControlRemark'
import ControlRemarkForm from '@components/ControlRemark/Form'
import { Filter }        from '@components/Column/Filter'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { REMARK_ACTIONS, remarkReducer } from '@reducers/index'

interface ReportMaintenanceProps {
  open?:      boolean
  height?:    string
  maxHeight?: string
  grow?:      boolean
}

const ReportMaintenance: React.FC<ReportMaintenanceProps> = ({
  open       = true,
  height     = '100%',
  maxHeight  = '50%',
  grow       = false
}) => {

  const {
    i18n,
    showModal,
    CONSTANTS,
    initAPI,
    fetchApi,
    closeModal
  } = useGlobalContextState()

  const {
    report,
    reportDispatch,
    updateMethods,
  } = useReportContextState()

  const API = initAPI({
    reportId: report.id,
    updateMethods
  })

  const [_state, dispatch] = useReducer(remarkReducer, { report, API, fetchApi, closeModal, updateMethods })
  const createRemark       = data => dispatch({ type: REMARK_ACTIONS.CREATE, data })

  const { REMARK } = CONSTANTS

  const filters = useMemo(() => new Filter({
    references: {
      status: REMARK.STATUS
    },
    filters: [
      {
        id:        REMARK.STATUS.OPEN,
        name:      i18n.t('control_remarks.open'),
        icon:      <FontAwesomeIcon icon='section' />,
        reference: 'status'
      },
      {
        id:        REMARK.STATUS.CLOSED,
        name:      i18n.t('control_remarks.closed'),
        icon:      <FontAwesomeIcon icon='check' />,
        reference: 'status'
      },

    ],
    totalFilteredCount: report.total_filtered_remarks,
    perPage:            20,
    totalPages:         report.total_remarks_pages,
    fetch:              API.fetchRemarks,
    pageCallback:       data => updateMethods.moreRemarks(data),
    callback:           updateMethods.remarks
  }), [])

  const addRemarkForm = () => showModal({
    title:   i18n.t('control_remarks.actions.create'),
    content: <ControlRemarkForm createRemark={createRemark} />
  })

  const headerActions = useMemo(() =>
    [report.permissions.can_create_remarks &&
    <Button
      key   = 'create_remark'
      icon  = {<FontAwesomeIcon icon="plus" />}
      color = 'var(--rep-primary)'
      hover = 'var(--rep-primary-light)'
      click = {addRemarkForm}
    >
      {i18n.t('actions.add')}
    </Button>], [report.total_filtered_remarks, report.total_remarks, report.total_remarks_pages])

  return (
    <Column
      togglable
      paginated
      withFilters
      open             = {open}
      height           = {height}
      maxHeight        = {maxHeight}
      grow             = {grow}
      title            = {i18n.t('control_remarks.names')}
      totalCount       = {report.total_filtered_remarks}
      currentCount     = {report.remarks.length}
      totalPages       = {report.total_remarks_pages}
      totalFiltered    = {report.total_filtered_remarks}
      headerActions    = {headerActions}
      filters          = {filters}
    >
      {report.remarks.map(remark =>
        <ControlRemark
          key           = {remark.id}
          remark        = {remark}
          report        = {report}
          updateMethods = {updateMethods}
          dispatch      = {reportDispatch}
        />
      )}
    </Column>
  )
}

export default ReportMaintenance
