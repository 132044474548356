import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'
import Select     from '@form/Select'
import Textarea   from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { IReport } from '@interfaces/index'
import { ITask }   from '@interfaces/index'

const RequestForm: React.FC<{ report: IReport, task?: ITask }> = ({ report, task }) => {

  const {
    current_company,
    current_user,
    fetchApi,
    i18n,
    CONSTANTS,
    closeModal
  } = useGlobalContextState()

  const { USER } = CONSTANTS

  const [selectedTask, setSelectedTask] = useState(task || report.tasks[0])

  const sendRequestForm = (event) => {
    event.preventDefault()
    const formData: FormData = new FormData(event.currentTarget)
    const requestData = Object.fromEntries(formData)

    fetchApi({
      url:       '/material_requests.json',
      method:    'POST',
      body:      { material_request: requestData },
      callback:  data => window.open(`/material_requests/${data.response.id}`, '_blank', 'noopener,noreferrer'),
      followUps: [closeModal]
    })
    return
  }

  return (
    <form onSubmit={sendRequestForm}>
      <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}</FormStyle.Header>
      <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "to_do_item_id"
        defaultValue = {[selectedTask]}
        options      = {report?.tasks?.length ? report.tasks : [selectedTask]}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />

      <FormStyle.Header>
        <FontAwesomeIcon icon="list-ol" />{i18n.t('shared.details')}
      </FormStyle.Header>
      <FormStyle.Label>{i18n.t('material_request.preparer')}</FormStyle.Label>
      <Select
        name             = 'preparer_id'
        defaultValue     = {[current_company.default_stock_responsible || report.responsible]}
        search
        searchUrl        = '/users/search'
        filters          = {[
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
          }
        ]}
        defaultFilter    = 'managers'
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        required
      />

      <FormStyle.Label>{i18n.t('material_request.requested_for')}</FormStyle.Label>
      <Select
        name             = 'requested_for_id'
        defaultValue     = {[current_user]}
        search
        searchUrl        = '/users/search'
        filters      = {[
          {
            id:      'technicians',
            name:    i18n.t('user.role.technicians'),
            filters: { role: ['technician', 'technician_manager'] }
          },
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: ['manager', 'local_manager'] }
          },
          {
            id:      'employees',
            name:    i18n.t('user.role.employees'),
            filters: { role: ['employee', 'employee_manager'] }
          },
          {
            id:      'all',
            name:    i18n.t('shared.all'),
            filters: {}
          }
        ]}
        defaultFilter    = 'technicians'
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        required
      />

      <Textarea
        name         = 'description'
        label        = {i18n.t('shared.description')}
        marginY      = 'M'
      />

      <DatePicker
        label        = {i18n.t('material_request.deadline')}
        name         = 'deadline'
        date         = {new Date()}
      />

      <Input
        name         = 'submit'
        type         = 'submit'
        defaultValue = {i18n.t('material_request.actions.prepare_request')}
      />
    </form>
  )
}

export default RequestForm
