import styled from 'styled-components'

export const ReportCard = styled.div`
  background:    white;
  border:        1px solid var(--rep-neutral-primary-light);
  border-radius: 5px;
  color:         #333333;
  margin-bottom: 8px;
  padding:       0.5rem;
  text-align:    left;
  position:      relative;
  &:hover { cursor : pointer }
`

export const ReportHeaderContainer = styled.div`
  display:         flex;
  gap:             6px;
  justify-content: space-between;
  align-items:     flex-start;
`

export const ReportHeader = styled.div`
  display:       flex;
  flex-wrap:     wrap;
  gap:           6px;
  margin-bottom: 0.5rem;
`

export const ReportTitle = styled.h3`
  align-items:   center;
  display:       flex;
  text-align:    left;
  font-family:   'Inter', sans-serif;
  font-size:     1rem;
  font-weight:   500;
  /* gap:           6px; */
  margin-bottom: 0;
`

export const ReportText = styled.div`
  color:      var(--rep-neutral-primary);
  text-align: left;
  margin:     4px 0;
  display:    -webkit-box;
  overflow:   hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const FooterLeft = styled.div`
  align-items: center;
  color:       var(--rep-neutral-primary);
  display:     flex;
  flex-wrap:   wrap;
  gap:         6px;
`

export const ReportFooter = styled.div`
  display:         flex;
  flex-wrap:       wrap;
  gap:             6px;
  justify-content: space-between;
  margin-top:      0.5rem;
`

export const AvatarIcons = styled.div`
  display:         flex;
  flex-grow:       1;
  gap:             4px;
  justify-content: end;
`

export const UnseenMsgCount = styled.div`
  padding-left:  5px;
  padding-right: 5px;
  border-radius: 5px;
  background:    var(--rep-danger);
  color:         white;
  position:      absolute;
  right:         -5px;
  top:           -5px;
  font-size:     10px;
`
