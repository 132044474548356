import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const READY   = 'MACHINE_READY'
const RESTART = 'MACHINE_RESTART'
const RESTOP  = 'MACHINE_RESTOP'

export const MACHINE_ACTIONS = {
  READY:   READY,
  RESTOP:  RESTOP,
  RESTART: RESTART,
}

export function machineStopReducer(state, action) {

  const { stop, updateMethods, fetchApi } = state

  switch (action.type) {
    case READY:
      fetchApi({
        url:      `/machine_stops/${stop.id}/ready.js`,
        method:   'PATCH',
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    case RESTOP:
      fetchApi({
        url:      `/machine_stops/${stop.id}/restop.js`,
        method:   'PATCH',
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    case RESTART:
      fetchApi({
        url:      `/machine_stops/${stop.id}/restart.js`,
        method:   'PATCH',
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
