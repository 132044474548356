import styled, { css } from 'styled-components'

const AvatarLeftStyle = css`
  border-top-right-radius:     0;
  border-bottom-right-radius:  0;
  border-top-left-radius:      4px;
  border-bottom-left-radius:   4px;
`
const AvatarRightStyle = css`
  border-top-right-radius:     4px;
  border-bottom-right-radius:  4px;
  border-top-left-radius:      0;
  border-bottom-left-radius:   0;
`
const AvatarCircleStyle = css`
  border-top-right-radius:     4px;
  border-bottom-right-radius:  4px;
  border-top-left-radius:      4px;
  border-bottom-left-radius:   4px;
`

export const Avatar = styled.div`
  display:          flex;
  justify-content:  center;
  align-items:      center;
  width:            28px;
  height:           28px;
  color:            ${props => props.color};
  background:       ${props => props.background};
  border:           ${props => props.border ? `2px solid ${props.border}` : '1px solid transparent' };
  font-weight:      600;
  font-size:        .6rem;
  box-sizing:       border-box;
  user-select:      none;
  &:hover { cursor: default }

  ${props => {
    switch(props.type) {
      case 'left':  return AvatarLeftStyle
      case 'right': return AvatarRightStyle
      default:      return AvatarCircleStyle
    }}}

`

export const OverlappedStyle = styled.div`
  margin-right: ${props => props.overlapping ? '-8px' : 'inherit'};
`
