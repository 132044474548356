import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button  from '@components/Button'
import Callout from '@components/Callout'

import Checkbox from '@form/Checkbox'
import Input    from '@form/Input'
import Select   from '@form/Select'
import Textarea from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'
interface PlanFormInfoProps {
  data?:       IMaintenancePlan
  setData?:    React.Dispatch<React.SetStateAction<IMaintenancePlan>>
  initData:    IMaintenancePlan
  permissions: object
}

const PlanFormInfo: React.FC<PlanFormInfoProps> = ({
  data,
  setData,
  initData,
  permissions
}) => {

  const { i18n, current_company } = useGlobalContextState()

  const [expertise,          selectExpertise]   = useState(data.expertise)
  const [remind_provider,    setRemindProvider] = useState(data.remind_provider)
  const [reminder_period,    setReminderPeriod] = useState<string | number>(30)

  useEffect(() => { setData(data => ({...data, expertise })) }, [expertise])
  // Needed ?
  useEffect(() => { setData(data => ({...data, remind_provider }))     }, [remind_provider])
  useEffect(() => { setData(data => ({...data, reminder_period })) }, [reminder_period])

  const planSelected   = useRef(null)
  const nameRef        = useRef(null)
  const descriptionRef = useRef(null)

  const selectPlan = plan => {
    planSelected.current = plan.object

    setData(data => ({
      ...data,
      ...plan.object,
      id:        null,
      name:      `${plan.object.name} (Copy)`,
      documents: []
    }))
  }

  const resetPlan = () => {
    planSelected.current = null
    setData(data => ({
      ...initData,
      name:      '',
      amenities: data.amenities.filter(a => a.created),
      units:     data.units.filter(u => u.created)
    }))
    nameRef.current.value        = ''
    descriptionRef.current.value = ''
  }

  return(
    <div>
      {!!data?.id &&
        <Callout
          icon = {<FontAwesomeIcon icon="triangle-exclamation" />}
          type = 'warning'
        >
          {i18n.t('maintenance.form.applied_to_future')}
        </Callout>
      }

      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>

      <Input
        name         = 'name'
        label        = {i18n.t('shared.name')}
        tooltip      = {i18n.t('maintenance.form.tooltip.name_plan')}
        type         = 'text'
        marginY      = 'S'
        defaultValue = {data.name}
        forwardRef   = {nameRef}
        required
      />

      <Textarea
        name         = "description"
        forwardRef   = {descriptionRef}
        placeholder  = {i18n.t('shared.description')}
        marginY      = 'M'
        defaultValue = {data.description}
        tooltip      = {i18n.t('maintenance.form.tooltip.desc_plan')}
      />


      <Select
        name             = 'expertise_id'
        label            = {i18n.t('expertise.expertise')}
        defaultValue     = {[expertise]}
        callback         = {selected => selectExpertise(selected.object)}
        searchUrl        = '/expertises/search'
        format           = {{ content: 'name', value: 'id'}}
        marginY          = 'M'
        search
        withEmpty
      />

      <Checkbox
        name         = 'legal'
        label        = {i18n.t('maintenance.legal_control')}
        defaultValue = {data.legal}
        marginY      = 'S'
        tooltip      = {
          <div style={{whiteSpace: 'pre-line'}}>
            {i18n.t('maintenance.form.tooltip.legal')}
          </div>
        }
        fullWidth
      />

      <Checkbox
        name         = 'notify_external'
        label        = {i18n.t('maintenance.form.notify_external')}
        defaultValue = {remind_provider}
        marginY      = 'S'
        callback     = {notify => setRemindProvider(notify.isCheck)}
        fullWidth
      />

      {remind_provider &&
        <Input
          name          = 'notification_period'
          label         = {i18n.t('maintenance.notification_period')}
          tooltip       = {i18n.t('maintenance.form.tooltip.notification_period', { count: reminder_period })}
          defaultValue  = {reminder_period}
          change        = {e => setReminderPeriod(e.target.value)}
          type          = 'text'
          marginY       = 'M'
          hint          = {i18n.t('shared.days').toLowerCase()}
        />
      }
  
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="copy" />
        {i18n.t('maintenance.form.copy_existing')}
      </FormStyle.Header>
      <Select
        name             = 'filter-templates'
        label            = {i18n.t('maintenance.templates')}
        defaultValue     = {[planSelected.current]}
        callback         = {selectPlan}
        emptyCallback    = {resetPlan}
        searchUrl        = '/maintenance_plans/search'
        format           = {{ content: 'name', value: 'id'}}
        search
        withEmpty
      />
    </div>
  )
}

export default PlanFormInfo
