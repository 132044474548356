import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Input    from '@form/Input'

import { IControlRemark, IReport } from '@interfaces/index'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { REMARK_ACTIONS } from '@reducers/index'

interface ControlRemarkProps {
  remark?:       IControlRemark,
  report:        IReport,
  dispatch:      React.Dispatch<any>
  createRemark?: (any) => void
}

const ControlRemarkForm: React.FC<ControlRemarkProps> = ({
  remark,
  dispatch,
  createRemark
}) => {

  const { i18n } = useGlobalContextState()

  const updateRemark = data => dispatch({ type: REMARK_ACTIONS.UPDATE, remark, data })

  const saveRemark = (event) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const data     = Object.fromEntries(formData)

    remark ? updateRemark(data) : createRemark(data)
  }

  return(
    <form onSubmit={saveRemark}>
      <FormStyle.Header><FontAwesomeIcon icon="file-contract" />{i18n.t('control_remarks.title')}</FormStyle.Header>
      <Input
        name         = 'description'
        type         = 'text'
        defaultValue = {remark?.description}
        marginY      = 'M'
        required
      />
      <Input name="submit" defaultValue={remark ? i18n.t('actions.save') : i18n.t('actions.add')} type="submit" />
    </form>
  )
}

export default ControlRemarkForm
