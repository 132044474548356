import React, { useEffect, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout    from '@components/Callout'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'
import TaskBox    from '@components/Task/TaskBox'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import IMaintenance from '@interfaces/IMaintenance.d'

interface MaintenanceFromProps {
  maintenance:       IMaintenance
  updateMaintenance: React.Dispatch<React.SetStateAction<React.SyntheticEvent>>
  fromRails?:        boolean
}
const MaintenanceForm: React.FC<MaintenanceFromProps> = ({
  maintenance,
  updateMaintenance,
  maintenance_id,
  fromRails = false
}) => {

  const { i18n, fetchApi, CONSTANTS } = useGlobalContextState()

  const { RECURRENCY }                     = CONSTANTS.RECURRENCY.MODES
  const { INTERNAL, EXTERNAL, UNASSIGNED } = CONSTANTS.RECURRENCY.ASSIGNATIONS

  const [maintenanceData, setMaintenanceData] = useState(maintenance)
  const [_loading,        setLoading]         = useState(false)
  const [_state,          formDispatch]       = useReducer(maintenanceReducer, {
    fetchApi,
    setLoading
  })

  useEffect(() => {
    // Dirty fix to fetch only when RoR modale is open
    const ror_modale = document.querySelector(`#edit-maintenance-react${maintenance_id}`)
    if (fromRails && ror_modale) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName === 'class' && ror_modale.classList.contains('show')) {
            fetchApi({
              url:      `/maintenances/${maintenance_id}`,
              callback: data => setMaintenanceData(data),
            })
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(ror_modale, { attributes: true, childList: false, subtree: false })
    }
  }, [])

  const callback = event => {
    if (fromRails) {
      event.preventDefault()
      const formData    = new FormData(event.currentTarget)
      const data        = Object.fromEntries(formData)
      const maintenance = { data: maintenanceData }

      formDispatch({
        type:      MAINTENANCE_ACTIONS.UPDATE_MAINTENANCE,
        maintenance,
        data, callbacks: [
          () => window.location = '/maintenances/listing'
        ],
        fromRails,
      })
    } else {
      updateMaintenance(event)
    }
  }

  const removeUser = (task, user) => {
    const taskData = {...task, users: task.users.filter(u => u.id !== user.id)}
    const tasks = maintenanceData.tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const addUser = (task, user) => {
    if (task.users.map(u => u.id).includes(user.object.id)) return

    let taskData
    if (task.assignation === EXTERNAL) {
      taskData = {...task, users: [user.object] }
    } else {
      taskData = {...task, assignation: (user ? INTERNAL : UNASSIGNED), users: [...task.users, user.object] }
    }
    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  if (!maintenanceData) return <div>Loading…</div>

  return <form onSubmit={callback}>

    {maintenanceData.mode == RECURRENCY &&
      <>
        <Callout
          icon       = {<FontAwesomeIcon icon="lightbulb" />}
          color      = 'var(--rep-warning)'
          background = 'var(--rep-warning-light)'
        >
          {i18n.t('maintenance.callout.will_not_affect_previous')}
        </Callout>

        <FormStyle.Label>
          {i18n.t('maintenance.start_date')}
        </FormStyle.Label>
        <DatePicker
          name = 'start'
          date = {maintenanceData.start}
          showTime
        />
      </>
    }

    <FormStyle.Label>
      {i18n.t('maintenance.default_assignment')}
    </FormStyle.Label>

    {maintenanceData.tasks.length
      ? maintenanceData.tasks.map((task, key) =>
        <TaskBox
          key          = {`${task.name}-${key}`}
          task         = {task}
          addUser      = {user => addUser(task, user)}
          removeUser   = {(task, user) => removeUser(task, user)}
        />)
      : <Callout
        marginY    = 'M'
        background = 'var(--rep-warning-light)'
        color      = 'var(--rep-warning)'
        border     = 'var(--rep-warning)'
      >
        {i18n.t('maintenance.no_tasks')}
      </Callout>
    }
    <Input type="submit" name="save" defaultValue={i18n.t('actions.save')} />
  </form>
}

export default MaintenanceForm
