import React, { useMemo, useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button       from '@components/Button'
import Card         from '@components/Card'
import Column       from '@components/Column'
import Cost         from '@components/Cost/Cost'
import CostForm     from '@components/Cost/Form'
import Tag          from '@components/Tag'

import { Filter }   from '@components/Column/Filter'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { COST_ACTIONS, costReducer } from '@reducers/index'

interface IReportCosts {
  height?:    string
  maxHeight?: string
  grow?:      boolean
}

const ReportsCosts: React.FC<IReportCosts> = ({
  height    = '100%',
  maxHeight = '100%',
  grow = true
}) => {

  const {
    initAPI,
    current_user,
    current_company,
    showModal,
    i18n,
    CONSTANTS,
    fetchApi,
    closeModal,
  } = useGlobalContextState()

  const {
    report,
    updateMethods,
  } = useReportContextState()

  const { COST } = CONSTANTS

  const API = initAPI({
    reportId:      report.id,
    updateMethods: updateMethods
  })

  const [_state, dispatch] = useReducer(costReducer, { report, fetchApi, closeModal, updateMethods })
  const createCost         = data => dispatch({ type:      COST_ACTIONS.CREATE, data, callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })

  const filters = useMemo(() => new Filter({
    references: {
      status: COST.STATUS,
      type:   COST.TYPE
    },
    filters: [
      {
        id:        COST.STATUS.TO_APPROVE,
        name:      i18n.t('offer_line.status.to_approve'),
        icon:      <FontAwesomeIcon icon="vote-yea" />,
        reference: 'status',
      },
      {
        id:        COST.STATUS.APPROVED,
        name:      i18n.t('offer_line.status.approved'),
        icon:      <FontAwesomeIcon icon="check" />,
        reference: 'status',
      },
      {
        id:        COST.STATUS.REJECTED,
        name:      i18n.t('offer_line.status.rejected'),
        icon:      <FontAwesomeIcon icon="times" />,
        reference: 'status',
      },
      {
        id:        COST.TYPE.HOURS,
        name:      i18n.t('offer_line.line_types.hours'),
        icon:      <FontAwesomeIcon icon="user-clock" />,
        reference: 'type',
      },
      {
        id:        COST.TYPE.TRANSPORT,
        name:      i18n.t('offer_line.line_types.transport'),
        icon:      <FontAwesomeIcon icon="truck-moving" />,
        reference: 'type',
      },
      {
        id:        COST.TYPE.EXTERNAL_INVOICE,
        name:      i18n.t('offer_line.line_types.external_invoice'),
        icon:      <FontAwesomeIcon icon="file-invoice-dollar" />,
        reference: 'type',
      },

      ...[current_company.permissions.access_stock &&
    {
      id:        COST.TYPE.INVENTORY,
      name:      i18n.t('offer_line.line_types.inventory'),
      icon:      <FontAwesomeIcon icon="cubes" />,
      reference: 'type',
    },
      ]].filter(i => i),
    perPage:            20,
    totalPages:         report.total_costs_pages,
    totalFilteredCount: report.total_filtered_costs,
    fetch:              API.fetchCosts,
    pageCallback:       data => updateMethods.moreCosts(data),
    callback:           updateMethods.costs
  }), [])

  const headerActions = useMemo(() => [!!report.permissions.can_create_cost &&
    <Button
      key   = 'create-cost'
      icon  = {<FontAwesomeIcon icon="plus" />}
      color = 'var(--rep-primary)'
      hover = 'var(--rep-primary-light)'
      click = {() => {
        showModal({
          title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
          content: <CostForm
            report={report}
            createCost={createCost}
          />
        })
      }}>
      {i18n.t('actions.add')}
    </Button>], [report.permissions])

  const emptyCard = useMemo(() => (current_user.managing
    ? <Card
      border    = 'var(--rep-neutral-primary-light)'
      togglable = {false}
    >
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="piggy-bank" /></p>
        {i18n.t('offer_line.page.no_costs')}
      </Style.EmptyColumn>
    </Card>
    : <Card
      border    = 'var(--rep-neutral-primary-light)'
      togglable = {false}
    >
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon='history' /></p>
        {i18n.t('offer_line.page.no_activities')}
      </Style.EmptyColumn>
    </Card>
  ),[])

  const emptyFilters = useMemo(() =>
    <Card
      border    = 'var(--rep-neutral-primary-light)'
      togglable = {false}
    >
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="business-time" /></p>
        {i18n.t('offer_line.page.no_filtered_activities')}
      </Style.EmptyColumn>
    </Card>, [])

  const footer = useMemo(() =>
    <Tag
      icon           = {<FontAwesomeIcon icon="receipt" />}
      hover          = 'var(--rep-success-light)'
      color          = 'var(--rep-success)'
      tooltipContent={i18n.t('offer_line.total_pending', { total: Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(Number.parseFloat(report.costs_amount_pending)) })}
    >
      {i18n.t('offer_line.total_approved', { total: Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(Number.parseFloat(report.costs_amount_approved)) })}
    </Tag>, [report.costs_amount_approved, report.costs_amount_pending])

  return (
    <Column
      togglable
      open
      paginated
      withFilters
      height               = {height}
      maxHeight            = {maxHeight}
      grow                 = {grow}
      title                = {current_user.managing ? i18n.t('offer_line.costs') : i18n.t('offer_line.activity')}
      totalCount           = {report.total_filtered_costs}
      totalFiltered        = {report.total_filtered_costs}
      totalPages           = {report.total_costs_pages}
      currentCount         = {report.offer_lines.length}
      headerActions        = {headerActions}
      showEmptyContent     = {!report.total_costs}
      emptyContent         = {emptyCard}
      showEmptyFilters     = {!!report.total_costs && !report.total_filtered_costs}
      emptyFilters         = {emptyFilters}
      filters              = {filters}
      footer               = {!!report.offer_lines.length && report.permissions.can_see_prices && footer}>
      {report.offer_lines.map(cost =>
        <Cost
          key           = {cost.id}
          cost          = {cost}
          report        = {report}
          updateMethods = {updateMethods}
        />
      )}
    </Column>
  )
}

export default ReportsCosts
