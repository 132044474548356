import React from 'react'

import Wrapper    from '../../Wrapper'

import Calendar from './Calendar'

import { MaintenancesContextProvider } from '@context/MaintenancesContext'

import { ICompany, IUser } from '@interfaces/index'

interface ReportMainProps {
  maintainables:    any[],
  current_user:     IUser,
  current_company:  ICompany,
  token:            string,
  locale:           string,
  pagination:       { page: number, pages: number, next: number | null }
  companyStartWeek: number
  permissions: {
    maintenance_plans: {
      can_create_maintenance_plan: boolean,
      can_update_maintenance_plan: boolean
    },
    maintenances: {
      can_create_maintenance: boolean
    }

  }
}

const MaintenanceCalendar: React.FC<ReportMainProps> = ({
  maintainables,
  pagination,
  companyStartWeek,
  current_user,
  current_company,
  permissions,
  token,
  locale
}) => {

  return (
    <MaintenancesContextProvider
      serverMaintainables = {maintainables}
      initialPagination   = {pagination}
      current_user        = {current_user}
      companyStartWeek    = {companyStartWeek}
      permissions         = {permissions}
    >
      <Wrapper
        current_user    = {current_user}
        current_company = {current_company}
        token           = {token}
        locale          = {locale}
      >
        <Calendar />
      </Wrapper>
    </MaintenancesContextProvider>
  )
}

export default MaintenanceCalendar
