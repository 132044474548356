import React, { useEffect, useRef, useState } from 'react'
import FieldInputProps from './types.d'

import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'
import Select     from '@form/Select'

import { useGlobalContextState } from '@context/GlobalContext'

const FieldInput: React.FC<FieldInputProps> = ({
  background  = 'var(--rep-primary-light)',
  border      = 'var(--rep-primary)',
  color       = 'var(--rep-primary)',
  defaultValue,
  disabled    = false,
  field,
  marginY,
  width,
  required    = false,
  blur        = null,
  callback    = null,
  click       = null,
  focus       = null,
  keyUp       = null,
}) => {

  const { CONSTANTS } = useGlobalContextState()

  const [value, setValue] = useState({ field_id: field.id, value: defaultValue })
  const inputRef = useRef(false)


  const handleCheckboxChange = (response) => {
    setValue({
      field_id: field.id,
      value:    response.isCheck
    })
  }

  const handleDateChange = (response) => {
    setValue({
      field_id: field.id,
      value:    response
    })
  }

  const handleNumberInput = (response) => {
    setValue({
      field_id: field.id,
      value:    response.target.value
    })
  }

  const handleSelectInput = (response) => {
    setValue({
      field_id: field.id,
      value:    response.value
    })
  }

  const handleTextInput = (response) => {
    setValue({
      field_id: field.id,
      value:    response.target.value
    })
  }

  useEffect(() => {
    if (inputRef?.current) {
      callback(value)
    }
    inputRef.current = true
  }, [value])

  return (
    <>
      {field.reply_type === CONSTANTS.REPLY_TYPES.DATE &&
        <DatePicker
          allowClear
          callback = {handleDateChange}
          date     = {defaultValue}
          disabled = {disabled}
          label    = {field.name}
          name     = {field.name}
          required = {required}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.DATE_TIME &&
        <DatePicker
          allowClear
          callback = {handleDateChange}
          date     = {defaultValue}
          disabled = {disabled}
          label    = {field.name}
          name     = {field.name}
          required = {required}
          showTime = {true}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.FREE_TEXT &&
        <Input
          background   = {background}
          blur         = {blur}
          border       = {border}
          change       = {handleTextInput}
          click        = {click}
          color        = {color}
          defaultValue = {defaultValue}
          disabled     = {disabled}
          focus        = {focus}
          keyUp        = {keyUp}
          label        = {field.name}
          marginY      = {marginY}
          name         = {field.name}
          required     = {required}
          type         = "text"
          width        = {width}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.MULTIPLE_CHOICE &&
        <Select
          callback     = {handleSelectInput}
          defaultValue = {[value]}
          disabled     = {disabled}
          format       = {{ content: 'content', value: 'value' }}
          label        = {field.name}
          marginY      = {marginY}
          name         = {field.name}
          options      = {field.choice.map((choice) => ({ content: choice, value: choice }))}
          required     = {required}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.NUMBER &&
        <Input
          background   = {background}
          blur         = {blur}
          border       = {border}
          change       = {handleTextInput}
          click        = {click}
          color        = {color}
          defaultValue = {defaultValue}
          disabled     = {disabled}
          focus        = {focus}
          keyUp        = {keyUp}
          label        = {field.name}
          marginY      = {marginY}
          name         = {field.name}
          required     = {required}
          type         = "number"
          width        = {width}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.TRUE_FALSE &&
        <Checkbox
          callback     = {handleCheckboxChange}
          defaultValue = {defaultValue === 'true'}
          disabled     = {disabled}
          label        = {field.name}
          marginY      = {marginY}
          name         = {field.name}
          required     = {required}
        />
      }


    </>
  )
}

export default FieldInput
