import React, { useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout         from '@components/Callout'
import Chat            from '@components/Chat'
import ReportCosts     from './ReportCosts'
import ReportDocuments from './ReportDocuments'
import ReportHeader    from './ReportHeader'
import ReportRemarks   from './ReportRemarks'
import ReportTasks     from './ReportTasks'
import Switch          from '@components/Switch'
import TaskForm        from '@components/Task/Form'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

const ReportBody = () => {
  const {
    i18n,
    current_user,
    modalDisplay,
    showModal,
    isDesktop,
    CONSTANTS,
  } = useGlobalContextState()

  const {
    report,
    updateMethods,
    view,
    setView,
  } = useReportContextState()

  const { TYPE_VALUES } = CONSTANTS.REPORT_TYPES

  useEffect(() => {
    if (report.permissions.can_view_report                &&
        !report.tasks.length                              &&
        !report.status === CONSTANTS.REPORT.STATUS.CLOSED &&
        current_user.managing                             &&
        report.suggested_user
    ) showModal({
      title:   i18n.t('todo.actions.add_task'),
      content: <TaskForm report={report} />
    })
  }, [])

  const layout = useMemo(() => {
    // Mobile versions
    if (!isDesktop) {
      switch(view) {
        case CONSTANTS.VIEW.VIEW_INFO:    return <ReportDocuments />
        case CONSTANTS.VIEW.VIEW_CHAT:    return <Chat
          report        = {report}
          updateMethods = {updateMethods}
          height        = '45dvh'
        />
        case CONSTANTS.VIEW.VIEW_TASKS:   return <ReportTasks   />
        case CONSTANTS.VIEW.VIEW_COSTS:   return <ReportCosts   />
        case CONSTANTS.VIEW.VIEW_REMARKS: return <ReportRemarks />
      }
    } else {
      // If costs are activated
      if (report.permissions.can_see_costs) {
        return (<>
          <Style.Column>
            {report.type.document !== TYPE_VALUES.HIDDEN &&
              <ReportDocuments
                open      = {!!report.documents.length}
                height    = '100%'
                maxHeight = '50%'
              />
            }
            <Chat
              report         = {report}
              updateMethods  = {updateMethods}
              height         = '100%'
              maxHeight      = '100%'
            />
          </Style.Column>
          <Style.Column>
            {report.is_legal_control &&
              <ReportRemarks
                open       = {!!report.remarks.length}
                height     = '100%'
                maxHeight  = '50%'
              />
            }
            <ReportTasks
              height     = '50%'
              grow       = {true}
            />
          </Style.Column>
          <Style.Column>
            <ReportCosts
              height = '100%'
            />
          </Style.Column>
        </>)
      } else {
        // Without costs
        return (<>
          <Style.Column>
            {report.type.document !== TYPE_VALUES.HIDDEN &&
              <ReportDocuments
                height = '50%'
                open   = {!!report.documents.length}
              />
            }
            <Chat
              report         = {report}
              updateMethods  = {updateMethods}
            />
          </Style.Column>
          <Style.Column>
            {report.is_legal_control &&
              <ReportRemarks
                open      = {!!report.remarks.length}
                height    = '100%'
                maxHeight = '50%'
              />
            }
            <ReportTasks
              height = '50%'
              grow   = {true}
            />
          </Style.Column>
        </>)
      }
    }
  }, [report, isDesktop, view])

  const switchOptions = useMemo(() => {
    const options = [
      { content: i18n.t('shared.conversations'), icon: <FontAwesomeIcon icon="comment" />, value: CONSTANTS.VIEW.VIEW_CHAT },
      { content: i18n.t('todo.todos'),           icon: <FontAwesomeIcon icon="list" />,    value: CONSTANTS.VIEW.VIEW_TASKS },
    ]
    if (report.type.document !== TYPE_VALUES.HIDDEN) {
      options.unshift({ content: i18n.t('shared.informations'),  icon: <FontAwesomeIcon icon="receipt" />, value: CONSTANTS.VIEW.VIEW_INFO })
    }
    if (report.is_legal_control)
      options.push({
        content: i18n.t('control_remarks.name'),
        icon:    <FontAwesomeIcon icon="scale-balanced" />,
        value:   CONSTANTS.VIEW.VIEW_REMARKS
      })
    if (report.permissions.can_see_costs)
      options.push({
        content: i18n.t(`offer_line.${current_user.managing ? 'costs' : 'activity'}`),
        icon:    <FontAwesomeIcon icon="history" /> ,
        value:   CONSTANTS.VIEW.VIEW_COSTS
      })
    return options
  }, [])

  return (
    <Style.ReportBodyLayout
      isDesktop = {isDesktop}
      modalOpen = {modalDisplay}
      withCosts = {report.permissions.can_see_costs}
    >
      {report.permissions.can_view_report
        ? <>
          <ReportHeader />
          {!isDesktop &&
              <Switch
                name     = 'mobile-switch'
                options  = {switchOptions}
                selected = {view}
                callback = {setView}
              />
          }
          {layout}
        </>
        : <Callout
          title      = 'Unauthorized'
          border     = 'var(--rep-danger)'
          color      = 'var(--rep-danger)'
          background = 'var(--rep-danger-light)'
        >
          {i18n.t('report.page.unauthorized')}
        </Callout>
      }
    </Style.ReportBodyLayout>
  )
}

export default ReportBody
