import React, { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'

import Button from '@components/Button'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

/**
 * @description Modal component. Triggered by showModal from GlobalContext.
 */
const Modal = () => {

  const { isDesktop, modalContent, modalDisplay, closeModal } = useGlobalContextState()

  const modalRef = useRef(null)

  useEffect(() => {
    if (modalDisplay) modalRef.current.focus()
  }, [modalDisplay])

  const handleKeyDown = e => {
    if (e.code === 'Escape') { closeModal() }
  }

  return (
    <>
      {!!isDesktop &&
        <Style.ModalOverlay
          as={motion.div}
          initial={{
            opacity:    0,
            visibility: 'hidden'
          }}
          animate={{
            opacity:    modalDisplay ? 0.7 : 0,
            visibility: modalDisplay ? 'visible' : 'hidden',
          }}
          onClick={closeModal}
        />
      }

      <Style.Modal
        as    = {motion.div}
        style = {{
          left:   isDesktop ? '25%' : '0',
          height: isDesktop ? 'calc(100dvh - 10dvh)' : '100dvh',
          width:  isDesktop ? '50vw' : '100%',
        }}
        tabIndex  = {modalDisplay ? 1 : -1}
        onKeyDown = {handleKeyDown}
        ref       = {modalRef}
        initial   = {{
          y: '-100%'
        }}
        transition = {{
          ease:     'linear',
          duration: .3,
        }}
        animate = {{
          zIndex:   2500,
          position: 'fixed',
          y:        modalDisplay ? (isDesktop ? '5%' : '0') : '-110%'
        }}
      >
        <Style.ModalTitle>
          <h3>{modalContent?.title}</h3>
          {modalContent?.titleDescription && <p>{modalContent?.titleDescription}</p>}
          <Button
            icon={<FontAwesomeIcon icon="times" />}
            click={closeModal}
            background='var(--rep-primary-light)'
            color='var(--rep-primary)'
          />
        </Style.ModalTitle>
        <Style.ModalContent>
          {modalContent?.content}
        </Style.ModalContent>
        {modalContent?.action &&
          <Style.ModalAction>{modalContent?.action}</Style.ModalAction>
        }
      </Style.Modal>
    </>
  )
}

export default Modal
