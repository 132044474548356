import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Avatar from '@components/Avatar'
import Tag    from '@components/Tag'

import * as Style from './style'

import { IReport } from '@interfaces/index'

import { useGlobalContextState } from '@context/GlobalContext'

const ReportCard: React.FC<{ report: IReport }> = ({ report }) => {

  const {
    i18n,
    current_user,
    current_company,
    CONSTANTS,
  } = useGlobalContextState()

  const status = ({
    [CONSTANTS.REPORT.STATUS.NEW]:
      <Tag
        color          = 'var(--rep-primary)'
        icon           = {<FontAwesomeIcon icon="star" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ASSIGNED]:
      <Tag
        color          = 'var(--rep-accent)'
        icon           = {<FontAwesomeIcon icon="users" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ONGOING]:
      <Tag
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon={['far', 'circle']} fade />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.DONE]:
      <Tag
        color          = 'var(--rep-warning)'
        icon           = {<FontAwesomeIcon icon="clipboard-check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t('report.status.done_tasks')}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ON_HOLD]:
      <Tag
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="pause" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.TO_APPROVE]:
      <Tag
        color          = 'var(--rep-warning)'
        icon           = {<FontAwesomeIcon icon="check-to-slot" />}
        tooltipTitle = {i18n.t('report.report_status')}
        tooltipContent = {i18n.t('report.to_be_validated_by', { name: report.validated_by?.name || '' })}
      >
        {i18n.t('report.to_be_validated')}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.APPROVED]:
      <Tag
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon="check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CLOSED]:
      <Tag
        color          = 'var(--rep-neutral-primary)'
        icon           = {<FontAwesomeIcon icon="lock" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CANCELED]:
      <Tag
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="ban" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>
  })[report.status] ?? <div></div>

  return (
    <a href={`/reports/${report.id}`}>
      <Style.ReportCard>
        <Style.ReportHeaderContainer>
          <Style.ReportHeader>
            {status}
            <Tag
              tooltipContent = {report.type.name}
              icon           = {<FontAwesomeIcon icon={report.type.logo || 'triangle-exclamation'} />}
              color          = {report.type.color}
              background     = {`${report.type.color}20`}
            />
            {!!report.deadline &&
              <Tag
                icon           = {report.deadline_delta >= 0 ? <FontAwesomeIcon icon="comment-dots" /> : <FontAwesomeIcon icon="triangle-exclamation" />}
                color          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
                background     = {report.deadline_delta >= 0 ? 'var(--rep-neutral-light)' : 'var(--rep-danger-light)'}
                tooltipContent = {<>
                  <div>{new Date(report.deadline).toLocaleDateString(i18n.locale)}</div>
                  {report.deadline_delta < 0 && <div>{report.deadline_string}</div>}
                </>}
                tooltipTitle   = {i18n.t('report.deadline')}
              >
                {report.deadline_delta < 0 && i18n.t('shared.late')}
              </Tag>
            }
            {!!report.urgent &&
              <Tag
                color      = 'black'
                background = 'var(--rep-danger)'
              >
                {i18n.t('report.urgent')}
              </Tag>
            }
            {!!report.priority &&
              <Tag
                icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
                color          = 'var(--rep-neutral-primary)'
                border         = 'var(--rep-neutral-primary-light)'
                tooltipContent = {i18n.t('report.priority')}
              >
                {report.priority.name}
              </Tag>
            }
            { (report.notification_count || 0) > 0 &&
              <Style.UnseenMsgCount>{report.notification_count}</Style.UnseenMsgCount>
            }
          </Style.ReportHeader>
          {current_company.permissions.access_material_requests && report.material_requests &&
            <Tag
              icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
              color          = 'var(--rep-warning)'
              background     = 'var(--rep-warning-light)'
              tooltipContent = {i18n.t('material_request.material_requested')}
            />
          }
        </Style.ReportHeaderContainer>

        <Style.ReportTitle>
          {report.title}
        </Style.ReportTitle>

        <Tag
          icon           = {<FontAwesomeIcon icon="hashtag" />}
          color          = 'var(--rep-neutral-primary)'
          background     = 'var(--rep-neutral-light)'
          hover          = 'var(--rep-neutral-primary-light)'
          border         = 'white'
          tooltipContent = {i18n.t('report.report_number')}
          marginY        = 'S'
        >
          {report.report_number}
        </Tag>

        {!!report.description &&
          <Style.ReportText>
            {report.description}
          </Style.ReportText>
        }
        <Style.ReportFooter>
          <Style.FooterLeft>
            {!!report.unit &&
              <Tag
                icon           = {<FontAwesomeIcon icon="location-dot" />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'transparent'
                tooltipTitle   = {i18n.t('unit.unit')}
                tooltipContent = {report.unit.path_string}
              >
                {report.unit.name}
              </Tag>
            }
            {!!report.amenity &&
              <Tag
                icon           = {<FontAwesomeIcon icon="gear" />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'transparent'
                tooltipTitle   = {i18n.t('amenity.amenity')}
                tooltipContent = {report.amenity.path_string}
              >
                {report.amenity.name}
              </Tag>
            }
          </Style.FooterLeft>
          <Style.AvatarIcons>
            {!!report.requester &&
              <Avatar
                firstName      = {report.requester?.first_name}
                lastName       = {report.requester?.last_name}
                title          = {i18n.t('shared.requested_by')}
                background     = 'var(--rep-neutral-light)'
                color          = 'var(--rep-neutral)'
                tooltipContent = {
                  <>
                    <div>
                      {i18n.t('report.created_by_on', { date: new Date(report.created_at).toLocaleDateString(), user: `${report.requester?.name}` })}
                    </div>
                    {report.requester?.phone &&
                      <div>
                        {<FontAwesomeIcon icon="phone" />} {report.requester?.phone}
                      </div>
                    }
                  </>
                }
              />
            }
            {report.responsible &&
              <Avatar
                firstName      = {report.responsible?.first_name}
                lastName       = {report.responsible?.last_name}
                title          = {i18n.t('shared.responsible')}
                background     = 'var(--rep-warning-light)'
                border         = {report.responsible?.id === current_user.id && 'var(--rep-warning)'}
                color          = 'var(--rep-warning)'
                tooltipContent = {
                  <>
                    <div>
                      {`${report.responsible?.name}`}
                    </div>
                    {report.responsible?.phone &&
                      <div>
                        {<FontAwesomeIcon icon="phone" />} {report.responsible?.phone}
                      </div>
                    }
                  </>
                }
              />
            }
          </Style.AvatarIcons>
        </Style.ReportFooter>
      </Style.ReportCard>
    </a>
  )

}

export default ReportCard
