import React from 'react'

import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'

import { useGlobalContextState }       from '@context/GlobalContext'

const ReactivateForm = ({ maintenance, callback }) => {

  const { i18n } = useGlobalContextState()

  return (
    <form onSubmit={callback}>
      <DatePicker
        label = {i18n.t('maintenance.reactivate_from')}
        name  = 'start'
        date  = {maintenance.data.start}
        showTime
        required
      />
      <Input
        name         = "reactive-maintenance"
        type         = "submit"
        defaultValue = {i18n.t('maintenance.actions.plan_maintenance')}
      />
    </form>
  )
}

export default ReactivateForm
