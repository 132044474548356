import styled, { css } from 'styled-components'

export const FilterList = styled.div`
  background:                 white;
  border-top:                 1px solid var(--rep-neutral-light);
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
  display:                    grid;
  column-gap:                 8px;
  grid-template-columns:      ${props => props.isDesktop ? '1fr 1fr 1fr' : '1fr'};
  height:                     ${props => props.toggled ? 'auto' : 0};
  margin:                     16px;
  padding-top:                8px;
  width:                      auto
  & > div {
    width:     ${props => props.isDesktop ? 'auto' : '100%'};
    min-width: ${props => props.isDesktop ? '32%' : '100%'}
  }
`

export const HeaderContent = styled.div`
  display:         flex;
  flex-wrap:       wrap;
  gap:             8px;
  justify-content: space-between;
  margin:          16px;
  width:           auto;
`

export const HeaderFiltersAndOrdering = styled.div`
  display:          flex;
  gap:              ${props => props.isDesktop ? '1rem' : 'none'};
  justify-content:  ${props => props.isDesktop ? 'flex-start' : 'space-between'};
  width:            ${props => props.isDesktop ? 'none' : '100%'};
`

export const HeaderStyles = styled.div`
  background:    white;
  border:        1px solid var(--rep-neutral-primary-light);
  border-radius: 8px;
  grid-column:   1 / span 3;
  height:        min-content;
  margin-top:    6px;
`

export const Column = styled.div`
  display:        flex;
  flex-direction: column;
  height:         100%;
  max-height:     90vh;
`

export const ColumnHeader = styled.div`
  align-items:                center;
  background:                 white;
  border:                     1px solid var(--rep-neutral-primary-light);
  border-bottom:              ${props => props.toggled ? 'none' : '1px solid var(--rep-neutral-primary-light)'};
  border-radius:              5px;
  border-bottom-left-radius:  0;
  border-bottom-right-radius: 0;
  box-shadow:                 0 0 3px var(--rep-neutral-primary-light);
  display:                    flex;
  justify-content:            space-between;
  min-height:                 3rem;
  padding:                    0 8px;
`

export const ColumnTitle = styled.div`
  display:     flex;
  font-size:   1rem;
  font-family: 'Inter', sans-serif;
  user-select: none;
  sup {
    color:       var(--rep-primary);
    font-weight: 900;
    margin-left: 4px;
    top:         unset;
  }
`

export const ColumnContent = styled.div`
  background:              #FBFCFD;
  border:                  1px solid var(--rep-neutral-primary-light);
  border-top:              none;
  border-radius:           5px;
  border-top-left-radius:  0;
  border-top-right-radius: 0;
  box-shadow:              0 0 3px var(--rep-neutral-primary-light);
  display:                 flex;
  flex-direction:          column;
  flex-grow:               1;
  max-height:              100%;
  margin-bottom:           6px;
  min-height:              90%;
  overflow:                scroll;
  padding:                 8px;
  padding-bottom:          0;
  scrollbar-width:         none;
  -ms-overflow-style:      none;
`

export const ReportMobileStyles = css`
  text-align: center;
  padding:    0px 8px 100px 8px;

  @media only screen and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea {
      font-size: 11px !important;
    }
    input:focus,
    select:focus,
    textarea:focus {
      font-size: 16px !important;
    }
  }
`

export const ReportDesktopStyles = css`
  box-sizing:            border-box;
  display:               grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-template-rows:    min-content minmax(1em, 100vh);
  grid-column-gap:       12px;
  grid-row-gap:          12px;
  height:                100vh;
  max-height:            95vh;
  overflow:              auto;
  overflow-x:            hidden;
  /* padding:               0px 8px 0px 8px; */
  position:              relative;
  width:                 100%;
`

export const ReportListLayout = styled.div`
  font-family: Inter, sans-serif;
  ${props => props.isDesktop
    ? ReportDesktopStyles
    : ReportMobileStyles
};
`

export const EmptyList = styled.div`
  align-items:     center;
  border-radius:   5px;
  color:           var(--rep-neutral-primary);
  display:         flex;
  flex-direction:  column;
  gap:             8px;
  height:          100px;
  justify-content: center;
  padding:         12px 8px;
  text-align:      center;
  svg {
    font-size: 1.5rem;
  }
`

export const Loading = styled.div`
  align-items:     center;
  color:           var(--rep-primary);
  display:         flex;
  flex-direction:  column;
  font-size:       16px;
  height:          100px;
  justify-content: center;
  opacity:         .7s;
`
