import React from 'react'

import Button      from '@components/Button'
import WithTooltip from '@components/WithTooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './multiselectStyle'

interface MultiSelectProps {
  name:          string
  icon?:         React.ReactNode
  tooltip?:      string
  confirmText?:  string
  removeAction?: (any) => void
  children?:     React.ReactNode
}
const MultiselectItem: React.FC<MultiSelectProps> = ({
  name,
  icon,
  tooltip,
  confirmText,
  removeAction,
  children
}) => {
  return(
    <Style.Item>
      {tooltip
        ? <WithTooltip content={tooltip}>
          {icon} {name}
        </WithTooltip>
        : <>{icon} {name}</>
      }
      {children}
      {!!removeAction &&
        <Style.removeIcon>
          <Button
            click       = {removeAction}
            icon        = {<FontAwesomeIcon icon="times" />}
            border      = 'var(--rep-danger-light)'
            background  = 'var(--rep-danger-light)'
            color       = 'var(--rep-danger)'
            marginY     = 'S'
            confirmText = {confirmText}
            confirmIcon = {<FontAwesomeIcon icon="trash" />}
            confirm
          />
        </Style.removeIcon>
      }
    </Style.Item>
  )
}

export default MultiselectItem
