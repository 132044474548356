import React from 'react'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FETCH            = 'TASK_FETCH'
const CREATE           = 'TASK_CREATE'
const UPDATE           = 'TASK_UPDATE'
const START            = 'TASK_START'
const STOP             = 'TASK_STOP'
const DONE             = 'TASK_DONE'
const OPEN             = 'TASK_OPEN'
const APPROVE          = 'TASK_APPROVE'
const ALL_TASK_APPROVE = 'TASK_ALL_TASK_APPROVE'
const DESTROY          = 'TASK_DESTROY'

const UPDATE_PERIOD    = 'TASK_UPDATE_PERIOD'
const DELETE_PERIOD    = 'TASK_DELETE_PERIOD'
const CREATE_FORM      = 'TASK_CREATE_FORM'
const ANSWER_QUESTION  = 'TASK_ANSWER_QUESTION'
const SELF_ASSIGN      = 'TASK_SELF_ASSIGN'

export const TASK_ACTIONS = {
  FETCH:            FETCH,
  CREATE:           CREATE,
  UPDATE:           UPDATE,
  START:            START,
  STOP:             STOP,
  DONE:             DONE,
  OPEN:             OPEN,
  APPROVE:          APPROVE,
  ALL_TASK_APPROVE: ALL_TASK_APPROVE,
  DESTROY:          DESTROY,
  UPDATE_PERIOD:    UPDATE_PERIOD,
  DELETE_PERIOD:    DELETE_PERIOD,
  CREATE_FORM:      CREATE_FORM,
  ANSWER_QUESTION:  ANSWER_QUESTION,
  SELF_ASSIGN:      SELF_ASSIGN,
}

export function taskReducer(state, action) {

  const { report, task, setAnimation, API, updateMethods, fetchApi, closeModal } = state

  const callbacks = action.callbacks || []
  const params    = new URLSearchParams(action.params).toString()

  switch (action.type) {
    case FETCH:
      fetchApi({
        url:      `/reports/${report.id}/rep_tasks.json`,
        method:   'GET',
        callback: updateMethods.tasks
      })
      return state

    case CREATE:
      fetchApi({
        url:       `/reports/${report.id}/to_do_items/rep_create.json`,
        method:    'POST',
        body:      action.data,
        callback:  data => updateMethods.addTask(data.response),
        followUps: [
          ...callbacks,
          closeModal
        ]
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/to_do_items/${task.id}/rep_update.json`,
        method:    'PATCH',
        body:      action.data,
        callback:  data => updateMethods.task(data.response),
        followUps: callbacks
      })
      return state

    case START:
      setAnimation(action.animation)
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_start.json?${params}`,
        method:       'PATCH',
        endAnimation: setAnimation,
        callback:     data => updateMethods.task(data.response),
        followUps:    callbacks
      })
      return state

    case STOP:
      setAnimation(action.animation)
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_stop.json?${params}`,
        method:       'PATCH',
        endAnimation: setAnimation,
        callback:     data => updateMethods.task(data.response),
        followUps:    callbacks
      })
      return state

    case DONE:
      setAnimation(action.animation),
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_done.json?${params}`,
        method:       'PATCH',
        body:         action.data,
        endAnimation: setAnimation,
        callback:     data => updateMethods.task(data.response),
        followUps:    callbacks
      })
      return state

    case OPEN:
      setAnimation(action.animation)
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_open.json?${params}`,
        method:       'PATCH',
        endAnimation: setAnimation,
        callback:     data => updateMethods.task(data.response),
        followUps:    [
          () => API.fetchMinimalReport().then(updateMethods.minimalReport)
        ]
      })
      return state

    case APPROVE:
      setAnimation(action.animation)
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_approve.json`,
        method:       'PATCH',
        endAnimation: setAnimation,
        callback:     data => updateMethods.task(data.response),
        followUps:    [
          () => API.fetchMinimalReport().then(updateMethods.minimalReport)
        ]
      })
      return state

    case ALL_TASK_APPROVE:
      const todoIds = report.tasks.filter(c => !['closed'].includes(c.status)).map(c => c.id)
      fetchApi({
        url:       `/reports/${report.id}/to_do_items/rep_approve_all.json`,
        method:    'PATCH',
        body:      { todo_ids: todoIds },
        followUps: [
          () => API.fetchTasks().then(updateMethods.tasks),
          () => API.fetchCosts().then(updateMethods.costs),
          () => API.fetchMinimalReport().then(updateMethods.minimalReport)
        ],
        withLoading: true
      })
      return state

    case DESTROY:
      setAnimation(action.animation)
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_destroy.json`,
        method:       'DELETE',
        endAnimation: setAnimation,
        callback:     data => updateMethods.removeTask(data.response),
        followUps:    callbacks
      })
      return state

    case UPDATE_PERIOD:
      fetchApi({
        url:    `/period_to_todos/${action.period.id}/rep_update.json`,
        method: 'PATCH',
        body:   {
          assigned_date: action.dates,
          show_time:     action.time
        },
        callback:  data => updateMethods.task(data.response),
        followUps: [closeModal]
      })
      return state

    case DELETE_PERIOD:
      fetchApi({
        url:       `/to_do_items/${task.id}/rep_unassign.json?period_id=${action.period.id}`,
        method:    'DELETE',
        callback:  data => updateMethods.task(data.response),
        followUps: [...callbacks, closeModal]
      })
      return state

    case ANSWER_QUESTION:
      fetchApi({
        url:       `/form_replies/${action.reply.id}/answer.json`,
        method:    'PATCH',
        body:      JSON.stringify({ answer: action.answer }),
        callback:  data => updateMethods.form(data.response, action.task),
        followUps: [...callbacks]
      })
      return state

    case SELF_ASSIGN:
      fetchApi({
        url:          `/to_do_items/${task.id}/rep_self_assign.json?${params}`,
        method:       'PATCH',
        callback:     data => updateMethods.task(data.response),
        followUps:    [...callbacks],
        endAnimation: setAnimation
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
