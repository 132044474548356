import React, { useEffect, useState } from 'react'

import { ITask } from '../interfaces'

// create contexts
const TaskContextState = React.createContext(null)

const useTaskContextState = () => {
  const context = React.useContext(TaskContextState)

  if (context === undefined) {
    throw new Error('useTaskContextState was used outside of its Provider')
  }
  return context
}

interface TaskContextProviderProps {
  children:   React.ReactNode,
  serverTask: ITask,
}

const TaskContextProvider: React.FC<TaskContextProviderProps> = ({ children, serverTask }) => {

  const [task, setTask] = useState(serverTask)

  useEffect(() => setTask(serverTask), [serverTask])

  const updateTask  = data  => setTask(_task => data)

  const updateMachineStop = data => setTask(task => ({...task, report: {...task.report, machine_stop: data }}))

  const addCost    = data => setTask(task => ({...task, costs: [...data, ...task.costs] }))
  const removeCost = data => setTask(task => ({...task, costs: task.costs.filter(c => c.id !== data.id)}))
  const updateCost = data => setTask(task => ({...task, costs: task.costs.map(c => c.id === data.id ? data : c)}))

  // TODO: Check if the document methods are used elsewhere to remove if/else
  const addDocument = data => {
    if (task.report) {
      setTask(task => ({ ...task,
        documents: [...task.documents, data],
        report:    {...task.report, documents: [...task.report.documents, data] }
      }))
    } else {
      setTask(task => ({ ...task,
        documents: [...task.documents, data]
      }))
    }
  }

  const removeDocument = data => {
    if (task.report) {
      setTask(task => ({ ...task,
        documents: task.documents.filter(d => d.id !== data.id),
        report:    {...task.report, documents: task.report.documents.filter(d => d.id !== data.id) }
      }))
    } else {
      setTask(task => ({ ...task,
        documents: task.documents.filter(d => d.id !== data.id)
      }))
    }
  }

  const updateForm = data => setTask(task => ({...task, forms: task.forms.map(f => f.id === data.id ? data : f)}))

  const addConversation     = conversation  => setTask(task => ({ ...task, report: {...task.report, conversations: [...task.report.conversations, conversation] }}))
  const updateConversation  = data          => setTask(task => ({ ...task, report: {...task.report, conversations: task.report.conversations.map(c => c.id === data.id ? data : c) }}))
  const updateConversations = conversations => setTask(task => ({ ...task, report: {...task.report, conversations }}))

  const updateMethods = {
    task:        updateTask,
    machineStop: updateMachineStop,

    addCost:    addCost,
    removeCost: removeCost,
    cost:       updateCost,

    addDocument:    addDocument,
    removeDocument: removeDocument,

    form: updateForm,

    addConversation: addConversation,
    conversation:    updateConversation,
    conversations:   updateConversations,
  }

  return (
    <TaskContextState.Provider value={{
      task, setTask, updateMethods
    }}>
      {children}
    </TaskContextState.Provider>
  )
}

export { TaskContextProvider, useTaskContextState }
