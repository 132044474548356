import styled from 'styled-components'

export const Item = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  color:           var(--rep-neutral-primary);
  border-bottom:   1px solid var(--rep-neutral-primary-light);
  padding:         4px 0;
  margin:          8px 0;
  border:          1px solid var(--rep-neutral-primary-light);
  border-radius:   5px;
  padding:         8px;
`

export const removeIcon = styled.div`
  color: var(--rep-warning);
`
