import styled, { css, keyframes } from 'styled-components'

export const ListStyles = styled.div`
  margin:         auto 0;
`

export const Logo = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  margin-bottom:   8px;
  img { width: 40px }
`

export const HeaderStyles = styled.div`
  grid-column:   1 / span 3;
  background:    white;
  border-radius: 8px;
  height:        min-content;
  border:        1px solid var(--rep-neutral-primary-light);
  padding:       16px;
  padding-top:   8px;
  margin:        12px 0;
`

export const HeaderTitle = styled.div`
  display:         flex;
  gap:             8px;
  margin-bottom:   8px;
  justify-content: space-between;
`

export const TasksListLayout = styled.div`
  font-family: Inter, sans-serif;
  position:    'relative';
  overflow:    scroll;
  height:      100dvh;
  ${props => props.isDesktop
    ? ReportDesktopStyles
    : ReportMobileStyles
};
`

export const ReportMobileStyles = css`
  padding: 0px 8px 100px 8px;

  @media only screen and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea {
      font-size: 11px !important;
    }
    input:focus,
    select:focus,
    textarea:focus {
      font-size: 16px !important;
    }
  }
`

export const ReportDesktopStyles = css`
  display:               grid;
  grid-template-columns: ${props => props.withCosts ? '4fr 6fr 5fr' : '2fr 3fr'};
  grid-template-rows:    min-content minmax(1em, 100vh);
  height:                100vh;
  max-height:            100vh;
  width:                 100%;
  box-sizing:            border-box;
  overflow:              auto;
  overflow-x:            hidden;
  overflow:              hidden;
  width:                 calc(100vw - 125px);
  grid-column-gap:       12px;
  grid-row-gap:          12px;
  padding:               0px 8px 0px 8px;
  position:              relative;
`

export const TaskContainer = styled.div`
  min-height: 50px;
`

export const TaskDayList = styled.div`
  margin-top: 20px;
`

export const TaskDateStyle = styled.div`
  text-transform: capitalize;
  background:   white;
  font-size:      1rem;
  padding:        8px;
  border-radius:  5px;
  margin-bottom:  8px;
  border:         1px solid var(--rep-neutral-primary-light);
  font-weight:    600;
  position:       sticky;
  top:            -1px;
  z-index:        10;
  transition:     .3s ease;

  sup {
    color:       var(--rep-primary);
    font-weight: 900;
    margin-left: 4px;
  }

  &.pinned {
    box-shadow: 0 4px 10px var(--rep-neutral-primary-light);
    padding:    16px 8px;
    border-top-left-radius:  0;
    border-top-right-radius: 0;
  }
`

export const DateSelection = styled.div`
  display:         flex;
  align-items:     center;
  justify-content: space-between;
  gap:             8px;
  font-size:       1rem;
  color:           var(--rep-primary);
  font-weight:     500;
  margin-bottom:   8px;
`

export const DateCalendar = styled.div`
  display:        flex;
  flex-direction: column;
  align-items:    center;
`

export const FilterList = styled.div`
  display:   flex;
  gap:       8px;
  flex-wrap: wrap;
`

export const Filters = styled.div`
  border-top:            1px solid var(--rep-neutral-light);
  display:               grid;
  column-gap:            8px;
  grid-template-columns: ${props => props.isDesktop ? '1fr 1fr 1fr' : '1fr'};
  height:                ${props => props.toggled ? 'auto' : 0};
  margin-top:            16px;
  padding-top:           8px;
  width:                 auto
  & > div {
    width:     ${props => props.isDesktop ? 'auto' : '100%'};
    min-width: ${props => props.isDesktop ? '32%' : '100%'}
  }
`

export const LateNumber = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  background:      var(--rep-danger);
  width:           20px;
  height:          20px;
  color:           white;
  font-weight:     700;
  border-radius:   50px;
`

export const LoadingAnimation = keyframes`
  0%  { transform: rotate(0deg) }
  60% { transform: rotate(-20deg) }
  0%  { transform: rotate(0deg) }
`

export const LoadingIcon = styled.div`
  animation-name:           ${LoadingAnimation};
  animation-duration:        2s;
  animation-iteration-count: infinite;
  font-size:                 2em;
  opacity:                   .7s;
`

export const Loading = styled.div`
  display:          flex;
  flex-direction:   column;
  justify-content:  center;
  align-items:      center;
  height:           100px;
  color:            var(--rep-neutral-primary);
`

export const LoadButton = styled.div`
  display:         flex;
  justify-content: center;
`

export const NoMoreTasks = styled.div`
  padding:          12px 8px;
  background:       var(--rep-neutral-primary-light);
  border-radius:    5px;
  color:            var(--rep-neutral-primary);
  text-align:       center;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  flex-direction:   column;
  margin:           24px 0;
  gap:              8px;
  svg {
    font-size: 1.5rem;
  }
`
