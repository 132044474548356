import React, { useEffect, useState } from 'react'

import Wrapper  from '../../Wrapper'

import PlanFormEquipments from './PlanFormEquipments'

import { MaintenancesContextProvider } from '@context/MaintenancesContext'

import { ICompany, IMaintenancePlan, IUser } from '@interfaces/index'
import fetchApi from '@utils/fetchApi'

interface PlanFormEquipmentProps {
  item:            IMaintenancePlan,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string,
  fromRails:       boolean
}


const EquipmentsForm: React.FC<PlanFormEquipmentProps> = ({
  plan_id,
  fromRails,
  current_user,
  current_company,
  token,
  locale,
}) => {

  const [data, setData] = useState({
    group:     null,
    units:     [],
    amenities: []
  })

  const [displayed, setDisplayed] = useState(false)

  useEffect(() => {
    const ror_modale = document.querySelector(`#edit-maintenance-equipments-react${plan_id}`)

    if (!ror_modale || !plan_id) return

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.attributeName === 'class' && ror_modale.classList.contains('show')) {
          fetchApi(token, {
            url:      `/maintenance_plans/${plan_id}`,
            callback: planData => {
              setData(planData)
              setDisplayed(true)
            }
          })
        }
      }
    }

    const observer = new MutationObserver(callback)

    observer.observe(ror_modale, { attributes: true, childList: false, subtree: false })
  }, [])

  if (!displayed) return <div>Loading…</div>

  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
      withModal       = {false}
    >
      <MaintenancesContextProvider
        current_user = {current_user}
      >
        <PlanFormEquipments
          data      = {data}
          setData   = {setData}
          fromRails = {fromRails}
          dateSelect
        />
      </MaintenancesContextProvider>
    </Wrapper>
  )
}

export default EquipmentsForm
