import React, { useCallback, useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar      from '@components/Avatar'
import Button      from '@components/Button'
import Callout     from '@components/Callout'
import Card        from '@components/Card'
import Dropdown    from '@components/Dropdown'
import Tag         from '@components/Tag'
import Thumbnail   from '@components/Form/FileUploader/Thumbnail'
import WithTooltip from '@components/WithTooltip'

import CostForm    from './Form'

import * as Style from './style'

import CostProps  from './types.d'

import useHumanMs from '@hooks/useHumanMs'

import { COST_ACTIONS, costReducer } from '@reducers/index'
import { useGlobalContextState } from '@context/GlobalContext'

const Cost: React.FC<CostProps> = ({
  cost,
  editable = true,
  report,
  updateMethods,
}) => {

  const {
    initAPI,
    closeModal,
    showModal,
    showAlert,
    closeAlert,
    i18n,
    fetchApi,
    current_user,
    CONSTANTS
  } = useGlobalContextState()

  const [animation, setAnimation] = useState(null)

  const API = initAPI({ reportId: report.id, costId: cost.id })

  const [_state, dispatch] = useReducer(costReducer, { report, cost, API, fetchApi, setAnimation, updateMethods, closeModal })

  // Initialize reducer with the actions.
  // State is not used in component as the data comes from the context.
  const updateCost   = data => dispatch({ type: COST_ACTIONS.UPDATE, data: data, callback: updateMethods.cost })
  const updateStatus = status => dispatch({ type: COST_ACTIONS.CHANGE_STATUS, status, animation: 'loading', callback: updateMethods.cost })
  const destroyLine  = ()     => dispatch({ type:      COST_ACTIONS.DESTROY, animation: 'loading', callback:  updateMethods.removeCost, callbacks: [
    closeAlert,
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })

  const showCostForm = useCallback(() => showModal({
    title:   i18n.t(`offer_line.edit_${current_user.managing ? 'cost' : 'activity'}`),
    content: <CostForm
      cost       = {cost}
      report     = {report}
      updateCost = {updateCost}
    />
  }), [cost])

  const actions = useMemo(() => {
    const actionsArray = []
    if (editable && cost.status !== CONSTANTS.COST.STATUS.TO_APPROVE && cost.permissions.reopen_cost) actionsArray.unshift({
      icon:       <FontAwesomeIcon icon="question" />,
      color:      'var(--rep-warning)',
      background: 'var(--rep-warning-light)',
      content:    i18n.t('offer_line.actions.reopen'),
      click:      () => updateStatus(CONSTANTS.COST.STATUS.TO_APPROVE)
    })

    if (editable && cost.status === CONSTANTS.COST.STATUS.APPROVED  && cost.permissions.approve_reject) actionsArray.unshift({
      icon:       <FontAwesomeIcon icon="times" />,
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      content:    i18n.t('offer_line.actions.reject'),
      click:      () => updateStatus(CONSTANTS.COST.STATUS.REJECTED)
    })

    if (editable && cost.status === CONSTANTS.COST.STATUS.REJECTED  && cost.permissions.approve_reject) actionsArray.unshift({
      icon:       <FontAwesomeIcon icon="check" />,
      color:      'var(--rep-success)',
      background: 'var(--rep-success-light)',
      content:    i18n.t('offer_line.actions.approve'),
      click:      () => updateStatus(CONSTANTS.COST.STATUS.APPROVED)
    })

    // if (editable && (cost.permissions.reopen_cost)) {
    //   actionsArray.push({ separator: true })
    // }

    if (editable && cost.permissions.update_cost) actionsArray.push({
      icon:       <FontAwesomeIcon icon="edit" />,
      color:      'var(--rep-primary)',
      background: 'var(--rep-primary-light)',
      content:    i18n.t('actions.edit'),
      click:      showCostForm
    })
    if (editable && cost.permissions.destroy_cost) actionsArray.push({
      icon:    <FontAwesomeIcon icon="trash-can" />,
      color:   'var(--rep-danger)',
      content: i18n.t('actions.destroy'),
      click:   () => showAlert(
        <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
          {i18n.t('actions.delete_confirm')}
        </Callout>,
        [{
          content:    i18n.t('actions.delete'),
          color:      'white',
          background: 'var(--rep-danger)',
          icon:       <FontAwesomeIcon icon="trash-can" />,
          click:      destroyLine
        }]
      )
    })

    return actionsArray
  }, [report, cost])

  const statusIcon = ({
    [CONSTANTS.COST.STATUS.ONGOING]:    <FontAwesomeIcon fixedWidth icon={['far', 'circle']} fade />,
    [CONSTANTS.COST.STATUS.TO_APPROVE]: <FontAwesomeIcon fixedWidth icon={['far', 'eye']} />,
    [CONSTANTS.COST.STATUS.APPROVED]:   <FontAwesomeIcon fixedWidth icon='check' />,
    [CONSTANTS.COST.STATUS.REJECTED]:   <FontAwesomeIcon fixedWidth icon='times' />,
  })[cost.status] ?? <FontAwesomeIcon  icon='dollar' />

  const costIcon = ({
    [CONSTANTS.COST.TYPE.HOURS]:            <FontAwesomeIcon icon="user-clock" />,
    [CONSTANTS.COST.TYPE.TRANSPORT]:        <FontAwesomeIcon icon="truck-moving" />,
    [CONSTANTS.COST.TYPE.INVENTORY]:        <FontAwesomeIcon icon="cubes" />,
    [CONSTANTS.COST.TYPE.EXTERNAL_INVOICE]: <FontAwesomeIcon icon="file-invoice-dollar" />,
  })[cost.line_type] ?? <FontAwesomeIcon icon="dollar-sign" />

  return (
    <Card
      animation   = {animation}
      opened      = {false}
      togglable   = {false}
      border      = 'var(--rep-neutral-primary-light)'
      headerTop   = {
        <Style.CostHeader>
          <Style.CostStatus status={cost.status}>
            {statusIcon}
            {i18n.t(`offer_line.status.${cost.status}`)}
          </Style.CostStatus>
          <Style.CostTopActions>
            {!!cost.material_request &&
              <Tag
                icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
                color          = {cost.material_request.status === 'requested' ? 'var(--rep-warning)' : 'var(--rep-success)'}
                background     = {cost.material_request.status === 'requested' ? 'var(--rep-warning-light)' : 'var(--rep-success-light)'}
                hover          = {cost.material_request.status === 'requested' ? 'var(--rep-warning-light)' : 'var(--rep-success-light)'}
                tooltipContent = {`[${cost.material_request.number}] Materials requested by ${cost.material_request.requester.name}`}
                click          = {() => window.open(`/material_requests/${cost.material_request.id}`, '_blank', 'noopener,noreferrer')}
              />
            }
            <Tag
              icon            = {costIcon}
              color           = 'var(--rep-neutral-primary)'
              background      = 'var(--rep-neutral-light)'
              hover           = 'var(--rep-neutral-primary-light)'
              tooltipContent  = {i18n.t('offer_line.type')}
            >
              {i18n.t(`offer_line.line_types.${cost.line_type}`)}
            </Tag>
            <Tag
              color           = 'var(--rep-neutral-primary)'
              background      = 'var(--rep-neutral-light)'
              hover           = 'var(--rep-neutral-primary-light)'
              tooltipContent  = {i18n.t('offer_line.date')}
            >
              {cost.start_time ? `${new Date(cost.start_time).toLocaleDateString(i18n.locale)} @ ${new Date(cost.start_time).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' })}`  : new Date(cost.date).toLocaleDateString(i18n.locale)}
            </Tag>
            {!!actions.length &&
              <Dropdown
                options     = {actions}
                withArrow   = {false}
                color       = 'var(--rep-primary)'
                icon        = {<FontAwesomeIcon icon="gear" />}
              />
            }
          </Style.CostTopActions>
        </Style.CostHeader>
      }
    >
      <Style.CostTitle>
        {cost.name ? cost.name : cost?.todo?.title }
        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '4px'}}>
          {!!cost.approved_by &&
            <Avatar
              firstName  = {cost.approved_by.first_name}
              lastName   = {cost.approved_by.last_name}
              background = 'var(--rep-success-light)'
              // border     = 'var(--rep-success-light)'
              color      = 'var(--rep-success)'
              title      = {i18n.t('offer_line.approved_on_date_by', {date: new Date(cost.approval_date).toLocaleDateString()})} />
          }
          {!!cost.rejected_by &&
            <Avatar
              firstName  = {cost.rejected_by.first_name}
              lastName   = {cost.rejected_by.last_name}
              // background = 'transparent'
              background = 'var(--rep-danger-light)'
              // border     = 'var(--rep-danger-light)'
              color      = 'var(--rep-danger)'
              title      = {i18n.t('offer_line.rejected_on_date_by', {date: new Date(cost.reject_date).toLocaleDateString()})}/>
          }
          {!!cost.for_user &&
            <Avatar
              firstName = {cost.for_user.first_name}
              lastName  = {cost.for_user.last_name}
              title     = {i18n.t('offer_line.added_by')}
            />
          }
        </div>
      </Style.CostTitle>

      {!!cost.todo &&
        <WithTooltip content={i18n.t('offer_line.associated_task')}>
          <Style.CostDescription>
            <FontAwesomeIcon icon="link" />
            {cost.todo.title}
          </Style.CostDescription>
        </WithTooltip>
      }

      {cost.line_type === CONSTANTS.COST.TYPE.INVENTORY && !!cost.material_quantity &&
        <Style.CostDetails>
          <h6>{i18n.t('material.location').toLowerCase()}</h6>
          <div></div>
          <h6>{i18n.t('material.category').toLowerCase()}</h6>
          <h6>{i18n.t('material.internal_ref').toLowerCase()}</h6>
          <div>{cost.material_quantity.location_name ? cost.material_quantity.location_name : '—'}</div>
          <div></div>
          <div>{cost.material_quantity.material.category || '—'}</div>
          <div>{cost.material_quantity.material.internal_ref || '—'}</div>
        </Style.CostDetails>
      }

      {cost.line_type === CONSTANTS.COST.TYPE.EXTERNAL_INVOICE &&
        <Style.CostDetails>
          <h6>{i18n.t('offer_line.invoice_nb').toLowerCase()}</h6>
          <div></div>
          <h6>{i18n.t('offer_line.order_nb').toLowerCase()}</h6>
          <h6>{i18n.t('offer_line.supplier').toLowerCase()}</h6>
          <div>{cost.invoice_nb || '—'}</div>
          <div></div>
          <div>{cost.order_nb || '—'}</div>
          <div>{cost.supplier || cost.service_provider?.name || '—'}</div>
        </Style.CostDetails>
      }

      <Style.CostDetails>
        <h6>{i18n.t('offer_line.quantity').toLowerCase()}</h6>
        {report.permissions.can_see_prices
          ? <>
            <div></div>
            <h6>{i18n.t('offer_line.unit_price').toLowerCase()}</h6>
            <h6>{i18n.t('offer_line.total').toLowerCase()}</h6>
          </>
          : <>
            <div></div>
            <div></div>
            <div></div>
          </>
        }

        { cost.line_type === CONSTANTS.COST.TYPE.HOURS
          ? <div>{useHumanMs(cost.quantity)}</div>
          : <div>{cost.quantity}</div>
        }
        {report.permissions.can_see_prices
          ? <>
            <div>&#xd7;</div>
            <div>{Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(cost.unit_price)}</div>
            <Style.CostTotal>{Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(cost.total)}</Style.CostTotal>
          </>
          : <>
            <div></div>
            <div></div>
            <div></div>
          </>
        }
      </Style.CostDetails>

      {cost.line_type === CONSTANTS.COST.TYPE.EXTERNAL_INVOICE &&
        !!cost.documents.length &&
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridRow: '100px', gap: '8px'}}>
            {cost.documents.map(doc =>
              <Thumbnail
                key          = {doc.id}
                file         = {doc}
                showFileName = {false}
              />
            )}
          </div>
      }

      {cost.permissions.approve_reject &&
        <Style.CostActions>
          <Button
            click = {() => updateStatus(CONSTANTS.COST.STATUS.APPROVED)}
            icon  = {<FontAwesomeIcon icon='check' />}
            color = 'var(--rep-success)'
            hover = 'var(--rep-success-light)'
          >
            {i18n.t('offer_line.actions.approve')}
          </Button>
          <Button
            click = {() => updateStatus(CONSTANTS.COST.STATUS.REJECTED)}
            icon  = {<FontAwesomeIcon icon="times" />}
            color = 'var(--rep-danger)'
            hover = 'var(--rep-danger-light)'
          >
            {i18n.t('offer_line.actions.reject')}
          </Button>
        </Style.CostActions>
      }
    </Card>
  )
}

export default Cost
