import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button          from '@components/Button'
import Callout         from '@components/Callout'
import Input           from '@components/Form/Input'
import MultiselectItem from '@components/Form/Select/MultiselectItem'
import Select          from '@components/Form/Select'
import Tag             from '@components/Tag'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { ITaskBox } from './types.d'

const TaskBox: React.FC<ITaskBox> = ({
  task,
  editAction,
  removeAction,
  addUser,
  removeUser,
  suggestedUser
}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { INTERNAL, EXTERNAL, UNASSIGNED } = CONSTANTS.RECURRENCY.ASSIGNATIONS

  const [provider, setProvider] = useState(null)

  return(
    <Style.TaskBox>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <h3>{task.name}</h3>
          {(!!task.hours || !!task.minutes) &&
            <Tag
              color          = 'var(--rep-primary)'
              border         = 'var(--rep-primary-light)'
              tooltipContent = "Durée prévue"
              icon           = {<FontAwesomeIcon icon="clock" />}
              marginY        = 'S'
            >
              {!!task.hours && `${task.hours}h`} {!!task.minutes && `${task.minutes}min`}
            </Tag>
          }
          {!!task.periodicity && task.periodicity !== 1 &&
            <Tag
              color          = 'var(--rep-primary)'
              border         = 'var(--rep-primary-light)'
              tooltipContent = "Périodicité"
              icon           = {<FontAwesomeIcon icon="rotate-right" />}
              marginY        = 'S'
            >
              {task.periodicity}
            </Tag>
          }
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          {!!editAction &&
            <Button
              click      = {() => editAction(task)}
              background = "var(--rep-primary-light)"
              color      = "var(--rep-primary)"
              icon       = {<FontAwesomeIcon icon="square-pen" />}
              marginY    = 'S'
            />
          }
          {!!removeAction &&
            <Button
              click       = {removeAction}
              background  = 'var(--rep-danger-light)'
              color       = 'var(--rep-danger)'
              icon        = {<FontAwesomeIcon icon='trash' />}
              confirmText = 'Remove ?'
              confirmIcon = {<FontAwesomeIcon icon='trash' />}
              marginY     = 'S'
              confirm
            />
          }
        </div>
      </div>

      <p>{task.description}</p>
      {(!!task.formsTemplates?.length ||!!task.formsCreated?.length) &&
        <FormStyle.Group style={{ justifyContent: 'flex-start' }}>
          {task.formsTemplates?.map((f, key) =>
            <Tag
              key        = {key}
              icon       = {<FontAwesomeIcon icon="receipt" />}
              background = "var(--rep-primary-light)"
              color      = "var(--rep-primary)"
            >
              {f.name}
            </Tag>
          )}
          {task.formsCreated?.map((f, key)   =>
            <Tag
              key        = {key}
              icon       = {<FontAwesomeIcon icon="receipt" />}
              background = "var(--rep-primary-light)"
              color      = "var(--rep-primary)"
            >
              {f.name}
            </Tag>
          )}
        </FormStyle.Group>
      }

      {!!task.users.length &&
        task.users.map(user =>
          <MultiselectItem
            key          = {user.id}
            name         = {user.name}
            icon         = {<FontAwesomeIcon icon={user.service_company ? 'user-tie' : 'user-gear'} />}
            removeAction = {removeUser ? (_event) => removeUser(task, user) : null}
          />
        )
      }

      <Input
        name         = {`task-${task.id}`}
        type         = "hidden"
        defaultValue = {task.users.map(u => u.id).toString()}
      />

      {!!suggestedUser &&
        <Callout type="primary" marginY='M'>
          {suggestedUser?.reason}
        </Callout>
      }

      {!!addUser && [INTERNAL, UNASSIGNED].includes(task.assignation) &&
        <Select
          label        = {i18n.t('user.users')}
          name         = 'user'
          searchUrl    = '/users/search'
          defaultValue = {[suggestedUser]}
          filters      = {[
            {
              id:      'technicians',
              name:    i18n.t('user.role.technicians'),
              filters: { role: ['technician', 'technician_manager'] }
            },
            {
              id:      'managers',
              name:    i18n.t('user.role.managers'),
              filters: { role: ['manager', 'local_manager'] }
            },
            {
              id:      'employees',
              name:    i18n.t('user.role.employees'),
              filters: { role: ['employee', 'employee_manager'] }
            },
            {
              id:      'all',
              name:    i18n.t('shared.all'),
              filters: {}
            }
          ]}
          callback         = {addUser}
          defaultFilter    = 'technicians'
          placeholder      = {i18n.t('user.your_users')}
          format           = {{ content: 'name', value: 'id', details: 'role' }}
          detailsLocaleKey = 'shared'
          marginY          = 'M'
          search
        />
      }

      {!!addUser && task.assignation === EXTERNAL &&
        <>
          <Select
            name         = 'provider'
            searchUrl    = '/service_providers/search'
            defaultValue = {[provider]}
            label        = {i18n.t('service_provider.service_providers')}
            placeholder  = {i18n.t('service_provider.your_service_providers')}
            format       = {{ content: 'name', value: 'id', details: 'expertises' }}
            marginY      = 'M'
            callback     = {prov => setProvider(prov.object)}
            search
          />
          {provider &&
            <Select
              name          = 'user'
              label         = {provider?.name || suggestedUser?.selected?.name}
              defaultValue  = {[suggestedUser]}
              search
              searchUrl     = {`/service_providers/search_users?provider=${provider?.id}`}
              placeholder   = {i18n.t('todo.provider_users', { provider: provider?.name })}
              format        = {{ content: 'name', value: 'id', details: 'role' }}
              marginY       = 'M'
              callback      = {addUser}
              required
            />
          }
        </>
      }
    </Style.TaskBox>
  )
}

export default TaskBox
