import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimePicker } from 'antd'

import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import DatePicker         from '@components/Form/DatePicker'
import FileUploader       from '@components/Form/FileUploader'
import Input              from '@components/Form/Input'
import MaterialOrderForm  from '@components/Material/Order/MaterialOrderForm'
import Select             from '@components/Form/Select'
import Switch             from '@components/Switch'

import * as FormStyle from '../Form/FormStyles'

import { CostFormProps }      from './types.d'

import { useGlobalContextState } from '../../context/GlobalContext'

const DURATION = 'duration'
const FROM_TO  = 'from_to'

const CostForm: React.FC<CostFormProps> = ({
  cost,
  task,
  type = 'hours',
  revealQR = false,
  report,
  createCost,
  updateCost
}) => {

  const {
    current_user,
    current_company,
    i18n,
    CONSTANTS
  } = useGlobalContextState()

  const { USER, COST } = CONSTANTS

  const [users,        _setUsers]       = useState([cost?.for_user || current_user])
  const [lineType,     setLineType]     = useState(cost?.line_type || type)
  const [inputType,    setInputType]    = useState(DURATION)
  const [timeRange,    setTimeRange]    = useState('')
  const [documents,    setDocuments]    = useState(cost?.documents || [])
  const [selectedTask, setSelectedTask] = useState(task)

  const updateMethods = {
    addDocument:    data => setDocuments(documents => [...documents, data]),
    removeDocument: data => setDocuments(documents => documents.filter(d => d.id !== data.id))
  }

  const sendCostForm = (event) => {
    event.preventDefault()
    const formData: FormData = new FormData(event.currentTarget)

    formData.append('line_type', lineType)
    formData.append('offer_line_id', cost?.id.toString())
    formData.append('documents', documents.map(doc => doc.id).toString())
    const costData = Object.fromEntries(formData)
    if (inputType === FROM_TO && costData.range === '') {
      toast(i18n.t('offer_line.error.select_starting_date'), { icon: '⚠️'})
      return
    }

    if (cost?.id && updateCost) {
      updateCost({ ...cost, ...costData })
    } else if (createCost) {
      createCost(costData)
    }
  }

  const hoursForm = useMemo(() => <>
    <FormStyle.Header>
      <FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}
    </FormStyle.Header>
    {report.tasks.length
      ? <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "to_do_item"
        defaultValue = {cost?.todo    ? [cost?.todo] : (selectedTask ? [selectedTask] : [])}
        options      = {report?.tasks?.length ? report.tasks : [selectedTask]}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />
      : <Input
        name         = "to_do_item"
        type         = "hidden"
        defaultValue = {task?.id}
      />
    }
    <Input
      type         = 'text'
      name         = 'name'
      defaultValue = {cost?.name || selectedTask?.title}
      label        = {i18n.t('shared.name')}
      marginY      = 'M'
    />

    <Select
      name             = 'for_user'
      label            = {i18n.t('user.user')}
      defaultValue     = {users}
      search
      searchUrl        = '/users/search'
      filters          = {[
        {
          id:      'technicians',
          name:    i18n.t('user.role.technicians'),
          filters: { role: `${USER.ROLES.TECHNICIAN},${USER.ROLES.TECHNICIAN_MANAGER}` }
        },
        {
          id:      'managers',
          name:    i18n.t('user.role.managers'),
          filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
        },
        {
          id:      'all',
          name:    i18n.t('shared.all'),
          filters: {}
        }
      ]}
      defaultFilter    = 'technicians'
      format           = {{ content: 'name', value: 'id', details: 'role' }}
      detailsLocaleKey = 'shared'
      placeholder      = {i18n.t('user.users')}
      marginY          = 'M'
      required
      multiselect
    />

    <FormStyle.Label>{i18n.t('shared.date')}</FormStyle.Label>
    <DatePicker
      name     = 'date'
      date    = {cost?.date ? new Date(cost.date) : new Date()}
      required
    />

    <FormStyle.Label>{i18n.t('offer_line.duration')}</FormStyle.Label>
    {!cost?.id &&
      <Switch
        name     = 'duration-type'
        selected = {inputType}
        marginY  = 'S'
        callback = {data => setInputType(_type => data)}
        options  = {[
          {
            content: i18n.t('offer_line.from_to'),
            icon:    <FontAwesomeIcon icon="user-clock" />,
            value:   FROM_TO
          },
          {
            content: i18n.t('offer_line.duration'),
            icon:    <FontAwesomeIcon icon="stopwatch" />,
            value:   DURATION
          }
        ]}
      />
    }

    {
      inputType === DURATION &&
        <FormStyle.Group marginY='M'>
          <Input
            type         = "number"
            name         = 'hours'
            label        = {i18n.t('shared.hours')}
            defaultValue = {cost ? Math.floor(cost.quantity / 3600) : undefined}
            marginY      = 'S'
            required
          />
          <Input
            type         = "number"
            name         = 'minutes'
            label        = {i18n.t('shared.minutes')}
            defaultValue = {cost ? Math.floor((cost.quantity / 60) % 60) : 0}
            min          = {0}
            max          = {59}
            maxLength    = {2}
            marginY      = 'S'
            required
          />
        </FormStyle.Group>
    }
    {
      inputType === FROM_TO &&
        <FormStyle.Group marginY='M'>
          <Input
            name         = "range"
            type         = "hidden"
            defaultValue = {timeRange}
          />
          <TimePicker.RangePicker
            defaultValue  = {[null, dayjs()]}
            popupStyle    = {{zIndex: 12000 }}
            minuteStep    = {10}
            aria-required = {true}
            size          = 'large'
            onChange      = {(_timeRange, timeString) => setTimeRange(timeString.join(','))}
            format        = 'HH:mm'
            style         = {{width: '100%'}}
            placeholder   = {[i18n.t('offer_line.beginning'), i18n.t('offer_line.end')]}
          />
        </FormStyle.Group>
    }

    {cost && cost.permissions.see_prices &&
      <Input
        type         = "number"
        name         = 'unit_price'
        defaultValue = {cost.unit_price}
        label        = {i18n.t('offer_line.hourly_rate')}
        hint         = '€'
        marginY      = 'M'
        required
        decimals
      />
    }
  </>, [users, inputType, timeRange])

  const transportForm = <>
    <FormStyle.Header marginY='M'>
      <FontAwesomeIcon icon="circle-info" />
      {i18n.t('shared.general_informations')}
    </FormStyle.Header>
    {report.tasks.length
      ? <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "to_do_item"
        defaultValue = {cost?.todo    ? [cost?.todo] : (selectedTask ? [selectedTask] : [])}
        options      = {report?.tasks?.length ? report.tasks : [selectedTask]}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />
      : <Input
        name         = "to_do_item"
        type         = "hidden"
        defaultValue = {task?.id}
      />
    }
    <Input
      type          = 'text'
      name          = 'name'
      defaultValue  = {cost?.name || selectedTask?.title}
      label         = {i18n.t('shared.name')}
      marginY       = 'M'
    />

    <Select
      name          = 'for_user'
      label         = {i18n.t('user.user')}
      defaultValue  = {users}
      search
      searchUrl     = '/users/search'
      filters          = {[
        {
          id:      'technicians',
          name:    i18n.t('user.role.technicians'),
          filters: { role: `${USER.ROLES.TECHNICIAN},${USER.ROLES.TECHNICIAN_MANAGER}` }
        },
        {
          id:      'managers',
          name:    i18n.t('user.role.managers'),
          filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
        },
        {
          id:      'all',
          name:    i18n.t('shared.all'),
          filters: {}
        }
      ]}
      defaultFilter    = 'technicians'
      format           = {{ content: 'name', value: 'id', details: 'role' }}
      detailsLocaleKey = 'shared'
      placeholder      = {i18n.t('user.users')}
      marginY          = 'M'
      required
    />

    <FormStyle.Label>{i18n.t('shared.date')}</FormStyle.Label>
    <DatePicker
      name     = 'date'
      date     = {cost?.date ? new Date(cost.date) : new Date()}
      required
    />

    <FormStyle.Label>{i18n.t('offer_line.transports')}</FormStyle.Label>
    <Input
      type          = 'number'
      name          = 'quantity'
      label         = {i18n.t('offer_line.enter_transports_per_day')}
      defaultValue  = {cost ? cost.quantity : undefined}
      marginY       = 'M'
      required
    />

    {cost && cost.permissions.see_prices &&
      <Input
        type         = 'number'
        name         = 'unit_price'
        defaultValue = {cost.unit_price}
        label        = {i18n.t('offer_line.transport_rate')}
        hint         = '€'
        marginY      = 'M'
        required
        decimals
      />
    }
  </>

  const inventoryForm = <>
    <FormStyle.Header>
      <FontAwesomeIcon icon="circle-info" />
      {i18n.t('shared.general_informations')}
    </FormStyle.Header>
    {report.tasks.length
      ? <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "to_do_item"
        defaultValue = {cost?.todo    ? [cost?.todo] : (selectedTask ? [selectedTask] : [])}
        options      = {report?.tasks?.length ? report.tasks : [selectedTask]}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />
      : <Input
        name         = "to_do_item"
        type         = "hidden"
        defaultValue = {task?.id}
      />
    }

    <Select
      name             = 'for_user'
      label            = {i18n.t('user.user')}
      defaultValue     = {users}
      search
      searchUrl        = '/users/search'
      filters          = {[
        {
          id:      'technicians',
          name:    i18n.t('user.role.technicians'),
          filters: { role: `${USER.ROLES.TECHNICIAN},${USER.ROLES.TECHNICIAN_MANAGER}` }
        },
        {
          id:      'managers',
          name:    i18n.t('user.role.managers'),
          filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
        },
        {
          id:      'all',
          name:    i18n.t('shared.all'),
          filters: {}
        }
      ]}
      defaultFilter    = 'technicians'
      format           = {{ content: 'name', value: 'id', details: 'role' }}
      detailsLocaleKey = 'shared'
      placeholder      = {i18n.t('user.users')}
      marginY          = 'M'
      required
    />

    {cost
      ? <>
        <FormStyle.Header>{cost?.name}</FormStyle.Header>
        <FormStyle.Group>
          <Input
            name         = 'quantity'
            defaultValue = {cost.quantity}
            label        = {i18n.t('offer_line.quantity')}
            type         = "number"
            marginY      = 'M'
          />
        </FormStyle.Group>
        {cost.permissions.see_prices &&
          <Input
            name         = 'unit_price'
            defaultValue = {cost.unit_price}
            label        = {i18n.t('offer_line.unit_price')}
            type         = "number"
            hint         = '€'
            marginY      = 'M'
            required
            decimals
          />
        }
      </>
      : <MaterialOrderForm
        withPrices    = {report.permissions.can_see_prices}
        reportAmenity = {report.amenity}
        revealQR      = {revealQR}
      />
    }
  </>

  const invoiceForm   = <>
    <FormStyle.Header>
      <FontAwesomeIcon icon="circle-info" />
      {i18n.t('shared.general_informations')}
    </FormStyle.Header>
    {report.tasks.length
      ? <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "to_do_item"
        defaultValue = {cost?.todo    ? [cost?.todo] : (selectedTask ? [selectedTask] : [])}
        options      = {report?.tasks?.length ? report.tasks : [selectedTask]}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />
      : <Input
        name         = "to_do_item"
        type         = "hidden"
        defaultValue = {task?.id}
      />
    }
    <Input
      type         = 'text'
      name         = 'name'
      defaultValue = {cost?.name || selectedTask?.title}
      label        = {i18n.t('shared.name')}
      marginY      = 'M'
      required
    />

    <Select
      name          = 'for_user'
      label         = {i18n.t('shared.responsible')}
      defaultValue  = {users}
      search
      searchUrl     = '/users/search'
      filters       = {[
        {
          id:      'technicians',
          name:    i18n.t('user.role.technicians'),
          filters: { role: `${USER.ROLES.TECHNICIAN},${USER.ROLES.TECHNICIAN_MANAGER}` }
        },
        {
          id:      'managers',
          name:    i18n.t('user.role.managers'),
          filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
        },
        {
          id:      'all',
          name:    i18n.t('shared.all'),
          filters: {}
        }
      ]}
      defaultFilter    = 'technicians'
      required
      format           = {{ content: 'name', value: 'id', details: 'role' }}
      detailsLocaleKey = 'shared'
      placeholder      = {i18n.t('user.users')}
      marginY          = 'M'
    />

    <FormStyle.Label>{i18n.t('shared.date')}</FormStyle.Label>
    <DatePicker
      name     = 'date'
      date     = {cost?.date ? new Date(cost.date) : new Date()}
      required
    />

    <FormStyle.Header>
      <FontAwesomeIcon icon="receipt" />
      {i18n.t('offer_line.invoice_details')}
    </FormStyle.Header>
    <Select
      name         = 'service_provider'
      defaultValue = {[cost?.service_provider]}
      search
      searchUrl    = '/service_providers/search'
      format       = {{ content: 'name', value: 'id' }}
      label        = {i18n.t('service_provider.service_provider')}
      marginY      = 'M'
    />
    <Input
      type         = 'text'
      name         = 'invoice_nb'
      label        = {i18n.t('offer_line.invoice_nb')}
      defaultValue = {cost?.invoice_nb}
      marginY      = 'M'
    />
    <Input
      type         = 'text'
      name         = 'order_nb'
      label        = {i18n.t('offer_line.order_nb')}
      defaultValue = {cost?.order_nb}
      marginY      = 'M'
    />

    <Input
      type         = 'text'
      name         = 'supplier'
      label        = {i18n.t('offer_line.supplier')}
      defaultValue = {cost?.supplier}
      marginY      = 'M'
    />
    <Input
      name         = 'unit_price'
      label        = {i18n.t('offer_line.unit_price')}
      defaultValue = {cost?.unit_price}
      type         = "number"
      hint         = '€'
      marginY      = 'M'
      decimals
      required
    />
    <FileUploader
      name            = 'document'
      updateMethods   = {updateMethods}
      objectType      = 'offer_line'
      objectId        = {cost?.id}
      closeModalAfter = {false}
      destroyable     = {true}
      files           = {documents}
    />
  </>

  const switchOptions = useMemo(() => {
    const options = [
      {
        content: i18n.t('offer_line.line_types.hours'),
        icon:    <FontAwesomeIcon icon="user-clock" />,
        value:   COST.TYPE.HOURS
      },
      {
        content: i18n.t('offer_line.line_types.transport'),
        icon:    <FontAwesomeIcon icon="truck-moving" />,
        value:   COST.TYPE.TRANSPORT
      },
      {
        content: i18n.t('offer_line.line_types.external_invoice'),
        icon:    <FontAwesomeIcon icon="file-invoice-dollar" />,
        value:   COST.TYPE.EXTERNAL_INVOICE
      },
    ]

    if (current_company.permissions.access_stock) {
      options.push(
        {
          content: i18n.t('offer_line.line_types.inventory'),
          icon:    <FontAwesomeIcon icon="cubes" />,
          value:   COST.TYPE.INVENTORY
        }
      )
    }

    return options
  }, [current_company])

  return (
    <form onSubmit={sendCostForm}>
      {!cost &&
        <Switch
          name     = 'line-type-switch'
          allWidth = {true}
          selected = {lineType}
          callback = {setLineType}
          options  = {switchOptions}
          marginY  = 'M'
        />
      }

      {lineType === COST.TYPE.HOURS && hoursForm}
      {lineType === COST.TYPE.TRANSPORT && transportForm}
      {lineType === COST.TYPE.INVENTORY && inventoryForm}
      {lineType === COST.TYPE.EXTERNAL_INVOICE && invoiceForm}

      <Input
        name         = 'submit'
        type         = 'submit'
        defaultValue = {cost ? i18n.t('actions.save') : i18n.t('actions.save')}
      />
    </form>
  )
}

export default CostForm
