import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar         from '@components/Avatar'
import Button         from '@components/Button'
import Checkbox       from '@form/Checkbox'
import DatePicker     from '@form/DatePicker/DatePicker'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

import WorkPeriodProps from './types.d'


const WorkPeriod: React.FC<WorkPeriodProps> = ({
  period,
  editable = false,
  updatePeriod,
  deletePeriod
}) => {

  const { i18n, isDesktop, current_company } = useGlobalContextState()

  const [displayForm,    setDisplayForm]    = useState(false)
  const [dates,          setDates]          = useState([period.start, period.stop])
  const [showPeriodTime, setShowPeriodTime] = useState(current_company.permissions.dispatch_task_with_hours)

  const options = useMemo<object>(() => {
    return isDesktop
      ? { weekday: 'short', month: 'short', day: 'numeric' }
      : { month: 'short', day: 'numeric' }
  }, [])

  const startDate = useMemo(() => new Date(period.start).toLocaleDateString(i18n.locale, options), [])
  const startTime = useMemo(() => new Date(period.start).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }), [])
  const stopDate  = useMemo(() => new Date(period.stop).toLocaleDateString(i18n.locale, options) ,[])
  const stopTime  = useMemo(() => new Date(period.stop).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }), [])

  return (
    <>
      <Style.WorkPeriodContainer>
        <Avatar
          firstName   = {period.user.first_name}
          lastName    = {period.user.last_name}
          type        = 'left'
        />
        <Style.WorkPeriod>
          {startDate} {startTime !== '00:00' && startTime}
          <FontAwesomeIcon icon="arrow-right-long" />
          {period.stop
            ? <>
              {stopDate} {stopTime !== '00:00' && stopTime}
            </>
            : <FontAwesomeIcon icon="question" />
          }
        </Style.WorkPeriod>
        {!!period.created_by &&
          <Avatar
            firstName      = {period.created_by.first_name}
            lastName       = {period.created_by.last_name}
            type           = 'right'
            color          = 'var(--rep-neutral)'
            background     = 'var(--rep-neutral-light)'
            tooltipContent = {i18n.t('todo.assigned_by_name', { name: `${period.created_by?.first_name} ${period.created_by?.last_name}` })}
          />
        }
        {!!editable &&
          <Style.WorkPeriodAction>
            {displayForm
              ? <Button
                color      = 'var(--rep-success)'
                background = 'var(--rep-success-light)'
                click      = {() => updatePeriod(period, dates, showPeriodTime)}
              >
                {i18n.t('actions.save')}
              </Button>
              : <Button
                background = 'var(--rep-primary-light)'
                color      = 'var(--rep-primary)'
                icon       = {<FontAwesomeIcon icon='pen' />}
                click      = {() => setDisplayForm(true)}
              >
                {i18n.t('actions.edit')}
              </Button>
            }
            <Button
              background = 'var(--rep-danger-light)'
              color      = 'var(--rep-danger)'
              icon       = {<FontAwesomeIcon icon='trash' />}
              click      = {() => deletePeriod(period)}
            />
          </Style.WorkPeriodAction>
        }
      </Style.WorkPeriodContainer>

      {!!editable &&
        <Style.WorkPeriodFormStyle>
          {!!displayForm &&
            <>
              <DatePicker
                name     = {`assigned_date_period_${period.id}`}
                mode     = 'range'
                date     = {dates}
                callback = {setDates}
                showTime = {
                  showPeriodTime
                    ? {
                      minuteStep: 10,
                      showNow:    true,
                      format:     'HH:mm'
                    }
                    : false}
                required
              />
              <Checkbox
                name         = "show_time"
                label        = {i18n.t('todo.actions.dispatch_hours')}
                defaultValue = {showPeriodTime}
                callback     = {() => setShowPeriodTime(showPeriodTime => !showPeriodTime)}
              />
            </>
          }
        </Style.WorkPeriodFormStyle>
      }
    </>
  )
}

export default WorkPeriod
