import React, {
  createContext,
  useContext,
  useReducer,
  useState,
} from 'react'

import CONSTANTS from '../config/constants.json'

import { ICompany, IReport, IUser } from '../interfaces'
import { ReportContextType }        from './types.d'

import { reportReducer }         from '@reducers/reportReducer'
import { useGlobalContextState } from './GlobalContext'

declare global {
  interface Window {
    env: any;
  }
}

// create contexts
const ReportContextState = createContext<ReportContextType | null>(null)

// context consumer hook
const useReportContextState = () => {
  // get the context
  const context = useContext(ReportContextState)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useReportContextState was used outside of its Provider')
  }

  return context
}

const initialCompany: ICompany = {
  id:   null,
  name: ''
}

const initialUser: IUser = {
  id:               null,
  first_name:       '',
  last_name:        '',
  name:             '',
  initials:         '',
  preferred_locale: '',
  role:             '',
  active:           true,
  managing:         false,
  company:          initialCompany,
}

const initialReport: IReport = {
  id:             null,
  report_number:  '',
  created_by:     initialUser,
  suggested_user: null,
  status:         'new',
  urgent:         false,
  title:          '',
  drop_off:       '',
  created_since:  null,
  waste_category: {
    id:          null,
    description: ''
  },
  danger_icons: [''],
  users:        [],
  documents:    [],
  tasks:        [],
  offer_lines:  [],
  amenity:      {
    id:           null,
    name:         '',
    ancestors:    [],
    has_children: false,
    status:       '',
    path:         ''
  },
  unit: {
    id:              null,
    name:            '',
    path:            '',
    has_amenities:   false,
    has_children:    false,
    invoice_contact: {
      id:       null,
      add_name: '',
      address:  '',
      city:     '',
      name:     '',
      vat_nb:   '',
      zip_code: ''
    }
  },
  remarks:             [],
  conversations:       [],
  mandatory_expertise: false,
  permissions:         {
    require_report_approve:             false,
    can_view_report:                    false,
    can_create_report:                  false,
    can_update_report:                  false,
    can_update_description:             false,
    can_send_for_approval:              false,
    can_approve_report:                 false,
    can_close_report:                   false,
    can_open_report:                    false,
    can_assign_task:                    false,
    can_create_task:                    false,
    can_see_all_tasks:                  false,
    can_see_costs:                      false,
    can_see_prices:                     false,
    can_create_cost:                    false,
    can_update_status:                  false,
    can_cancel_report:                  false,
    can_uncancel_report:                false,
    can_task_batch_approval:            false,
    can_cost_batch_approval:            false,
    can_create_conversation:            false,
    can_create_document:                false,
    can_create_material_request:        false,
    can_choose_closing_date:            false,
    can_remove_users_from_conversation: false,
    can_access_unit:                    false,
    can_access_amenity:                 false,
    can_access_work_order:              false,
    can_access_intervention_report:     false,
    require_charge_number:              false,
    can_set_expected_date:              false,
    must_set_expected_date:             false,
    require_closing_comment:            false,
  }
}

interface ReportContextProviderProps {
  children:     React.ReactNode,
  serverReport: IReport,
}

const ReportContextProvider: React.FC<ReportContextProviderProps> = ({
  serverReport,
  children,
}) => {

  const {
    i18n,
    initAPI,
    fetchApi,
    showModal,
    closeModal
  } = useGlobalContextState()

  const [report, setReport] = useState(serverReport || initialReport)
  const [view,   setView]   = useState(CONSTANTS.VIEW.VIEW_TASKS)

  const updateFullReport    = newReport => setReport(_report => newReport)
  const updateMinimalReport = newReport => setReport(report => ({
    ...report,
    status:                newReport.status,
    permissions:           newReport.permissions,
    title:                 newReport.title,
    urgent:                newReport.urgent,
    description:           newReport.description,
    done_at:               newReport.done_at,
    input_code:            newReport.input_code,
    next_maintenance:      newReport.next_maintenance,
    validated_by:          newReport.validated_by,
    validated_at:          newReport.validated_at,
    prepared_by:           newReport.prepared_by,
    prepared_date:         newReport.prepared_date,
    responsible:           newReport.responsible,
    expected_date:         newReport.expected_date,
    expected_delta:        newReport.expected_delta,
    expected_string:       newReport.expected_string,
    canceled_at:           newReport.canceled_at,
    canceled_by:           newReport.canceled_by,
    closing_comment:       newReport.closing_comment,
    costs_amount_approved: newReport.costs_amount_approved,
    costs_amount_pending:  newReport.costs_amount_pending,
    hours_amount:          newReport.hours_amount,
    hours_quantity:        newReport.hours_quantity,
    invoices_amount:       newReport.invoices_amount,
    materials_amount:      newReport.materials_amount,
    transports_amount:     newReport.transports_amount,
    transports_quantity:   newReport.transports_quantity,
    all_costs_amount:      newReport.all_costs_amount,
    location:              newReport.location,
    type:                  newReport.type,
    field_values:          newReport.field_values,
    anomaly:               newReport.anomaly,
    // Options of minimal report
    conversations:         newReport.conversations.length ? newReport.conversations : report.conversations
  }))

  // COSTS - OFFER LINES
  const addCosts = costs => setReport(report => ({ ...report,
    offer_lines:          [...costs, ...report.offer_lines],
    total_costs:          report.total_costs + costs.length,
    total_filtered_costs: report.total_filtered_costs + costs.length
  }))
  const updateCost = data  => setReport(report => ({ ...report,
    offer_lines: report.offer_lines.map(c => c.id === data.id ? data : c)
  }))
  const updateCosts = data  => setReport(report => ({ ...report,
    offer_lines:          data.response,
    total_filtered_costs: data.total,
    total_costs_pages:    data.total_pages
  }))
  const fetchMoreCosts = costs => setReport(report => ({ ...report,
    offer_lines: [...report.offer_lines, ...costs]
  }))
  const removeCost = data => setReport(report => ({ ...report,
    offer_lines:          report.offer_lines.filter(r => r.id !== data.id),
    total_costs:          report.total_costs - 1,
    total_filtered_costs: report.total_filtered_costs - 1
  }))

  // TASKS - TO DO ITEMS
  const addTask        = task  => setReport(report =>  ({ ...report,
    tasks:                [task, ...report.tasks],
    total_tasks:          report.total_tasks + 1,
    total_filtered_tasks: report.total_filtered_tasks + 1
  }))
  const updateTask     = task  => setReport(report => ({ ...report,
    tasks: report.tasks.map(t => t.id === task.id ? task : t)
  }))
  const updateTasks    = data  => setReport(report =>  ({ ...report,
    tasks:                data.response,
    total_filtered_tasks: data.total,
    total_tasks_pages:    data.total_pages,
  }))
  const fetchMoreTasks = tasks => setReport(report => ({ ...report,
    tasks: [...report.tasks, ...tasks]
  }))
  const removeTask     = data  => {
    setReport(report => ({ ...report,
      tasks:                report.tasks.filter(t => !data.ids.includes(t.id)),
      total_tasks:          report.total_tasks - data.ids.length ,
      total_filtered_tasks: report.total_filtered_tasks - data.ids.length
    }))
  }

  const addConversation     = conversation  => setReport(report => ({ ...report, conversations: [...report.conversations, conversation] }))
  const updateConversation  = conversation  => setReport(report => ({ ...report, conversations: report.conversations.map(c => c.id === conversation.id ? conversation : c) }))
  const updateConversations = conversations => setReport(report => ({ ...report, conversations }))

  // MAINTENANCE LEGAL REMARKS
  const addRemark = data => setReport(report => ({ ...report,
    remarks:                [data, ...report.remarks],
    total_remarks:          report.total_remarks + 1,
    total_filtered_remarks: report.total_filtered_remarks + 1
  }))
  const updateRemark = data => setReport(report => ({ ...report,
    remarks: report.remarks.map(r => r.id === data.id ? data : r)
  }))
  const updateRemarks = data => setReport(report => ({ ...report,
    remarks:                data.response,
    total_filtered_remarks: data.total,
    total_remarks_pages:    data.total_pages
  }))
  const fetchMoreRemarks = remarks => setReport(report => ({ ...report,
    remarks: [...report.remarks, ...remarks]
  }))
  const removeRemark = data => setReport(report => ({ ...report,
    remarks:                report.remarks.filter(r => r.id !== data.id),
    total_remarks:          report.total_remarks - 1 ,
    total_filtered_remarks: report.total_filtered_remarks - 1
  }))

  const addDocument = data => setReport(report => ({ ...report,
    documents:       [...report.documents, data],
    total_documents: report.total_documents + 1,
  }))
  const updateDocuments = data => setReport(report => ({ ...report,
    documents:             data.response,
    total_documents:       data.total,
    total_documents_pages: data.total_pages
  }))
  const updateDocument  = data => setReport(report => ({ ...report,
    documents: report.documents.map(d => d.id === data.id ? data : d)
  }))
  const fetchMoreDocuments = documents => setReport(report => ({ ...report,
    documents: [...report.documents, ...documents]
  }))
  const removeDocument = data => setReport(report => ({ ...report,
    documents:       report.documents.filter(d => d.id !== data.id),
    total_documents: report.total_documents - 1
  }))

  const updateMachineStop = data => setReport(report => ({ ...report, machine_stop: data }))

  const updateForm = (data, task) => setReport(report => ({...report,
    tasks: report.tasks.map(t => t.id === task.id ? ({ ...t,
      forms: task.forms.map(f => f.id === data.id ? data : f)
    }) : t)
  }))

  const updateMethods = {
    report:        updateFullReport,
    minimalReport: updateMinimalReport,

    addCost:    addCosts,
    cost:       updateCost,
    costs:      updateCosts,
    moreCosts:  fetchMoreCosts,
    removeCost: removeCost,

    addTask:    addTask,
    task:       updateTask,
    tasks:      updateTasks,
    moreTasks:  fetchMoreTasks,
    removeTask: removeTask,

    machineStop: updateMachineStop,

    addConversation: addConversation,
    conversation:    updateConversation,
    conversations:   updateConversations,

    addDocument:    addDocument,
    document:       updateDocument,
    documents:      updateDocuments,
    moreDocuments:  fetchMoreDocuments,
    removeDocument: removeDocument,

    addRemark:    addRemark,
    remark:       updateRemark,
    remarks:      updateRemarks,
    moreRemarks:  fetchMoreRemarks,
    removeRemark: removeRemark,

    form: updateForm
  }

  const [_state, reportDispatch] = useReducer(reportReducer, {
    API: initAPI({ type: 'reports', reportId: report.id }),
    updateMethods, i18n,
    report, setReport, fetchApi, showModal, closeModal
  })

  return (
    // the Providers gives access to the context to its children
    <ReportContextState.Provider value={{
      view,                  setView,
      report,                setReport,
      reportDispatch,        updateMethods,
    }}>
      {children}
    </ReportContextState.Provider>
  )
}

export { ReportContextProvider, useReportContextState }
