import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker   from '@form/DatePicker'
import FileUploader from '@form/FileUploader'
import Input        from '@form/Input'
import Textarea     from '@form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'
import { useTaskContextState }   from '@context/TaskContext'

import * as FormStyle from '@form/FormStyles'

interface DoneForm {
  callback: (any) => void
}

const DoneForm: React.FC<DoneForm> = ({
  // task,
  callback,
  report
}) => {
  const {
    i18n,
    current_company,
  } = useGlobalContextState()

  const {
    task,
    updateMethods
  } = useTaskContextState()

  const [endedDate,   _setEndedDate]   = useState(task.done_date || new Date())
  const [measurments, setMeasurments]  = useState(report.measurments_tasks.map((typeMeasurment) => {
    return ({
      id:      typeMeasurment.id,
      value:   task.measurment_values.find(f => f.measurment.id === typeMeasurment.id)?.value || '',
      comment: task.measurment_values.find(f => f.measurment.id === typeMeasurment.id)?.comment || ''
    })
  })
  )
  

  const handleMeasurmentChange = (measurmentValue) => {
    const newMeasurments = [...measurments]
    const measurment     = newMeasurments.find(m => m.id === measurmentValue.id)
    if (measurment) {
      measurment.value   = measurmentValue.value || measurment.value
      measurment.comment = measurmentValue.comment || measurment.comment
    }
    setMeasurments(newMeasurments)
  }

  const sendDoneTask = useCallback((event) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    formData.append('measurment_values', JSON.stringify(measurments))

    const doneData = Object.fromEntries(formData)
    callback(doneData)
  }, [task])

  return (
    <form onSubmit={sendDoneTask}>
      {task.permissions.edit_closing_date &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="calendar-check" />{i18n.t('todo.ended_at')}</FormStyle.Header>
          <DatePicker
            name    = 'ended_at'
            date    = {endedDate}
            maxDate = {new Date()}
          />
        </>
      }
      {report.measurments_tasks.map((measurment) => {
        
        return (
          <>
            <FormStyle.Header><FontAwesomeIcon icon="ruler" />{measurment.name}</FormStyle.Header>
            <Input
              background   = {'var(--rep-primary-light)'}
              border       = {'var(--rep-primary)'}
              color        = {'var(--rep-primary)'}
              defaultValue = {task.measurment_values.find(f => f.measurment.id === measurment.id)?.value}
              name         = {`measurments[${measurment.id}][value]`}
              label        = {i18n.t('measurment.value')}
              required     = {true}
              marginY      = 'M'
              type         = "text"
              change       = {(event) => handleMeasurmentChange({ id: measurment.id, value: event.currentTarget.value })}
            />
            <Textarea
              name         = {`measurments[${measurment.id}][comment]`}
              label        = {i18n.t('shared.comment')}
              defaultValue = {task.measurment_values.find(f => f.measurment.id === measurment.id)?.comment}
              marginY      = 'M'
              change       = {(event) => handleMeasurmentChange({ id: measurment.id, comment: event.currentTarget.value })}
            />
          </>
        )
          
        
      })}
      <FormStyle.Header>
        <FontAwesomeIcon icon="comment-medical" />
        {i18n.t('todo.closing_comment')}
      </FormStyle.Header>
      <Textarea
        name         = 'comment'
        defaultValue = {task.comment}
        required     = {current_company.permissions.task_closing_comment_required}
        placeholder  = {i18n.t('todo.add_closing_comment')}
        marginY      = "M"
      />
      <FileUploader
        objectType      = 'to_do_item'
        objectId        = {task.id}
        closeModalAfter = {false}
        updateMethods   = {updateMethods}
        files           = {task.documents}
        multiple
        destroyable
        grid
      />
      <Input
        name         = 'submit'
        type         = 'submit'
        background   = 'var(--rep-success-light)'
        color        = 'var(--rep-success)'
        border       = 'none'
        defaultValue = {i18n.t('actions.close_task')}
        marginY      = 'M'
      />
    </form>
  )
}

export default DoneForm
