import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout    from '@components/Callout'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IReport from '@interfaces/IReport.d'

interface ExpectedDateForm {
  report:   IReport
  callback: (any) => void
}

const ExpectedDateForm: React.FC<ExpectedDateForm> = ({
  report,
  callback
}) => {
  const { i18n } = useGlobalContextState()

  const sendExpectedDate = useCallback((event) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const doneData = Object.fromEntries(formData)
    callback(doneData)
  }, [report])

  return (
    <form onSubmit={sendExpectedDate}>
      <Callout
        type    = "warning"
        icon    = {<FontAwesomeIcon icon="hand" />}
        marginY = 'M'
      >
        {i18n.t('report.page.required_expected_date')}
      </Callout>
      <FormStyle.Header><FontAwesomeIcon icon="calendar-check" />{i18n.t('report.expected_date')}</FormStyle.Header>
      <DatePicker
        name    = 'expected_date'
        date    = {new Date()}
      />

      <Input
        name         = 'submit'
        type         = 'submit'
        background   = 'var(--rep-success-light)'
        color        = 'var(--rep-success)'
        border       = 'none'
        defaultValue = {i18n.t('report.actions.validate_expected_date')}
        marginY      = 'M'
      />
    </form>
  )
}

export default ExpectedDateForm
