import React, { useReducer, useState } from 'react'

import Callout         from '@components/Callout'
import Input           from '@form/Input'
import MultiselectItem from '@components/Form/Select/MultiselectItem'
import PlanEquipments  from '@pages/Maintenances/Form/PlanFormEquipments'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import IMaintenancePlanGroup from '@interfaces/IMaintenancePlanGroup.d'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { useMaintenancesContextState } from '@context/MaintenancesContext'

interface GroupFromProps {
  group:        IMaintenancePlanGroup
  setGroupLine: (any) => void
}

const GroupForm: React.FC<GroupFromProps> = ({ group, setGroupLine }) => {

  const { i18n, closeModal, fetchApi } = useGlobalContextState()

  const { filters, } = useMaintenancesContextState()

  const [groupData, setGroupData] = useState(group)

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    updateLine: setGroupLine,
  })

  const updateMaintenanceGroup = event => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData)

    dispatch({ type: MAINTENANCE_ACTIONS.UPDATE_GROUP, data: {...data, units: groupData.units, amenities: groupData.amenities } })
  }

  return(
    <form onSubmit={updateMaintenanceGroup}>
      <Input type="hidden" name="id" defaultValue={group.id} />

      <FormStyle.Header>
        {i18n.t('maintenance.group_name')}
      </FormStyle.Header>
      <Input type="text" name="name" defaultValue={group.name} marginY="S" disabled={group.archived} />

      <FormStyle.Header>
        {i18n.t('maintenance.associated_plans')}
      </FormStyle.Header>

      {group.plans.map(plan =>
        <MultiselectItem key={plan.id} name={plan.name} />
      )}

      {/* <Callout marginY='M'>
        {i18n.t('maintenance.callout.all_plans_will_apply')}
      </Callout> */}

      {/* <PlanEquipments
        data    = {groupData}
        setData = {setGroupData}
        dateSelect
      /> */}

      <Input
        name         = 'save-group'
        type         = "submit"
        defaultValue = {i18n.t('maintenance.actions.save_group')}
      />
    </form>
  )
}

export default GroupForm
