import React, { useCallback, useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon }             from '@fortawesome/react-fontawesome'

import Button      from '@components/Button'
import Callout     from '@components/Callout'
import WithTooltip from '@components/WithTooltip/WithTooltip'

import FileForm    from './FileForm'

import { useGlobalContextState } from '@context/GlobalContext'

import ThumbnailProps from './types.d'

import * as Style from './style'

import { matchImage, matchPdf } from '@utils/regex'

import { DOCUMENT_ACTIONS, documentReducer } from '@reducers/index'

/**
 * @see Interface {@link ThumbnailProps}
 * @description File Thumbnail component
 * @example
 * <Thumbnail file={file} />
 */
const Thumbnail: React.FC<ThumbnailProps> = ({
  file,
  updateMethods,
  color,
  wrapText        = true,
  modaleDetails   = false,
  showFileName    = true,
  destroyable     = false,
  closeModalAfter = false,
  certification   = false,
}) => {

  const {
    showModal,
    closeModal,
    fetchApi,
    showAlert,
    closeAlert,
    i18n
  } = useGlobalContextState()

  const [_state, dispatch] = useReducer(documentReducer, { file, fetchApi, updateMethods, closeModal })

  const updateDocument = data => dispatch({ type: DOCUMENT_ACTIONS.UPDATE, data, closeModal: closeModalAfter })
  const removeDocument = ()   => dispatch({ type: DOCUMENT_ACTIONS.DESTROY, closeModal: closeModalAfter, callbacks: [closeAlert] })

  const isPdf   = useMemo(() => matchPdf(file.doctype), [])
  const isImage = useMemo(() => matchImage(file.doctype), [])

  const [errorCount, setErrorCount]           = useState(0)

  const onImageError = (e, file) => {
    e.target.src = 'https://user-images.githubusercontent.com/28399150/84750757-fdcc6f00-afbb-11ea-908a-1074b026b06b.png'
    if (errorCount <= 5) {
      setErrorCount(errorCount + 1)
      setTimeout(() => {
        e.target.src = file.thumb_url
      }, 3000)
    }
  }

  const openFile  = useCallback(() => window.open(file.url, '_blank', 'noopener,noreferrer'), [])
  const openModal = useCallback(() => showModal({
    title:   i18n.t('document.attached'),
    content: <FileForm
      file               = {file}
      certification      = {certification}
      destroyable        = {destroyable}
      removeDocument     = {removeDocument}
      updateDocument     = {updateDocument}
    />
  }), [])

  const FileDisplay = useMemo(() => {
    if (file.preview) return <img src={file.preview} onError = {(e) => onImageError(e, file)}/>
    if (isPdf) {
      return <Style.FileIcon color={color}>
        <FontAwesomeIcon icon="file-pdf" />
      </Style.FileIcon>
    } else if(isImage) {
      return <img src={file.thumb_url} onError = {(e) => onImageError(e, file)}/>
    } else {
      return <Style.FileIcon color={color}>
        <FontAwesomeIcon icon="file" />
      </Style.FileIcon>
    }
  }, [])

  return (
    <div style={{position: 'relative'}}>
      {destroyable &&
        <div          style      = {{
          position: 'absolute',
          zIndex:   100,
          right:    '0'
        }}>
          <Button
            background = "var(--rep-danger-light)"
            hover = "var(--rep-danger-middle)"
            color      = "white"
            click      = {() => showAlert(
              <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
                {i18n.t('actions.delete_file')}
              </Callout>,
              [{
                content:    i18n.t('actions.delete'),
                color:      'white',
                background: 'var(--rep-danger)',
                icon:       <FontAwesomeIcon icon="trash-can" />,
                click:      removeDocument
              }]
            )}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </div>
      }
      <Style.FileContainer onClick={modaleDetails ? openModal : openFile}>
        <Style.FileStyles> {FileDisplay} </Style.FileStyles>

        {showFileName &&
        <Style.FileName wrapText={wrapText}>
          {file.certification &&
            <WithTooltip content="certification">
              <FontAwesomeIcon icon="medal" />
            </WithTooltip>
          }
          {file.name}
        </Style.FileName>
        }
      </Style.FileContainer>
    </div>
  )
}

export default Thumbnail
