import React from 'react'

import ReportIndex from './ReportIndex'
import Wrapper     from '../../Wrapper'

import { ICompany, IReport, IUser } from '@interfaces/index'

interface ReportListProps {
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string,
  new_reports:     IReport[],
  done_reports:    IReport[],
  ongoing_reports: IReport[],
  filter_presets:  object,
}

const ReportList: React.FC<ReportListProps> = ({
  current_user,
  current_company,
  token,
  locale,
  new_reports,
  done_reports,
  ongoing_reports,
  filter_presets
}) => {
  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
      withModal       = {false}
    >
      <ReportIndex
        new_reports     = {new_reports}
        done_reports    = {done_reports}
        ongoing_reports = {ongoing_reports}
        filter_presets  = {filter_presets}
      />
    </Wrapper>
  )
}

export default ReportList
