import React, { useReducer }         from 'react'

import { FileWithPath, useDropzone } from 'react-dropzone'
import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome'

import Thumbnail from './Thumbnail'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Styles from './style'

import IFileUploader from './types.d'

import { DOCUMENT_ACTIONS, documentReducer } from '@reducers/index'

const compressImage = async (file, { quality = 1, type = file.type }) => {
  const imageBitmap = await createImageBitmap(file)

  // Draw to canvas
  const canvas  = document.createElement('canvas')
  canvas.width  = imageBitmap.width
  canvas.height = imageBitmap.height
  const ctx     = canvas.getContext('2d')

  ctx.drawImage(imageBitmap, 0, 0)

  // Turn into Blob
  const blob: Blob = await new Promise(resolve => canvas.toBlob(resolve, type, quality))

  // Turn Blob into File
  return new File([blob], file.name, { type: blob.type })
}

const uploadFunction = (
  upload,
  files,
) => {
  Array.from(files).forEach((file) => {
    const formPayLoad = new FormData()
    if (file) {
      // We don't have to compress files that aren't images
      if (!file.type.startsWith('image')) {
        formPayLoad.append('document', file)
        upload(formPayLoad)
      } else {
        async function compress() {
          // Save back the compressed file instead of the original file
          const compressedFile = await compressImage(file, {
            quality: 0.5,
            type:    'image/jpeg',
          })
          formPayLoad.append('document', compressedFile)
          upload(formPayLoad)
        }
        compress()
      }
    }
  })
}

/**
 * @see Interface {@link IFileUploader}
 * @description File Uploader component
 * @example
 * <FileUploader name="documents" />
 */
const FileUploader: React.FC<IFileUploader> = ({
  name,
  objectType,
  objectId,
  updateMethods,
  destroyable,
  files           = [],
  multiple        = false,
  grid            = true,
  closeModalAfter = true,
}) => {

  const { i18n, fetchApi, closeModal } = useGlobalContextState()

  const [_state, dispatch] = useReducer(documentReducer, { fetchApi, updateMethods, closeModal })

  const addDocument = file =>
    dispatch({
      type:       DOCUMENT_ACTIONS.CREATE,
      file,
      objectType,
      objectId,
      closeModal: closeModalAfter
    })

  const onDrop = acceptedFiles => uploadFunction(addDocument, acceptedFiles)

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple:    multiple,
    onDragEnter: () => undefined,
    onDragOver:  () => undefined,
    onDragLeave: () => undefined
  })

  return (
    <React.Fragment>
      <Styles.DropZoneStyles {...(getRootProps()) as object}>
        <input name={name} {...(getInputProps()) as object} />
        <FontAwesomeIcon icon="upload" />
        {isDragActive ?
          <p>{i18n.t('document.drop_here')}</p> :
          <p>{i18n.t('document.drop_details')}</p>
        }
        {acceptedFiles.map((file: FileWithPath) =>
          <li key={file.path}>
            {file.path} - {(file.size / 1000000).toFixed(2)} Mb
          </li>
        )}
      </Styles.DropZoneStyles>
      <Styles.FilesStyles grid={grid}>
        {files.map((file) =>
          <Thumbnail
            key            = {file.id}
            file           = {file}
            updateMethods  = {updateMethods}
            destroyable    = {destroyable}
          />
        )}
      </Styles.FilesStyles>
    </React.Fragment>
  )
}

export default FileUploader
export { uploadFunction }
