import ReactOnRails from 'react-on-rails'

import Calendar           from '@pages/Maintenances/Calendar'
import MaintenanceForm    from '@pages/Maintenances/Calendar/SubLine/maintenance_form_for_ror.tsx'
// import PlanForm           from '@pages/Maintenances/Form/index_for_ror.tsx'
import PlanForm           from '@pages/Maintenances/Form/plan_form_for_ror.tsx'
import PlanFormEquipments from '@pages/Maintenances/Form/plan_form_equipments_for_ror.tsx'
import ReportShow         from '@pages/Reports/Show'
import ReportsIndex       from '@pages/Reports/Index'
import TasksIndex         from '@pages/Tasks/Index'

ReactOnRails.register({
  Calendar,
  MaintenanceForm,
  PlanForm,
  PlanFormEquipments,
  ReportsIndex,
  ReportShow,
  TasksIndex
})
