import styled from 'styled-components'

export const EmptyColumn = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  flex-direction:  column;
  color:           var(--rep-neutral-primary);
  p { font-size: 3em; }
`

export const Column = styled.div`
  max-height:     ${props => props.maxHeight ? props.maxHeight : '100%'};
  height:         ${props => props.height    ? props.height : 'unset'};
  display:        flex;
  flex-grow:      ${props => props.grow ? 1 : 0};
  flex-direction: column;
  grid-col:       ${props => props.gridCol ? props.gridCol : 'unset'};
  grid-row:       ${props => props.gridRow ? props.gridRow : 'unset'};
  margin-bottom:  ${props => props.marginBottom ? props.marginBottom : '0' };
`

export const StickyHeader = styled.div`
  position:     sticky;
  top:          0;
  background: #F8F8F8;
`

export const ColumnHeader = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  min-height:      2rem;
  padding:         0 8px;
  font-weight:     500;
  sup {
    color:        var(--rep-primary);
    font-weight:  900;
    margin-left:  4px;
    top:          unset;
  }
`

export const ColumnTitle = styled.div`
  font-size:    1rem;
  font-family:  'Inter', sans-serif;
  display:      flex;
  flex-grow:    1;
  user-select:  none;
  &:hover { cursor: ${props => props.onClick ? 'pointer' : 'default'} }
`

export const ColumnHeaderBtn = styled.div`
  display:     flex;
  align-items: center;
  font-weight: 500;
`

export const TogglableIcon = styled.div`
  transform:    rotate(${props => props.toggled ? 90 : 0}deg);
  transition:   .2s ease;
  color:        var(--rep-primary);
  margin-right: 8px;
`

export const FilterList = styled.div`
  height:        ${props => props.toggled ? 'auto' : 0};
  display:       flex;
  align-items:   center;
  gap:           4px;
  flex-wrap:     wrap;
  border-radius: 5px;
  border:        1px solid var(--rep-neutral-primary-light);
  background:    var(--rep-neutral-primary-light);
  padding:       8px;
`

export const FilterButton = styled.div`
  /* background:    white; */
  display:         flex;
  align-items:     center;
  justify-content: flex-end;
  color:           var(--rep-neutral-primary);
  font-weight:     500;
  padding:         4px 8px;
  &:hover { cursor: default; }
`

export const ColumnContent = styled.div`
  flex-grow:          ${props => props.toggled ? 1 : 0};
  height:             ${props => props.toggled ? 'max-content' : 0};
  max-height:         max-content;
  max-height:         100vh;
  min-height:         ${props => props.toggled ? '30%' : 'none'};
  overflow:           ${props => props.toggled ? 'scroll' : 'hidden'};
  display:            flex;
  flex-direction:     column;
  padding:            ${props => props.toggled ? '8px' : '0'};
  border-top:         none;
  padding-bottom:     0;
  -ms-overflow-style: none;
  scrollbar-width:    none;
`

export const ColumnLoader = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  color:           var(--rep-primary);
  font-size:       2em;
  margin:          8px 0;
  min-height:      2em;
`

export const ColumnFooter = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  padding:         8px 0;
  position:        sticky;
  bottom:          4px;
  background:      white;
  border:          1px solid var(--rep-neutral-primary-light);
  border-radius:   4px;
  padding:         8px;
`

export const FooterCount = styled.div`
  color: var(--rep-neutral-primary);
`
