import { FontAwesomeIcon }       from '@fortawesome/react-fontawesome'
import React from 'react'

import Avatar from '@components/Avatar'
import Button from '@components/Button'
import Tag    from '@components/Tag'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { IMachineStop, IReport } from '@interfaces/index'

const MachineStop = ({ report, stop }: { report: IReport, stop: IMachineStop }) => {

  const { i18n, CONSTANTS } = useGlobalContextState()

  const { MACHINE_STOP } = CONSTANTS

  const status = ({
    [MACHINE_STOP.STATUS.STOPPED]: {
      color:      'var(--rep-danger)',
      colorLight: 'var(--rep-danger-light)',
      icon:       <FontAwesomeIcon icon="explosion" />
    },
    [MACHINE_STOP.STATUS.READY]: {
      color:      'var(--rep-warning)',
      colorLight: 'var(--rep-warning-light)',
      icon:       <FontAwesomeIcon icon="wrench" />
    },
    [MACHINE_STOP.STATUS.RESTARTED]: {
      color:      'var(--rep-success)',
      colorLight: 'var(--rep-success-light)',
      icon:       <FontAwesomeIcon icon="plug-circle-check" />
    },
  })[stop.status] ?? {
    color:      'var(--rep-primary)',
    colorLight: 'var(--rep-primary-light)',
    icon:       <FontAwesomeIcon icon="gear" />,
  }

  return(
    <Style.MachineStop>
      <Tag
        color          = 'var(--rep-neutral-primary)'
        border         = 'var(--rep-neutral-primary)'
        tooltipContent = "Type d'arrêt"
      >
        {stop.type}
      </Tag>
      <Style.MachineStopHeader>
        <h3>
          Arrêt de {report.unit.name}
        </h3>
        <Tag
          color          = {status.color}
          background     = {status.colorLight}
          icon           = {status.icon}
          tooltipContent = 'Status'
        >
          {stop.status}
        </Tag>
      </Style.MachineStopHeader>

      {stop.description}

      <Style.MachineStopHeader>
        <Tag
          color       = 'var(--rep-danger)'
          background  = 'var(--rep-danger-light)'
          icon        = {<FontAwesomeIcon icon="stopwatch-20" />}
          marginY     = 'M'
        >Fixing time {stop.fixing_duration}</Tag>
        <Tag
          color       = 'var(--rep-warning)'
          background  = 'var(--rep-warning-light)'
          icon        = {<FontAwesomeIcon icon="arrow-up-right-dots" rotation={90} />}
          marginY     = 'M'
        >Total downtime {stop.downtime}</Tag>
      </Style.MachineStopHeader>

      <hr />

      {!!stop.stopped_at &&
        <>
          <Style.MachineStopLine>
            <Tag
              icon        = {<FontAwesomeIcon icon="explosion" />}
              color       = 'var(--rep-danger)'
              background  = 'var(--rep-danger-light)'
            >
              {i18n.t('machine_stop.status.stopped')}
            </Tag>
            <Avatar
              firstName = {stop.stopped_by.first_name}
              lastName  = {stop.stopped_by.last_name}
              title     = {i18n.t('machine_stop.stopped_by')}
            />
            {new Date(stop.stopped_at).toLocaleDateString(i18n.locale)} {new Date(stop.stopped_at).toLocaleTimeString(i18n.locale)}
          </Style.MachineStopLine>
          <hr />
        </>
      }


      {!!stop.ready_at &&
        <>
          <Style.MachineStopLine>
            <Tag
              icon       = {<FontAwesomeIcon icon="wrench" />}
              color      = 'var(--rep-warning)'
              background = 'var(--rep-warning-light)'
            >
              {i18n.t('machine_stop.status.ready')}
            </Tag>
            <Avatar
              firstName = {stop.ready_by.first_name}
              lastName  = {stop.ready_by.last_name}
              title     = {i18n.t('machine_stop.ready_by')}
            />
            {new Date(stop.ready_at).toLocaleDateString(i18n.locale)} {new Date(stop.ready_at).toLocaleTimeString(i18n.locale)}
          </Style.MachineStopLine>
          <hr />
        </>
      }


      {!!stop.restarted_at &&
        <>
          <Style.MachineStopLine>
            <Tag
              icon        = {<FontAwesomeIcon icon="plug-circle-check" />}
              color       = 'var(--rep-success)'
              background  = 'var(--rep-success-light)'
            >
              {i18n.t('machine_stop.status.restarted')}
            </Tag>
            <Avatar
              firstName = {stop.restarted_by.first_name}
              lastName  = {stop.restarted_by.last_name}
              title     = {i18n.t('machine_stop.restarted_by')}
            />
            {new Date(stop.restarted_at).toLocaleDateString(i18n.locale)} {new Date(stop.restarted_at).toLocaleTimeString(i18n.locale)}
          </Style.MachineStopLine>
          <hr />
        </>
      }


      <FormStyle.Header><FontAwesomeIcon icon="paste" />Reports</FormStyle.Header>
      {stop.reports.map(rep =>
        <Button
          key        = {rep.id}
          click      = {() => window.open(`/reports/${rep.id}`, '_blank', 'noopener,noreferrer')}
          color      = 'var(--rep-primary)'
          background = 'var(--rep-primary-light)'
          marginY    = 'S'
        >
          {report.report_number}
        </Button>
      )}
    </Style.MachineStop>
  )
}

export default MachineStop
