import styled from 'styled-components'

export const WorkPeriodContainer = styled.div`
  display:       flex;
  align-items:   center;
  padding:       4px 0px;
  color:         var(--rep-neutral-primary);
`

export const WorkPeriod = styled.div`
  display:        flex;
  align-items:    center;
  width:          100%;
  padding:        4px 8px;
  gap:            8px;
  color:          var(--rep-neutral-primary);
  padding:        4px 8px;
  text-transform: capitalize;
  border:         1px solid var(--rep-primary-light);
  border-right:   1px solid var(--rep-neutral-light);
`

export const WorkPeriodContent = styled.div`
`

export const WorkPeriodAction = styled.div`
  margin-left: 8px;
  display:     flex;
  gap:         8px;
`

export const WorkPeriodFormStyle = styled.div`
`
