import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button          from '@components/Button'
import Callout         from '@components/Callout'
import Checkbox        from '@form/Checkbox'
import DatePicker      from '@form/DatePicker'
import Input           from '@form/Input'
import MultiselectItem from '@form/Select/MultiselectItem'
import QuestionForm    from '@components/FormQuestion/Form'
import Select          from '@form/Select'
import Switch          from '@components/Switch'
import Textarea        from '@form/Textarea'
import WorkPeriod      from '@components/WorkPeriod'

import * as FormStyle            from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { TaskFormProps } from './types.d'

const INTERNAL   = 'internal'
const EXTERNAL   = 'external'

const TaskForm: React.FC<TaskFormProps> = ({
  report,
  task,
  assignTo,
  createTask,
  updateTask,
  updatePeriod,
  deletePeriod,
}) => {

  const {
    i18n,
    current_company,
  } = useGlobalContextState()

  const [providers,    setProviders]    = useState([])
  const [dispatchTo,   setDispatchTo]   = useState(assignTo ? assignTo : INTERNAL)

  const [userTeams,         setUserTeams]         = useState([])
  const [userTeamSelected,  setUserTeamSelected]  = useState([-1])
  const [formsTemplates,    setFormsTemplates]    = useState([])
  const [formsSelected,     setFormsSelected]     = useState(task ? task.forms : [])
  const [formsCreated,      setFormsCreated]      = useState([])
  const [invoicingSelected, setInvoicingSelected] = useState(task ? [task.invoice_contact].filter(e => e) : (report.unit?.invoice_contact ? [report.unit.invoice_contact] : []))

  const [suggestedProviderReason, setSuggestedProviderReason] = useState('')
  const [suggestedProvider,       setSuggestedProvider]       = useState([])
  const [suggestedUserReason,     setSuggestedUserReason]     = useState('')
  const [suggestedUser,           setSuggestedUser]           = useState([])
  const [showUserForm,            setShowUserForm]            = useState(Boolean(suggestedUser))
  const [showQuestionForm,        setShowQuestionForm]        = useState(false)

  const [selectedUsers, setSelectedUsers]   = useState([])
  const [showPeriodTime, setShowPeriodTime] = useState(current_company.permissions.dispatch_task_with_hours || !!report.maintenance_start_task)

  useEffect(() => {
    if (report.tasks.length) return

    if (report.suggested_user?.type === INTERNAL) {
      setDispatchTo(INTERNAL)
      setSuggestedUser([report.suggested_user.selected])
      setSuggestedUserReason(report.suggested_user.reason)
    }
    if (report.suggested_user?.type === EXTERNAL) {
      setDispatchTo(EXTERNAL)
      setProviders([{
        content: report.suggested_user.selected.name,
        value:   report.suggested_user.selected.id,
        details: report.suggested_user.selected.expertises,
        object:  report.suggested_user.selected
      }])
      setSuggestedProvider([report.suggested_user.selected])
      setSuggestedProviderReason(report.suggested_user.reason)
    }
  }, [])

  useEffect(() => {
    fetch('/user_teams/search')
      .then(response => response.json())
      .then(data => { setUserTeams(data.response.results) })
  }, [])

  const assignUserTeam = (teamId) => {
    const team = userTeams.find(team => team.id === teamId)
    if (userTeamSelected.includes(teamId)) {
      const teamToRemove = userTeamSelected.findIndex(t => t === teamId)
      const teamsUpdated = [...userTeamSelected]
      teamsUpdated.splice(teamToRemove, 1)
      setUserTeamSelected(teamsUpdated)
      const cleanUsers = [...suggestedUser].filter(u => !team.users.includes(u))
      setSuggestedUser(cleanUsers)
    } else {
      setUserTeamSelected([...userTeamSelected, team.id])
      setSuggestedUser([...new Set([...suggestedUser, ...team.users])])
    }
  }

  const addFormTemplates = forms => setFormsTemplates([...formsTemplates, ...forms.map(f => f.object)])
  const addForm = form => {
    setFormsCreated([...formsCreated, form])
    setShowQuestionForm(false)
  }
  const removeFormTemplate = form => setFormsTemplates(formsTemplates.filter(f => f.id !== form.id))
  const removeFormSelected = form => setFormsSelected(formsSelected.filter(f => f.id !== form.id))
  const removeFormCreated  = form => setFormsCreated(formsCreated.filter(f => f.tempId !== form.tempId))

  const sendTaskForm = (event) => {
    event.preventDefault()
    const formData: FormData = new FormData(event.currentTarget)
    const data      = Object.fromEntries(formData)
    data.assigned_to    = dispatchTo
    data.form_templates = formsTemplates
    data.forms_selected = formsSelected.map(f => f.id).join(',')
    data.forms_created  = formsCreated
    if (task?.id) {
      updateTask(data)
    } else {
      createTask(data)
    }
  }

  const defaultPeriod = useMemo(() => {
    if (!task?.id && report.maintenance_start_task) {
      const startDate = new Date(report.maintenance_start_task)
      const stopDate = new Date(report.maintenance_start_task)
      if (task?.duration) { stopDate.setSeconds(stopDate.getSeconds() + task.duration) }
      return [startDate, stopDate]
    } else if (task?.planned_start && task?.planned_end) {
      return [task.planned_start, task.planned_end]
    } else {
      return []
    }
  }, [])

  const providerUsers = useMemo(() => {
    return providers.map(prov =>
      <Select
        name          = {`service_provider_${prov.object.id}`}
        key           = {prov.object.id}
        label         = {prov.object.name}
        defaultValue  = {prov.object.users?.length ? [prov.object.users[0]] : []}
        options       = {prov.object.users}
        placeholder   = {i18n.t('todo.provider_users', { provider: prov.object.name })}
        format        = {{ content: 'name', value: 'id', details: 'role' }}
        marginY       = 'M'
        required
        multiselect
      />
    )
  }, [providers])

  return(
    <form onSubmit={sendTaskForm}>
      <FormStyle.Header>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>
      <Input
        type         = 'text'
        name         = 'title'
        label        = {i18n.t('shared.name')}
        defaultValue = {task ? task.title : report.title}
        marginY      = 'M'
        required
      />
      <Textarea
        name         = 'description'
        label        = {i18n.t('shared.description')}
        defaultValue = {task ? task.description : report.tasks.length ? '' : report.description}
        marginY      = 'M'
      />

      {current_company.permissions.see_estimated_time &&
        <>
          <FormStyle.Label>{i18n.t('todo.expected_duration')}</FormStyle.Label>
          <FormStyle.Group>
            <Input
              name         = 'hours'
              label        = {i18n.t('shared.hours')}
              type         = 'number'
              defaultValue = {task ? Math.floor(task.duration / 3600) : undefined}
            />
            <Input
              name         = 'minutes'
              label        = {i18n.t('shared.minutes')}
              type         = 'number'
              min          = {0}
              max          = {59}
              maxLength    = {2}
              defaultValue = {task ? Math.floor((task.duration / 60) % 60) : undefined}
            />
          </FormStyle.Group>
        </>
      }

      {report.permissions.can_assign_task &&
        <>
          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon="users" />{i18n.t('todo.actions.assign_to')}
          </FormStyle.Header>
          {task?.id && task.work_periods.map(period =>
            <WorkPeriod
              key          = {period.id}
              task         = {task}
              period       = {period}
              updatePeriod = {updatePeriod}
              deletePeriod = {deletePeriod}
              editable     = {task.permissions.unassign_task}
            />
          )}
          <Button
            icon    = {<FontAwesomeIcon icon={showUserForm ? 'angle-down' : 'plus' } />}
            click   = {() => setShowUserForm(!showUserForm)}
            marginY = 'S'
            color   = 'var(--rep-success)'
            border  = {showUserForm ? '' : 'var(--rep-success)'}
          >
            {i18n.t('todo.actions.assign_task')}
          </Button>

          {showUserForm &&
            <FormStyle.BorderedBox>
              <Switch
                name     = 'assign-to'
                options  = {[
                  { content: i18n.t('todo.internal'),                     value: INTERNAL},
                  { content: i18n.t('service_provider.service_provider'), value: EXTERNAL}
                ]}
                selected = {dispatchTo}
                callback = {setDispatchTo}
              />

              {dispatchTo === INTERNAL &&
                <>
                  {!!userTeams.length &&
                    <>
                      <FormStyle.Label>
                        {i18n.t('user_team.user_teams')}
                      </FormStyle.Label>
                      <Switch
                        multiselect
                        name     = 'user-team'
                        selected = {userTeamSelected}
                        callback = {assignUserTeam}
                        options  = {userTeams.map(team => {
                          return {
                            content: team.name,
                            value:   team.id
                          }
                        })}
                      />
                    </>
                  }
                  <FormStyle.Label>
                    {i18n.t('user.users')}
                  </FormStyle.Label>
                  <Select
                    name         = 'users'
                    searchUrl    = '/users/search'
                    defaultValue = {task?.users?.length ? [] : suggestedUser}
                    filters      = {[
                      {
                        id:      'technicians',
                        name:    i18n.t('user.role.technicians'),
                        filters: { role: ['technician', 'technician_manager'] }
                      },
                      {
                        id:      'managers',
                        name:    i18n.t('user.role.managers'),
                        filters: { role: ['manager', 'local_manager'] }
                      },
                      {
                        id:      'employees',
                        name:    i18n.t('user.role.employees'),
                        filters: { role: ['employee', 'employee_manager'] }
                      },
                      {
                        id:      'all',
                        name:    i18n.t('shared.all'),
                        filters: {}
                      }
                    ]}
                    defaultFilter    = 'technicians'
                    placeholder      = {i18n.t('user.your_users')}
                    format           = {{ content: 'name', value: 'id', details: 'role' }}
                    detailsLocaleKey = 'shared'
                    marginY          = 'S'
                    callback         = {setSelectedUsers}
                    search
                    multiselect
                    required
                  />
                  {!!suggestedUserReason &&
                    <Callout
                      icon       = {<FontAwesomeIcon icon="rocket" />}
                      type       = 'warning'
                      // border     = 'var(--rep-warning)'
                      // background = 'var(--rep-warning-light)'
                      // color      = 'var(--rep-warning)'
                    >
                      {suggestedUserReason}
                    </Callout>
                  }
                </>
              }

              {dispatchTo === EXTERNAL &&
                <>
                  <Checkbox
                    name    = 'send_notification_to_service_provider'
                    label   = {i18n.t('todo.send_notification_to_service_provider')}
                    marginY = 'M'
                    defaultValue
                  />

                  <Select
                    name         = 'service_providers'
                    searchUrl    = '/service_providers/search'
                    defaultValue = {suggestedProvider || []}
                    label        = {i18n.t('service_provider.service_providers')}
                    placeholder  = {i18n.t('service_provider.your_service_providers')}
                    format       = {{ content: 'name', value: 'id', details: 'expertises' }}
                    callback     = {setProviders}
                    marginY      = 'M'
                    search
                    multiselect
                    required
                  />

                  {providerUsers}

                  {!!suggestedProviderReason &&
                    <Callout
                      icon       = {<FontAwesomeIcon icon="rocket" />}
                      border     = 'var(--rep-warning)'
                      background = 'var(--rep-warning-light)'
                      color      = 'var(--rep-warning)'
                    >
                      {suggestedProviderReason}
                    </Callout>
                  }
                </>
              }
              {(!!selectedUsers.length || providers.length || userTeamSelected[1] || suggestedUser || suggestedProvider) &&
                <FormStyle.DatePickerForm>
                  <DatePicker
                    label    = {i18n.t('todo.work_period')}
                    mode     = 'range'
                    name     = 'assigned_date'
                    date     = {defaultPeriod}
                    showTime = {
                      showPeriodTime
                        ? {
                          minuteStep: 10,
                          showNow:    true,
                          format:     'HH:mm'
                        }
                        : false}
                    required
                  />
                  <Checkbox
                    name         = "show_time"
                    label        = {i18n.t('todo.actions.dispatch_hours')}
                    defaultValue = {showPeriodTime}
                    callback     = {() => setShowPeriodTime(showPeriodTime => !showPeriodTime)}
                  />
                </FormStyle.DatePickerForm>
              }
            </FormStyle.BorderedBox>
          }
        </>
      }

      {current_company.permissions.can_see_forms &&
        <>
          <FormStyle.Header marginY='M'><FontAwesomeIcon icon="list-check" />{i18n.t('form.forms')}</FormStyle.Header>
          <Select
            label        = 'Modèles de formulaires'
            name         = 'forms'
            searchUrl    = '/forms/search'
            defaultFilter={'archived'}
            filters      = {[{
              id:      'archived',
              name:    'archived',
              filters: { archived: false }
            }]}
            defaultValue = {[]}
            callback     = {addFormTemplates}
            format       = {{ content: 'name', value: 'id', details: '' }}
            multiselect  = {true}
            placeholder  = {i18n.t('form.your_forms')}
            marginY      = 'S'
            search
            cleanAfterSelect
          />
          {formsTemplates.map(form =>
            <MultiselectItem
              key          = {form.id}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-lines" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormTemplate(form)}
            />
          )}
          {formsSelected.map(form =>
            <MultiselectItem
              key          = {form.id}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-lines" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormSelected(form)}
            />
          )}
          {formsCreated.map(form =>
            <MultiselectItem
              key          = {form.tempId}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-circle-plus" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormCreated(form)}
            />
          )}
          <Button
            icon    = {<FontAwesomeIcon icon={showQuestionForm ? 'angle-down' : 'plus'} />}
            click   = {() => setShowQuestionForm(!showQuestionForm)}
            marginY = 'S'
            color   = 'var(--rep-success)'
            border  = {showQuestionForm ? '' : 'var(--rep-success)'}
          >
            {i18n.t('form.actions.create')}
          </Button>
          {showQuestionForm && <QuestionForm addForm={addForm} />}
        </>
      }

      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="receipt" />{i18n.t('invoice_contact.invoice_contact')}
      </FormStyle.Header>

      <Select
        name          = 'invoice_contact'
        searchUrl     = '/invoice_contacts/search'
        defaultValue  = {invoicingSelected}
        callback      = {invoicing => setInvoicingSelected([invoicing.object])}
        format        = {{ content: 'name', value: 'id', details: 'add_name' }}
        placeholder   = {i18n.t('invoice_contact.your_invoice_contacts')}
        marginY       = 'S'
        search
        withEmpty
      />

      {!selectedUsers.length && !providers.length && !userTeamSelected[1] &&
      <>
        <FormStyle.Header marginY='S'>
          <FontAwesomeIcon icon="receipt" />{i18n.t('todo.todo_expected_date')}
        </FormStyle.Header>
        <DatePicker
          name        = 'planned_end'
          date        = {task?.planned_end}
          required
        />
      </>
      }

      <Input
        name         = 'submit'
        type         = 'submit'
        marginY      = 'M'
        defaultValue = {i18n.t('actions.save')}
      />
    </form>
  )
}

export default TaskForm
