import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style     from './style'

import * as FormStyle from '../FormStyles'
import WithTooltip from '../../WithTooltip'

import ICheckbox  from './types.d'

import { useGlobalContextState } from '../../../context/GlobalContext'

/**
 * @see Interface {@link ICheckbox}
 * @description Checkbox form element
 * <Checkbox id='check-me' label='Check this box' />
 */
const Checkbox: React.FC<ICheckbox> = ({
  name,
  label,
  color        = 'var(--rep-neutral-primary)',
  border       = 'var(--rep-neutral-primary)',
  markColor    = 'var(--rep-primary)',
  background   = 'var(--rep-primary-light)',
  disabled     = false,
  defaultValue = false,
  required,
  tooltip,
  object,
  icon,
  callback,
  shouldReset,
  fullWidth,
  marginY
}) => {

  const { i18n, isDesktop } = useGlobalContextState()
  const [isCheck, setIsChecked] = useState(defaultValue)

  useEffect(() => {
    if (shouldReset) {
      setIsChecked(defaultValue)
    }
  }, [shouldReset])

  const checkboxChange = () => {
    if (disabled) return
    setIsChecked(!isCheck)
    Boolean(callback) && (object
      ? callback({ value: object, isCheck: !isCheck })
      : callback({ value: name, isCheck: !isCheck }))
  }

  const handleKeyDown = e => {
    if (e.code === 'Space') {
      e.preventDefault()
      checkboxChange()
    }
  }

  return (
    <Style.CheckboxContainer color={color} marginY={marginY} tooltip={!!tooltip} fullWidth={fullWidth}>
      <input
        id      = {`checkbox-${name}`}
        type    = 'checkbox'
        name    = {name.toString()}
        checked = {isCheck}
        hidden
        readOnly
        tabIndex = {-1}
        disabled = {disabled}
      />

      <Style.Checkbox
        role            = "checkbox"
        aria-checked    = {isCheck}
        onClick         = {checkboxChange}
        color           = {markColor}
        border          = {border}
        background      = {background}
        disabled        = {disabled}
        checked         = {isCheck}
        onKeyDown       = {handleKeyDown}
        aria-labelledby = {`checkbox-${name}`}
        tabIndex        = "0"
        isDesktop       = {isDesktop}
      >
        <Style.CheckMark displayed={isCheck}>
          {isCheck && (icon ? icon : <FontAwesomeIcon icon="check" size={isDesktop ? '1x' : 'xl'} color={color} />)}
        </Style.CheckMark>
      </Style.Checkbox>

      <div style={{ display: 'flex', width: '100%' }}>
        <Style.CheckboxLabel
          tooltip ={ tooltip}
          color={color}
          fullWidth = {fullWidth}
          htmlFor={`checkbox-${name}`}
          onClick={checkboxChange}
        >
          {disabled && <FontAwesomeIcon icon="lock" />} {label}
        </Style.CheckboxLabel>

        {!!required &&
        <FormStyle.Required middle>
          {i18n.t('shared.required')}
        </FormStyle.Required>
        }
        {!!tooltip &&
        <FormStyle.Tooltip middle>
          <WithTooltip content={tooltip}>
            <FontAwesomeIcon icon="circle-question" />
          </WithTooltip>
        </FormStyle.Tooltip>
        }
      </div>
    </Style.CheckboxContainer>
  )
}

export default Checkbox
