import React, { useState }      from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

import ISearch from './types.d'

const Search: React.FC<ISearch> = ({
  callback
}) => {

  const {
    i18n,
    isDesktop
  } = useGlobalContextState()

  const [focused,   setFocused]   = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)

  const handleChange = event => {
    const value = event.currentTarget.value
    // Delay update of searchValue to avoid unnecessary fetches while user is still typing
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => {
        callback(value)
      }, 300))
    } else {
      setTimeoutId(setTimeout(() => null, 300))
      callback(value)
    }
  }

  return (
    <Style.SearchBar
      focused   = {focused}
      isDesktop = {isDesktop}
    >
      <Style.SearchIcon>
        <FontAwesomeIcon icon={'magnifying-glass'} />
      </Style.SearchIcon>
      <Style.SearchInput
        placeholder = {i18n.t('actions.search')}
        onChange    = {handleChange}
        onBlur      = {() => setFocused(false)}
        onClick     = {() => setFocused(true)}
      >
      </Style.SearchInput>
    </Style.SearchBar>
  )
}

export default Search
